import React from "react"
import ResourceDocumentSection from "./ResourceDocumentSection"
export default function ResourceDetails() {
  const documentTypesArray = [
    {
      title: "PIPELINE DOCUMENTS",
      document_types_id: 1,
    },
    {
      title: "REGISTRATION DOCUMENTS",
      document_types_id: 2,
    },
    {
      title: "ISSUANCE DOCUMENTS",
      document_types_id: 3,
    },
    {
      title: "PROJECT DESIGN DOCUMENTS",
      document_types_id: 5,
    },
    {
      title: "MONITORING DOCUMENTS",
      document_types_id: 4,
    },
    {
      title: "OTHER DOCUMENTS",
      document_types_id: 6,
    },
    {
      title: "LOCATION FILES",
      document_types_id: 7,
    },
  ]
  return (
    <React.Fragment>
      {documentTypesArray.map(doctype => (
        <ResourceDocumentSection
          key={doctype.id}
          title={doctype.title}
          document_types_id={doctype.document_types_id}
        />
      ))}
    </React.Fragment>
  )
}
