import React from "react"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import TwoLineItem from '../DetailsView/TwoLineItem'
import CardSectionTitle from "components/DetailsView/CardSectionTitle"

function ProjectInformation() {
  const projectInformation = useSelector(
    state => state.projectDetails.details.projectInformation
  )

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle title="Project Information" icon={<img src={"/icons/project-details.svg"} />} />
        <Row>
          <Col md={4} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Project Type" body={projectInformation?.project_type_ids ?? "--"} tooltip="Project sub-categorization based on its specific activities" />
          </Col>

          <Col md={4}>
            <TwoLineItem title="Project Scale" body={projectInformation?.activity_scale ?? "--"} tooltip="The classification of the size of project activities in the Registry." />
          </Col>

          <Col md={4}>
            <TwoLineItem title="Project Length" body={projectInformation?.project_length ?? "--"} tooltip="Expected length of project activities in years" />
          </Col>
        </Row>
        <Row>
          <Col md={4} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Environment Impact" body={projectInformation?.environmental_impact ?? "--"} tooltip="The overall benefit of project activities on the environment." />
          </Col>

          <Col md={4}>
            <TwoLineItem title="Relevant GHG Sources" body={projectInformation?.ghg_sources ?? "--"} tooltip="The greenhouse gases addressed by project activities." />
          </Col>

          <Col md={4}>
            <TwoLineItem title="Project Methodology" body={projectInformation?.methodology ?? "--"} tooltip="Indicates the methodology used by the project under the approving carbon Registry." />
          </Col>
        </Row>
        <Row>
          <Col md={4} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Crediting Period" body={projectInformation?.project_vintage_period ?? "--"} tooltip="Range of years during which the project is expected to generate credits." />
          </Col>

          <Col md={4}>
            <TwoLineItem title="Crediting Start Date" body={projectInformation?.crediting_project_startdate ?? "--"} tooltip="Date at which the project started generating credits." />

          </Col>

          <Col md={4}>
            <TwoLineItem title="Crediting End Date" body={projectInformation?.crediting_project_enddate ?? "--"} tooltip="Date at which the project is expected to stop generating credits." />

          </Col>

          <Col md={12} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Technology" body={projectInformation?.technology ?? "--"} tooltip="The technologies used by the project." />
          </Col>

          <Col md={12} className="mb-5">
            <TwoLineItem title="Main Activity" body={projectInformation?.project_main_activity ?? "--"} tooltip="The specific intervention of the project" />
          </Col>

          <Col md={12} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Boundaries" body={projectInformation?.boundaries ?? "--"} tooltip="The GHG sources, sinks and reservoirs that are relevant to the project and baseline scenarios." />
          </Col>

          <Col md={12} style={{ marginBottom: '42px' }}>
            <TwoLineItem title="Project Notes/Remarks" body={projectInformation?.project_notes_remarks ?? "--"} tooltip="Additional relevant information provided by the developer." />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProjectInformation
