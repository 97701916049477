import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"

import { Row, Col, CardBody, Card} from "reactstrap"

//redux
import { useDispatch } from "react-redux"


// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import CarouselPage from "../Authentication/CarouselPage"
import withRouter from "components/Common/withRouter"
// import SignInImage from "../../assets/images/SignIn/SignInImage.png"
import Logo_cs from "../../assets/images/CarbonhubAiFinalLogo.svg"
import { postPasswordChnage } from "components/core/services/postUsers"
import Swal from "sweetalert2"


const NewPassword = props => {
  // eslint-disable-next-line no-unused-vars
  const [errMsg, setErrMsg] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [password, setPassword] = useState(true);

  const dispatch = useDispatch()

  const handlePasswordChange = (event, value) => {
    if (value !== event.target.form.password.value) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
      setPassword(value);
    }
    
    if (event.target.name === "password") {
      setPasswordsMatch(false);
    }
  };
  

  const handleValidSubmit = async () => {
    try {
      const payload = {
        newPassword: password,
      };

      const response = await dispatch(postPasswordChnage(payload));
      if (response.type === "/user/changePassword/fulfilled") {
        props.router.navigate("/chanagesuccess");
      } else {
        props.router.navigate("/login"); 
      } 
       
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: 'Password Changing Process Unsuccess. Change Your Password From Your Profile Setting',
      });
      setErrMsg('Login failed. Please check your credentials.');
    }
  }


  return (
    <React.Fragment>
      <MetaTags className="pt-5">
        <title>Login | CarboneSpace</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div style={{ backgroundColor: "#F3F3F9", height: "100vh" }}>

        <Row style={{ position: 'relative', height: "100%" }}>
          <Col lg={4} />
          <Col lg={4}>
            <Card className="overflow-hidden" style={{ marginTop: "22%" , borderRadius: "10px"}}>
              <CardBody className="pt-0">

                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                      <div className="text-center mb-4">
                        <img className="pt-4 pb-4" src={Logo_cs} width="150px" alt="Logo" />
                        <h3 className="pt-2 pb-2" style={{ marginBottom: "20px" }}>Create Your New Password</h3>
                      </div>
                    {/* {error ? (
                      <Alert color="danger">
                        {"Incorrect Username or Password"}
                      </Alert>
                    ) : null} */}

                  <div className="mb-3">
                    <label htmlFor="password">New Password</label>
                    <AvField
                      id="password"
                      name="password"
                      type="password"
                      required
                      placeholder="Enter New Password"
                      onChange={handlePasswordChange}
                      
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="reEnterPassword">Re-Enter Password</label>
                    <AvField
                      id="reEnterPassword"
                      name="reEnterPassword"
                      type="password"
                      required
                      placeholder="Re-Enter Your Password"
                      onChange={handlePasswordChange}
                      
                    />
                    {!passwordsMatch && (
                      <div className="text-danger">Password does not match.</div>
                    )}
                  </div>


                    <br />

                    

                    <div className="mt-1 d-grid">
                      <br />

                      <button
                        className="btn btn-block"
                        style={{ backgroundColor: "#12526D", color: "white" }}
                        type="submit"
                        disabled={passwordsMatch != true}
                        onClick={handleValidSubmit}
                      >
                        Change Password
                      </button>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-3 text-center">
              <p style={{ fontSize: "10px", marginBottom: "-100px" }}>
                Powered by Searchspace geo © {new Date().getFullYear()} |
                Privacy Policy | Cookie Policy
              </p>
            </div>
          </Col>
          <Col lg={4} />
        </Row>

      </div>
    </React.Fragment>
  )
}

export default withRouter(NewPassword)

NewPassword.propTypes = {
  history: PropTypes.object,
}