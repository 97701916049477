import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { BreadcrumbItem, Col, Row } from "reactstrap"

const Breadcrumb = ({ breadcrumbItems, title }) => {
  return (
    <div className="page-title-box align-items-center">
      <Row>
        <Col className="fw-bold text-muted fs-5 text-uppercase">{title}</Col>
      </Row>
      <Row>
        <div className="page-title-right">
          <ol className="breadcrumb m-0">
            {breadcrumbItems.map((item, key) => (
              <BreadcrumbItem
                className="breadcrumb me-2"
                key={key}
                active={key + 1 === breadcrumbItems.length}
              >
                <Link to={item.link}>{item.title}</Link>
              </BreadcrumbItem>
            ))}
          </ol>
        </div>
      </Row>
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
