import CardSectionTitle from "components/DetailsView/CardSectionTitle";
import React, { useState } from "react";
import Pagination from "react-js-pagination";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Container, Modal, ModalBody, Table } from "reactstrap";

function Documents() {
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrders, setSortOrders] = useState({
    name: "asc",
    category: "asc",
    date: "asc",
  });

  const data = useSelector((state) => state.projectDetails.details.resourceDetails);

  const [loading, setLoading] = useState(false)

  const pageSize = 5;
  const [activePage, setActivePage] = useState(1);

  const indexOfLastItem = activePage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  if (!data) {
    return <div>--</div>; // or any other suitable loading state
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrders({
        ...sortOrders,
        [column]: sortOrders[column] === "asc" ? "desc" : "asc",
      });
    } else {
      setSortColumn(column);
      setSortOrders({ ...sortOrders, [column]: "asc" });
    }
  };

  const sortedData = data
    ? [...data]
      .sort((a, b) => {
        if (sortColumn === "name") {
          return sortOrders.name === "asc"
            ? a.document_name.localeCompare(b.document_name)
            : b.document_name.localeCompare(a.document_name);
        } else if (sortColumn === "category") {
          return sortOrders.category === "asc"
            ? a.document_type.localeCompare(b.document_type)
            : b.document_type.localeCompare(a.document_type);
        } else if (sortColumn === "date") {
          return sortOrders.date === "asc"
            ? new Date(a.date) - new Date(b.date)
            : new Date(b.date) - new Date(a.date);
        }
        return 0;
      })
      .slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const convertFileToBlobAndDownload = async (file, name) => {
    setLoading(true)
    const blob = await fetch(file).then(r => r.blob())
    setLoading(false)
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = name // add custom extension here
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  }

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle
          title="Documents"
        />
        <div className='table-responsive'>
        <Table className="data-table">
          <thead>
            <tr style={{ borderBottom: "2px solid #DEDCDE", fontSize: '0.rem' }}>
              <th className="ash-text" onClick={() => handleSort("name")}>
                Name{" "}
                {sortColumn === "name" && sortOrders.name === "asc" ? "▲" : "▼"}
              </th>
              <th className="ash-text" onClick={() => handleSort("category")}>
                Category{" "}
                {sortColumn === "category" && sortOrders.category === "asc" ? "▲" : "▼"}
              </th>
              <th className="ash-text" onClick={() => handleSort("date")}>
                Date Uploaded{" "}
                {sortColumn === "date" && sortOrders.date === "asc" ? "▲" : "▼"}
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedData.map((row, index) => {
              // Check if file_input is not null
              if (row.file_input) {
                var fname = row.file_input;
                var last = fname?.substring(fname.lastIndexOf("/") + 1, fname.length).replaceAll('%20', ' ');
                const parts = last?.split("_");
                parts?.splice(0, 1);
                fname = parts?.join(" ");

                return (
                  <tr
                    key={index}
                    onClick={() => {
                      convertFileToBlobAndDownload(row.file_input, fname);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td className="black-text">{row.document_name}</td>
                    <td className="black-text">{row.document_type}</td>
                    <td className="black-text">{row.date}</td>
                  </tr>
                );
              } else {
                return (
                  <tr
                    key={index} >
                    <td className="black-text">{row.document_name}</td>
                    <td className="black-text">{row.document_type}</td>
                    <td className="black-text">{row.date}</td>
                  </tr>
                )
              }
            })}
          </tbody>

        </Table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-end mt-4">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageSize}
            totalItemsCount={data.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="pagination-item"
            activeClass="pagination-active-item"
            activeLinkClass="pagination-active-item"
            hideFirstLastPages
            itemClassNext="pagination-button"
            itemClassPrev="pagination-button"
          />
        </div>
      </Container>
      <Modal isOpen={loading} centered>
        <ModalBody className="text-center">
          <RotatingLines />
          <p className="mt-2">Downloading file...</p>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Documents;
