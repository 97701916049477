import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { BarLoader } from "react-spinners"
import { Card, CardBody, Col, Row } from "reactstrap"
//redux
import { useDispatch } from "react-redux"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions

import withRouter from "components/Common/withRouter"
import { postLogin } from "components/core/services/postUsers"
import { Navigate } from "react-router-dom"
import Swal from "sweetalert2"
import Logo_cs from "../../assets/images/CarbonhubAiFinalLogo.svg"
import jwtDecode from "jwt-decode"

const Login = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleValidSubmit = async () => {
    setLoading(true)
    try {
      const payload = {
        email: email,
        password: password,
      }

      // Dispatch the postLogin async thunk and get the response
      const response = await dispatch(postLogin(payload))
      const token = response?.payload?.payload?.token
      if (token) {
        const data = jwtDecode(token)
        const roleId = data?.role_id
        setEmail("")
        setPassword("")
        if (roleId === 1 || roleId === 2) {
          localStorage.setItem("authToken", token)
          props.router.navigate("/")
        } else {
          Swal.fire({
            title: "Oops!",
            text: "Access Denied. The admin panel is restricted to authorized users only.",
            icon: "error",
          })
        }
      } else if (response.payload.error) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          iconColor: "#F46A6A",
          text: response.payload.payload,
        })
        console.log("Invalid Credentials")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false) // Step 5: Set loading back to false when the server response is received
    }
  }

  if (localStorage.getItem("authToken") != null) {
    return <Navigate to="/" />
  }

  // eslint-disable-next-line no-undef
  const privacyPolicyUrl = `${process.env.REACT_APP_OTHER_SERVICE_URL}/privacy-policy`;


  return (
    <React.Fragment>
      <MetaTags className="pt-5">
        <title>Login | CarboneSpace</title>
      </MetaTags>

      <div style={{ backgroundColor: "#FFFFFF", height: "100vh" }}>
        <Row style={{ position: "relative", height: "100%" }}>
          <Col lg={4} />
          <Col lg={4}>
            <Card
              className="overflow-hidden"
              style={{
                marginTop: "20%",
                borderRadius: "10px",
                backgroundColor: "#F3F3F9",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              }}
            >
              <CardBody className="pt-0">
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div className="text-center mb-4">
                      <img
                        className="pt-4 pb-4"
                        src={Logo_cs}
                        style={{ borderRadius: "10px" }}
                        width="160px"
                        alt="Logo"
                      />
                      <h3 className="pt-1 pb-0">LOGIN</h3>
                      <h6
                        className="pt-0 pb-2"
                        style={{ marginBottom: "2px", color: "#495057" }}
                      >
                        Sign in to explore about Carbon Projects !
                      </h6>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="email">Username</label>
                      <AvField
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="password">Password</label>
                      <AvField
                        id="password"
                        name="password"
                        type="password"
                        required
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <br />
                    <div className="mt-1 d-grid">
                      <br />

                      <button
                        className="btn btn-block"
                        style={{ backgroundColor: "#90BE83", color: "white" }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <BarLoader
                            color={"#ffffff"}
                            loading={loading}
                            size={150}
                            height={8}
                          />
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-3 text-center">
              <p style={{ fontSize: "10px", marginBottom: "-100px" }}>
                Powered by Searchspace AI © {new Date().getFullYear()} | &nbsp;
                <a href={privacyPolicyUrl}>Privacy Policy | Cookie Policy</a>
              </p>
            </div>
          </Col>
          <Col lg={4} />
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
