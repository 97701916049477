import SectionTitle from "components/Common/SectionTitle"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"
import ContactDetailsTable from "./ContactDetailsTable"
import ValidationIndicator, { validate } from "components/Common/ValidationIndicator"

const OwnerDetails = () => {
  const dropdownValues = useSelector(state => state.wizard.dropdownData)
  const data = useSelector(state => state.wizard.wizardData)
  const dispatch = useDispatch()
  const showValidation = useSelector((state) => state.wizard.showValidation)

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label>Number of Proponent*</Label>
            <Select
              className="select-input"
              classNamePrefix="select"
              isSearchable={true}
              name="hostParties"
              options={dropdownValues["host_party"]}
              placeholder="Select the Proponet"
              onChange={e => {
                dispatch(updateWizardData({ number_of_proponents: e.id }))
              }}
              value={dropdownValues.host_party?.find(
                item => item.id === data.number_of_proponents
              )}
            />
            <ValidationIndicator dataKey={"number_of_proponents"} />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Developer*</Label>
            <Input
              type="text"
              className="form-input"
              id="project_dev"
              name="project_dev"
              invalid={showValidation && validate(data, 'project_developer')}
              placeholder="Enter Name of Entity"
              onChange={e => {
                dispatch(
                  updateWizardData({ project_developer: e.target.value })
                )
              }}
              value={data.project_developer ?? ""}
            />
            <ValidationIndicator dataKey='project_developer' />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Operator</Label>
            <Input
              type="text"
              className="form-input"
              id="project_oper"
              name="project_oper"
              placeholder="Enter Name of Entity"
              onChange={e => {
                dispatch(updateWizardData({ project_operator: e.target.value }))
              }}
              value={data.project_operator ?? ""}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="4">
          <FormGroup>
            <Label>Project Verifier/Validator</Label>
            <Input
              type="text"
              className="form-input"
              id="project_verifier"
              name="project_verifier"
              placeholder="Enter Name of Entity"
              onChange={e => {
                dispatch(
                  updateWizardData({
                    project_verifier_validator: e.target.value,
                  })
                )
              }}
              value={data.project_verifier_validator ?? ""}
            />
          </FormGroup>
        </Col>
        <Col md="8">
          <label className="header-font-custom">
            Project/Developer Website
          </label>
          <Input
            type="url"
            className="form-input"
            id="dev_website"
            name="dev_website"
            placeholder="Add Link"
            onChange={e => {
              dispatch(
                updateWizardData({ project_developer_website: e.target.value })
              )
            }}
            value={data.project_developer_website ?? ""}
          />
        </Col>
      </Row>
      <SectionTitle title="Proponent Details" />
      <ContactDetailsTable />
    </React.Fragment>
  )
}
export default OwnerDetails
