import React from "react";
import { Col, Row } from "reactstrap";

export default function SectionTitle({ title, subtitle = "", textColor = "#556EE6", subtitleColor = "#DE6262",children }) {
    return <Row className="mb-5 d-flex align-items-center">
        <Col>
            <div className="fw-bold" style={{ color: textColor, fontSize: 16, }}>{title}</div>
            <div style={{ color: subtitleColor }}>
                <small>{subtitle}</small>
            </div>
        </Col>
        <Col>{children}</Col>
    </Row>
}