import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"

// //Import Scrollbar
import jwtDecode from "jwt-decode"
import SimpleBar from "simplebar-react"
// MetisMenu
import withRouter from "components/Common/withRouter"
import MetisMenu from "metismenujs"
//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const token = localStorage.getItem("authToken")
  let userRole = null
  if (token) {
    const decodedToken = jwtDecode(token)
    userRole = decodedToken.role_id
  }
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")

      parent.classList.add("fw-bold")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        parent.classList.remove("fw-bold")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" style={{ position: "fixed", width: "250px" }}>
          <ul className="metismenu list-unstyled" id="side-menu">
            {userRole === 1 ? (
              <>
                <li>
                  <Link to="/create" reloadDocument={true}>
                    <svg width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M9 1.81818H1.8V18.1818H10.8V20H1.8C0.805887 20 0 19.186 0 18.1818V1.81818C0 0.814028 0.805887 0 1.8 0H11.1728L16.2 5.07799V11.8182H14.4V7.27273H10.8C9.80589 7.27273 9 6.4587 9 5.45455V1.81818ZM14.4 16.3636V14.5455H16.2V16.3636H18V18.1818H16.2V20H14.4V18.1818H12.6V16.3636H14.4ZM14.0272 5.45455L10.8 2.19474V5.45455H14.0272Z"
                            fill="#495057" />
                    </svg>
                    <span className="ms-2">{props.t("Create Listings")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <svg width="18" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.275 19.25L19.75 15.8L18.7 14.75L16.275 17.125L15.3 16.15L14.25 17.225L16.275 19.25ZM5 8H17V6H5V8ZM17 22C15.6167 22 14.4373 21.5123 13.462 20.537C12.4867 19.5617 11.9993 18.3827 12 17C12 15.6167 12.4877 14.4373 13.463 13.462C14.4383 12.4867 15.6173 11.9993 17 12C18.3833 12 19.5627 12.4877 20.538 13.463C21.5133 14.4383 22.0007 15.6173 22 17C22 18.3833 21.5123 19.5627 20.537 20.538C19.5617 21.5133 18.3827 22.0007 17 22ZM2 21V4C2 3.45 2.196 2.979 2.588 2.587C2.98 2.195 3.45067 1.99934 4 2H18C18.55 2 19.021 2.196 19.413 2.588C19.805 2.98 20.0007 3.45067 20 4V10.675C19.6833 10.525 19.3583 10.4 19.025 10.3C18.6917 10.2 18.35 10.125 18 10.075V4H4V18.05H10.075C10.1583 18.5667 10.2877 19.0583 10.463 19.525C10.6383 19.9917 10.8673 20.4333 11.15 20.85L11 21L9.5 19.5L8 21L6.5 19.5L5 21L3.5 19.5L2 21ZM5 16H10.075C10.125 15.65 10.2 15.3083 10.3 14.975C10.4 14.6417 10.525 14.3167 10.675 14H5V16ZM5 12H12.1C12.7333 11.3833 13.471 10.8957 14.313 10.537C15.155 10.1783 16.0507 9.99934 17 10H5V12Z"
                        fill="#495057" />
                    </svg>
                    <span className="ms-2">{props.t("Manage Listings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink to="/drafts">{props.t("Draft Listings")}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/published">
                        {props.t("Published Listings")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/*<li>*/}
                {/*  <Link to="/#" className="has-arrow ">*/}
                {/*    /!* <i className="bx bx-task " /> *!/*/}
                {/*    <img src="/icons/sidebar/Analytics.svg" className="me-3" />*/}
                {/*    <span>{props.t("Analytics")}</span>*/}
                {/*  </Link>*/}
                {/*  <ul className="sub-menu" aria-expanded="false">*/}
                {/*    <li>*/}
                {/*      <NavLink to="/retirementByProjectType">*/}
                {/*        {props.t("Retirement by Project Type")}*/}
                {/*      </NavLink>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</li>*/}
              </>
            ) : null}

            {userRole === 1 ? (
              <>
                <li>
                  <Link to="/companies">
                    <svg width="18" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z"
                        fill="#495057" />
                    </svg>
                    <span className="ms-2">{props.t("Companies")}</span>
                  </Link>
                </li>
              </>
            ) : null}
            {userRole === 1 || userRole === 2 ? <li>
              <Link to="/user">
                <svg width="18" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.07 6.41005C11.6774 5.56132 12.0041 4.54377 12.0041 3.50005C12.0041 2.45634 11.6774 1.43879 11.07 0.590053C11.6385 0.202008 12.3117 -0.00378014 13 5.2579e-05C13.9283 5.2579e-05 14.8185 0.368802 15.4749 1.02518C16.1313 1.68156 16.5 2.57179 16.5 3.50005C16.5 4.42831 16.1313 5.31855 15.4749 5.97493C14.8185 6.6313 13.9283 7.00005 13 7.00005C12.3117 7.00389 11.6385 6.7981 11.07 6.41005ZM3.5 3.50005C3.5 2.80782 3.70527 2.13113 4.08986 1.55556C4.47444 0.979985 5.02107 0.531381 5.66061 0.266474C6.30015 0.00156766 7.00388 -0.067744 7.68282 0.0673043C8.36175 0.202353 8.98539 0.535695 9.47487 1.02518C9.96436 1.51466 10.2977 2.1383 10.4327 2.81724C10.5678 3.49617 10.4985 4.1999 10.2336 4.83944C9.96867 5.47899 9.52007 6.02561 8.9445 6.4102C8.36892 6.79478 7.69223 7.00005 7 7.00005C6.07174 7.00005 5.1815 6.6313 4.52513 5.97493C3.86875 5.31855 3.5 4.42831 3.5 3.50005ZM5.5 3.50005C5.5 3.79672 5.58797 4.08673 5.7528 4.33341C5.91762 4.58008 6.15189 4.77234 6.42597 4.88587C6.70006 4.9994 7.00166 5.02911 7.29264 4.97123C7.58361 4.91335 7.85088 4.77049 8.06066 4.56071C8.27044 4.35093 8.4133 4.08366 8.47118 3.79269C8.52906 3.50172 8.49935 3.20012 8.38582 2.92603C8.27229 2.65194 8.08003 2.41767 7.83335 2.25285C7.58668 2.08803 7.29667 2.00005 7 2.00005C6.60218 2.00005 6.22064 2.15809 5.93934 2.43939C5.65804 2.7207 5.5 3.10223 5.5 3.50005ZM14 13.0001V15.0001H0V13.0001C0 13.0001 0 9.00005 7 9.00005C14 9.00005 14 13.0001 14 13.0001ZM12 13.0001C11.86 12.2201 10.67 11.0001 7 11.0001C3.33 11.0001 2.07 12.3101 2 13.0001M13.95 9.00005C14.5629 9.47678 15.064 10.0819 15.4182 10.7729C15.7723 11.4639 15.9709 12.2241 16 13.0001V15.0001H20V13.0001C20 13.0001 20 9.37005 13.94 9.00005H13.95Z"
                    fill="#495057" />
                </svg>
                <span className="ms-2">{props.t("Users")}</span>
              </Link>
            </li> : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
