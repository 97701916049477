import CardSectionTitle from "components/DetailsView/CardSectionTitle"
import React, { useState } from "react"
import Pagination from "react-js-pagination"
import { useSelector } from "react-redux"
import { Container, Table } from "reactstrap"

function IssuanceHistory() {
  const [sortColumn, setSortColumn] = useState("")
  const [sortOrders, setSortOrders] = useState({
    totalCreditRetired: "",
    totalCreditGenerated: "",
    totalBufferCredits: "",
    totalCreditsAvailable: "",
    yearPeriod: "",
  })

  const issuanceHistoryData = useSelector(
    state => state.projectDetails.details.issuanceHistory
  )

  const pageSize = 10 // Number of items to display per page
  const [activePage, setActivePage] = useState(1)

  // Get the current items to display based on the active page
  const indexOfLastItem = activePage * pageSize
  const indexOfFirstItem = indexOfLastItem - pageSize

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber)
  }

  const handleSort = column => {
    if (column === sortColumn) {
      setSortOrders({
        ...sortOrders,
        [column]: sortOrders[column] === "asc" ? "desc" : "asc",
      })
    } else {
      setSortColumn(column)
      setSortOrders({ ...sortOrders, [column]: "asc" })
    }
  }

  const data = issuanceHistoryData ? issuanceHistoryData : null

  const sortedData = data
    ? [...data]
        .sort((a, b) => {
          if (sortColumn === "totalCreditRetired") {
            return sortOrders[sortColumn] === "asc"
              ? a.totalCreditRetired - b.totalCreditRetired
              : b.totalCreditRetired - a.totalCreditRetired
          } else if (sortColumn === "totalCreditGenerated") {
            return sortOrders[sortColumn] === "asc"
              ? a.totalCreditGenerated - b.totalCreditGenerated
              : b.totalCreditGenerated - a.totalCreditGenerated
          } else if (sortColumn === "totalBufferCredits") {
            return sortOrders[sortColumn] === "asc"
              ? a.totalBufferCredits - b.totalBufferCredits
              : b.totalBufferCredits - a.totalBufferCredits
          } else if (sortColumn === "totalCreditsAvailable") {
            return sortOrders[sortColumn] === "asc"
              ? a.totalCreditsAvailable - b.totalCreditsAvailable
              : b.totalCreditsAvailable - a.totalCreditsAvailable
          } else if (sortColumn === "yearPeriod") {
            if (
              typeof a.yearPeriod === "string" &&
              typeof b.yearPeriod === "string"
            ) {
              const dateA = new Date(a.yearPeriod.split("-")[0])
              const dateB = new Date(b.yearPeriod.split("-")[0])
              return sortOrders[sortColumn] === "asc"
                ? dateA - dateB
                : dateB - dateA
            } else {
              const dateA = new Date(a.yearPeriod)
              const dateB = new Date(b.yearPeriod)
              return sortOrders[sortColumn] === "asc"
                ? dateA - dateB
                : dateB - dateA
            }
          }
          return 0
        })
        .slice(indexOfFirstItem, indexOfLastItem)
    : []

  if (!issuanceHistoryData) {
    return <div>--</div> // or any other suitable loading state
  }

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle
          title="Issuance History"
          icon={<div style={{ marginLeft: "24px" }}></div>}
        />
        <div className="table-responsive">
          <Table className="data-table">
            <thead>
              <tr
                style={{ borderBottom: "2px solid #DEDCDE", fontSize: "0.rem" }}
              >
                <th
                  className="ash-text"
                  onClick={() => handleSort("yearPeriod")}
                >
                  Vintage Period{" "}
                  {sortColumn === "yearPeriod" && sortOrders.yearPeriod === ""
                    ? "▶"
                    : sortOrders.yearPeriod === "asc"
                    ? "▲"
                    : "▼"}
                </th>
                <th
                  className="ash-text"
                  onClick={() => handleSort("totalCreditGenerated")}
                >
                  Credit Generated
                  {sortColumn === "totalCreditGenerated" &&
                  sortOrders.totalCreditGenerated === "asc"
                    ? "▲"
                    : "▼"}
                </th>
                <th
                  className="ash-text"
                  onClick={() => handleSort("totalCreditRetired")}
                >
                  Credit Retired
                  {sortColumn === "totalCreditRetired" &&
                  sortOrders.totalCreditRetired === "asc"
                    ? "▲"
                    : "▼"}
                </th>
                <th
                  className="ash-text"
                  onClick={() => handleSort("totalBufferCredits")}
                >
                  Buffer Credits
                  {sortColumn === "totalBufferCredits" &&
                  sortOrders.totalBufferCredits === "asc"
                    ? "▲"
                    : "▼"}
                </th>
                <th
                  className="ash-text"
                  onClick={() => handleSort("totalCreditsAvailable")}
                >
                  Credits Available
                  {sortColumn === "totalCreditsAvailable" &&
                  sortOrders.totalCreditsAvailable === "asc"
                    ? "▲"
                    : "▼"}
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody>
              {sortedData.map((row, index) => (
                <tr key={index}>
                  <td className="black-text">{row.yearPeriod}</td>
                  <td className="black-text">
                    {new Intl.NumberFormat("en-US").format(
                      row.totalCreditGenerated
                    )}
                  </td>
                  <td className="black-text">
                    {new Intl.NumberFormat("en-US").format(
                      row.totalCreditRetired
                    )}
                  </td>
                  <td className="black-text">
                    {new Intl.NumberFormat("en-US").format(
                      row.totalBufferCredits
                    )}
                  </td>
                  <td className="black-text">
                    {new Intl.NumberFormat("en-US").format(
                      row.totalCreditsAvailable
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-end mt-4">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pageSize}
            totalItemsCount={issuanceHistoryData.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="pagination-item"
            activeClass="pagination-active-item"
            activeLinkClass="pagination-active-item"
            hideFirstLastPages
            itemClassNext="pagination-button"
            itemClassPrev="pagination-button"
          />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default IssuanceHistory
