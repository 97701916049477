import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
// import CardSectionTitle from "components/DetailsView/CardSectionTitle"

function PieChartPopUp({ xValue, yValues }) {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        width: 470,
        type: "pie",
        animations: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          customScale: 1,
        },
      },
      grid:{
        padding:{
          left: -30,
          bottom: 30,
          top : 0,
          // right: 10,
        }
      },
      dataLabels: {
        enabled : true,
        dropShadow: {
          enabled: false,
        },
        style:{
          fontSize: '12px',
          fontFamily: 'Poppins', 
          fontStyle: 'normal',
          fontWeight: '600',
          color:"#FFF"
        },
      },
      labels: [],
      colors: [], // Add a colors array for custom colors
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              shape: "square",
              radius: 2
            },
          },
        },
      ],
      legend: {
        offsetY: -3,
        offsetX: -30,
        markers: {
          shape: "square", // Set the legend marker shape to "square"
          radius: 2,
          padding: 3,
          offsetX: 0,
          offsetY: 3,
        },
        itemMargin: {
          vertical: 5,
          horizontal: 3
        },
      },
    },
  })

  useEffect(() => {
    if (xValue && yValues && yValues.length > 0) {
      // Extract label names from yValues
      for (let i = 0; i < yValues.length; i++) {
        chartData.options.labels[i] = yValues[i].name
      }

      // Set series data based on yValues
      const series = yValues.map(item => item.value)

      // Define a map of color names to their respective hexadecimal values
      const colorMap = {
        "Nature Based": "#1D9D60",
        Energy: "#F8BA1D",
        Household: "#19A1B4",
        Industrial: "#015F81",
        "Waste Management": "#A6B0CF",
        Transport: "#8DD98E",
        Other: "#7AA5BF",
      }

      // Set custom colors for each segment based on the map
      const colors = chartData.options.labels.map(label => colorMap[label])

      setChartData({
        ...chartData,
        series,
        options: {
          ...chartData.options,
          colors,
        },
      })
    }
  }, [xValue, yValues])

  return (
    <React.Fragment>
      {/* <CardSectionTitle title="Pie Chart" subTitle={xValue}/> */}
      
    
      <div
        className="pie-chart-any"
        id="chart"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="pie"
          width={chartData.options.chart.width}
        />
      </div>
    </React.Fragment>
  )
}

export default PieChartPopUp
