import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import emailsuccess from "../../assets/images/email-error.svg"
const ContactErroring = () => {
  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <div className="cont-success">
            <h3>Your message wasn't delivered.</h3>
            <h6>While transferring your message, there was an issue.</h6>
            <h6>Please try once again later.</h6>
            <br />
            <img src={emailsuccess} className="email-suc"></img>
          </div>
          <br />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContactErroring
