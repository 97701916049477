import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"
import SDGPicker from "./SDGPicker"
import ValidationIndicator from "components/Common/ValidationIndicator"

export default function ProjectDetails() {
  const dispatch = useDispatch()
  const dropdownValues = useSelector(state => state.wizard.dropdownData)
  const data = useSelector(state => state.wizard.wizardData)

  // const allItems = [
  //   {
  //     id: 768,
  //     label: "1.No Poverty",
  //   },
  //   {
  //     id: 769,
  //     label: "2.Zero Hunger",
  //   },
  //   {
  //     id: 770,
  //     label: "3.Good Health and well being",
  //   },
  //   {
  //     id: 771,
  //     label: "4.Quality education",
  //   },
  //   {
  //     id: 772,
  //     label: "5.Gender equality",
  //   },
  //   {
  //     id: 773,
  //     label: "6.Clean water and sanitation",
  //   },
  //   {
  //     id: 774,
  //     label: "7.Affordable and clean energy",
  //   },
  //   {
  //     id: 775,
  //     label: "8.Decent work and economic growth",
  //   },
  //   {
  //     id: 776,
  //     label: "9.Industry, innovation and infrastructure",
  //   },
  //   {
  //     id: 777,
  //     label: "10.Reduced inequalities",
  //   },
  //   {
  //     id: 778,
  //     label: "11.Sustainable cities and communities",
  //   },
  //   {
  //     id: 779,
  //     label: "12.Responsible consumption and production",
  //   },
  //   {
  //     id: 780,
  //     label: "13.Climate action",
  //   },
  //   {
  //     id: 781,
  //     label: "14.Life below water",
  //   },
  //   {
  //     id: 782,
  //     label: "15.life on land",
  //   },
  //   {
  //     id: 783,
  //     label: "16.Peace, justice and strong institution",
  //   },
  //   {
  //     id: 784,
  //     label: "17.Partnership for the goals",
  //   },
  // ]

  const devGoals = data?.sustainable_development_goals?.split(",")

  const [selectedSDG, setSelectedSDG] = useState(   // eslint-disable-line no-unused-vars
    devGoals?.length
      ? devGoals
        .filter(goal => goal != "" || goal != "NaN")
        .map(item => parseInt(item))
      : []
  )

  // Handler function to update the selected SDG
  const handleSDGChange = goals => {
    goals = goals.filter(goal => goal != "" || goal != "NaN").join(",")
    dispatch(
      updateWizardData({
        sustainable_development_goals: goals,
      })
    )
    setSelectedSDG(goals)
  }

  // Function to get the selected SDG labels
  // const getSelectedSDGLabels = () => {
  //   return selectedSDG.map(id => {
  //     const selectedSDGItem = allItems.find(item => item.id === id)
  //     return selectedSDGItem ? selectedSDGItem.label : null
  //   })
  // }

  const handleMethodologyChange = selectedOptions => {
    const selectedMethodologies = selectedOptions
      .map(e => e.value)
      .join(",")
    dispatch(
      updateWizardData({
        methodology: selectedMethodologies,
      })
    )
  }

  const methodologyOptions = dropdownValues["meth"].map(item => ({
    label: item["label"],
    value: item["id"],
  }));

  const selectedMethodologyIDs = data.methodology
    ? data.methodology.split(",").map(item => parseInt(item))
    : [];

  const selectedMethodologies = methodologyOptions.filter(option =>
    selectedMethodologyIDs.includes(option.value)
  );

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label>Technology</Label>
            <Input
              type="text"
              className="form-input"
              placeholder="Enter the Technology Used"
              value={data.technology ?? ""}
              onChange={e => {
                dispatch(updateWizardData({ technology: e.target.value }))
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Methodology*</Label>
            <Select
              className="select-input"
              classNamePrefix="select"
              isSearchable={true}
              isMulti
              options={methodologyOptions}
              placeholder="Select Methodology"
              onChange={handleMethodologyChange}
              value={selectedMethodologies}
            />
            <ValidationIndicator dataKey='methodology' />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Area</Label>
            <Row>
              <Col md="5">
                <Input
                  type="number"
                  className="form-input"
                  placeholder="Enter Value"
                  onChange={e =>
                    dispatch(
                      updateWizardData({ project_area_value: e.target.value })
                    )
                  }
                  value={data.project_area_value}
                />
              </Col>
              <Col md="7">
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  isSearchable={true}
                  options={dropdownValues["meas_unit"]}
                  placeholder="Select  Unit"
                  onChange={e => {
                    dispatch(updateWizardData({ project_area_unit: e.id }))
                  }}
                  value={dropdownValues["meas_unit"].find(
                    item => item.id == data.project_area_unit
                  )}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup className="fourrows">
            <Label>Project Boundaries</Label>
            <textarea
              className="form-control"
              value={data.boundaries ?? ""}
              rows={9}
              placeholder="Enter Project Boundaries"
              onChange={e => {
                dispatch(updateWizardData({ boundaries: e.target.value }))
              }}
            ></textarea>
          </FormGroup>
        </Col>

        <Col md="4">
          <Row>
            <Col md="12">
              <FormGroup>
                <Label>Activity Scale</Label>
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="activityScale"
                  options={dropdownValues["act_scal"]}
                  placeholder="Select the Scale"
                  onChange={e => {
                    dispatch(updateWizardData({ activity_scale: e.id }))
                  }}
                  value={dropdownValues["act_scal"].find(
                    item => item.id == data.activity_scale
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label>Relevant GHG sources</Label>
                <Input
                  type="text"
                  className="form-input"
                  id="ghg_source"
                  name="ghg_source"
                  placeholder="Enter the Gases Project Addresses"
                  onChange={e => {
                    dispatch(updateWizardData({ ghg_sources: e.target.value }))
                  }}
                  value={data.ghg_sources ?? ""}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label>Environmental Impact</Label>
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="envImpacts"
                  options={dropdownValues["env_imp"]}
                  placeholder="Select the Impact"
                  value={dropdownValues["env_imp"].find(
                    item => item.id == data.environmental_impact
                  )}
                  onChange={e => {
                    dispatch(updateWizardData({ environmental_impact: e.id }))
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col md="12">
          <FormGroup>
            <Label>Project Notes/Remarks</Label>
            <textarea
              rows={3}
              value={data.project_notes_remarks ?? ""}
              className="form-control"
              placeholder="Enter Notes/Remarks"
              onChange={e => {
                dispatch(
                  updateWizardData({ project_notes_remarks: e.target.value })
                )
              }}
            ></textarea>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label>Suatainable Development Goals (UNSDG)</Label>
            {/* <div style={{ display: "flex", flexWrap: "wrap", backgroundColor: "#FFFFFF", borderRadius: "5px", border: "1px solid #CED4DA"  }}>
              {getSelectedSDGLabels().map((label) => (
                <div
                  key={label}
                  style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                    padding: "5px",
                    margin: "5px",
                  }}
                >
                  {label}
                </div>
              ))}
            </div> */}
            <SDGPicker
              onChange={handleSDGChange}
              itemSize={64}
              defaultValue={
                data.sustainable_development_goals
                  ?.split(",")
                  .map(item => parseInt(item)) ?? []
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  )
}
