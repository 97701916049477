import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const postContactUs = createAsyncThunk(
  'contactUs/post',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/email/contactUs',
        payload,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      const data = response.data;
      if (data.error) {
        throw new Error(data.payload);
      }
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);