import React, { useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
// import { useNavigate } from "react-router-dom"

// users
// import user1 from "../../../assets/images/users/profile.svg"
import jwtDecode from "jwt-decode"
import { logout } from "rtk-store/slices/authSlice"
import profileIcon from "../../../assets/images/pro-icon.svg"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const token = localStorage.getItem("authToken")
  let userRole = null
  let userRoleName = ""
  let userName = "Admin" // Default username, you can change it as needed.

  if (token) {
    const decodedToken = jwtDecode(token)
    userRole = decodedToken.role_id // Assuming the role ID is stored in the token.
    userName = decodedToken.username // Assuming the username is stored in the token.

    switch (userRole) {
      case 1:
        userRoleName = "Super Admin"
        break
      case 2:
        userRoleName = "Company Admin"
        break
      case 3:
        userRoleName = "User"
        break
    }
  }

  const handleLogout = () => {
    dispatch(logout())
    // navigate("/login")
    // eslint-disable-next-line no-undef
    window.location.href = process.env.REACT_APP_OTHER_SERVICE_URL+"?logout=true";

  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div style={{ marginTop: "-2px" }}><img height="30px" src={profileIcon} /></div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end ">
          <DropdownItem tag="span">
            <h5>{userName}</h5><h6 className="mb-0" style={{ color: "#90BE83" }}>{userRoleName}</h6>
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem tag="a" onClick={handleLogout} style={{ color: "#EE1C25", fontWeight: 700 }}>
            {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(ProfileMenu)