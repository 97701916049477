/* eslint-disable no-unused-vars */
import Breadcrumbs from "components/Common/Breadcrumb"
import Stepper from "components/CreateListing/Stepper"
import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Container, Row } from "reactstrap"
import {
  STEPS,
  goToNextStep,
  goToPrevStep,
  goToStep,
  resetDirtyCount,
  resetWizardData,
  updateValidationState,
} from "rtk-store/slices/wizardSlice"
import Swal from "sweetalert2"
// Import Componets
import ApprovalCertificationDetails from "components/CreateListing/Approval/ApprovalCertification"
import CoordinatesDetails from "components/CreateListing/Coordinates/CoordinatesDetails"
import CreditingDetails from "components/CreateListing/Crediting/CreditingDetails"
import ListingDetails from "components/CreateListing/Listing/ListingDetails"
import OwnerDetails from "components/CreateListing/Owner/OwnerDetails"
import ProjectDetails from "components/CreateListing/Project/ProjectDetails"
import RegistryProfileDetails from "components/CreateListing/Registry/RegistryProfile"
import ResourceDetails from "components/CreateListing/Resources/ResourceDetails"

import { getDropdownDataApi } from "components/core/services/getDropdownDataApi"
import { getDraftProjectByID } from "components/core/services/getProjectByIDApi"
import { publishProject } from "components/core/services/publishProjectApi"
import { saveDraft } from "components/core/services/saveDraftApi"
import { useNavigate, useParams } from "react-router-dom"
// import { JSONTree } from "react-json-tree"

// Import Componets

// eslint-disable-next-line no-unused-vars
const CreateListing = props => {
  const { id } = useParams()
  const navigate = useNavigate()

  document.title = "Create Listing | Carbone Space"

  const dispatch = useDispatch()

  const data = useSelector(state => state.wizard.wizardData)
  const steps = useSelector(state => state.wizard.steps)
  const activeStep = useSelector(state => state.wizard.activeStep)

  const publishButtonEnabled = useSelector(
    state => state.wizard.publishButtonEnabled
  )
  const canNavigateUsingMouse = useSelector(
    state => state.wizard.canNavigateUsingMouse
  )

  const loading = useSelector(state => state.wizard.loading)
  const error = useSelector(state => state.wizard.error)

  // Autosaving
  const dirtyCount = useSelector(state => state.wizard.dirtyCount)

  useEffect(() => {
    if (dirtyCount >= 3 && !loading && !error && activeStep != 0) {
      dispatch(saveDraft(data)).then(() => {
        dispatch(resetDirtyCount())
      })
    }
  }, [dirtyCount])

  const handleNextButton = async () => {
    // await dispatch(saveDraft(data))
    if (!loading) {
      if (activeStep == 0) {
        if (!data["prj_creator_name"] || !data["prj_creator_name"].length) {
          Swal.fire({
            title: "Oops!",
            text: "Creator name is required to create a new project!",
            icon: "error",
            showConfirmButton: true,
          })
          return
        }
      }
      dispatch(saveDraft(data)).then((_) => {
        dispatch(goToNextStep())
      })
    }
  }

  const handlePreviousButton = async () => {
    if (!loading) {
      dispatch(saveDraft(data)).then((_) => {
        dispatch(goToPrevStep())
      })
    }
  }

  const handleSaveDraft = async () => {
    await dispatch(saveDraft(data))
    // Show a success message using SweetAlert
    Swal.fire({
      title: "Draft Saved",
      text: "The draft has been saved successfully.",
      icon: "success",
      showConfirmButton: false,
      toast: true,
      position: "top-right",
      timer: 1500,
      timerProgressBar: true,
    })
  }

  useEffect(() => {
    if (data.id && !id) {
      navigate("/edit/" + data.id)
    }
  }, [data])

  useEffect(() => {
    if (id) {
      dispatch(getDraftProjectByID({ id: id, step: activeStep }))
    }
  }, [activeStep, id])

  useEffect(() => {
    dispatch(getDropdownDataApi())
    return () => {
      dispatch(resetWizardData())
    }
  }, [])

  const setErrorState = errorSteps => {
    let newSteps = [...steps]
    for (let index = 0; index < steps.length; index++) {
      const newStep = { ...steps[index] }
      if (errorSteps.indexOf(index) === -1) {
        newStep["status"] = "success"
      } else {
        newStep["status"] = "error"
      }
      newSteps[index] = newStep
    }
    dispatch(updateValidationState(newSteps))
  }

  const handlePublish = async () => {
    await dispatch(saveDraft(data))
    const isValidEmail = email => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return emailRegex.test(email)
    }
    const requiredFields = {
      // 0: ["registry"],
      // 1: ["number_of_proponents"],
      // 2: [
      //   "project_title",
      //   "registry_status_id",
      //   "project_type_ids",
      //   "projectSectoralScopes_ids",
      //   "project_main_activity",
      //   "project_description",
      // ],
      // 3: ["methodology"],
      // 6: ["est_annu_emission_red", "project_length"],
    }
    // let errorSteps = Object.entries(requiredFields)
    //   .filter(([_, fields]) =>
    //     fields.some(
    //       field => !data[field] || !data[field] === "" || data[field] === null
    //     )
    //   )
    //   .map(([step]) => parseInt(step))
    // if (!data["contact_details"]?.length) {
    //   errorSteps.push(1)
    // } else {
    //   let hasError = false
    //   data["contact_details"]?.forEach(item => {
    //     let fields = ["proponentName", "contactPersonName", "country"]
    //     fields.forEach(field => {
    //       if (!item[field] || !item[field].length) {
    //         hasError = true
    //       }
    //       if (typeof item[field] === "number") {
    //         hasError = isNaN(item[field])
    //       }
    //       // if (field == 'email') {
    //       //   hasError = !isValidEmail(item[field])
    //       // }
    //     })
    //   })
    //   if (hasError) {
    //     errorSteps.push(1)
    //   }
    // }
    // if (!data["coordinates"]?.length) {
    //   errorSteps.push(4)
    // } else {
    //   let hasError = false
    //   data["coordinates"]?.forEach(item => {
    //     let fields = ["country", "latitude", "longitude", "region_location"]
    //     fields.forEach(field => {
    //       if (typeof item[field] === "number") {
    //         hasError = isNaN(item[field])
    //       } else if (
    //         !item[field] ||
    //         !item[field].length ||
    //         item[field] === ""
    //       ) {
    //         hasError = true
    //       }
    //     })
    //   })
    //   if (hasError) {
    //     errorSteps.push(4)
    //   }
    // }
    let errorSteps = []
    if (errorSteps.length === 0) {
      try {
        await dispatch(publishProject(data))
        Swal.fire({
          title: "Success",
          text: "Project was published successfully!",
          icon: "success",
          showConfirmButton: false,
          toast: true,
          position: "top-right",
          timer: 1500,
          timerProgressBar: true,
        })
        navigate("/published")
      } catch (error) {
        console.log(error)
      }
    } else {
      Swal.fire({
        title: "Unable to publish project",
        text: "Please complete the required fields to publish your project.",
        icon: "error",
        showConfirmButton: false,
        toast: true,
        position: "top-right",
        timer: 1500,
        timerProgressBar: true,
      })
      setErrorState(errorSteps)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error && (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error}`}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Breadcrumbs
                title="Create Listing"
                breadcrumbItems={[
                  {
                    link: "#",
                    title: "Drafts",
                  },
                  {
                    link: "#",
                    title: data.draft_id ?? "Unsaved",
                  },
                ]}
              />
            </Col>
            <Col className="text-end">
              {loading && (
                <span
                  className="spinner spinner-border spinner-sm me-3"
                  style={{ width: "1rem", height: "1rem", color: "#83c7d4" }}
                ></span>
              )}
              <Button
                className="btn btn-outline-light me-3"
                style={{
                  border: "2px solid #83C7D4",
                  color: "#83C7D4",
                }}
                outline
                onClick={handleSaveDraft}
              >
                Save Draft
              </Button>
              <Button
                disabled={data.id === undefined || !publishButtonEnabled || loading}
                color="dark"
                style={{
                  backgroundColor: "#83C7D4",
                  border: "2px solid #83C7D4",
                }}
                onClick={handlePublish}
              >
                Publish
              </Button>
            </Col>
          </Row>
          {/* <JSONTree data={data} /> */}
          <Row className="mb-5">
            <Col>
              <Stepper
                steps={steps}
                activeStep={activeStep}
                onClick={step => {
                  if (canNavigateUsingMouse) {
                    dispatch(goToStep(step))
                  }
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              paddingBottom: "250px",
            }}
          >
            {activeStep === 0 ? (
              <RegistryProfileDetails />
            ) : activeStep === 1 ? (
              <OwnerDetails />
            ) : activeStep === 2 ? (
              <ListingDetails />
            ) : activeStep === 3 ? (
              <ProjectDetails />
            ) : activeStep === 4 ? (
              <CoordinatesDetails />
            ) : activeStep === 5 ? (
              <ApprovalCertificationDetails />
            ) : activeStep === 6 ? (
              <CreditingDetails />
            ) : activeStep === 7 ? (
              <ResourceDetails />
            ) : null}
          </div>
          <div>
            <Row
              style={{
                position: "fixed",
                bottom: '6%', // Adjust this value to match the height of your footer
                right: 0,
                left: 0,
                zIndex: 10,
                background: "rgba(255,255,255,0.75)",
                padding: "1rem",
                backdropFilter: "blur(5px)",
              }}
            >
              <Col className="d-flex justify-content-between align-items-center">
                <div className="text-muted">{/* <Footer /> */}</div>
                <div className="text-end">
                  <Button
                    disabled={loading || activeStep == 0}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "#7BC7A9",
                      color: "#7BC7A9",
                    }}
                    className="me-3"
                    onClick={() => {
                      handlePreviousButton()
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    disabled={loading || activeStep == STEPS - 1}
                    style={{
                      backgroundColor: "#7BC7A9",
                      borderColor: "#7BC7A9",
                    }}
                    onClick={() => {
                      handleNextButton()
                    }}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* <SaveFab /> */}
    </React.Fragment>
  )
}

export default withTranslation()(CreateListing)
