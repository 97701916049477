import { getUsersList } from "components/core/services/getUsers"
import {
  deleteUser,
  postCreateUser,
  postUserActivation,
  updateUser,
} from "components/core/services/postUsers"
import UserModal from "components/UserManagment/UserModal"
import jwtDecode from "jwt-decode"
import React, { useEffect, useState } from "react"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Swal from "sweetalert2"
import "sweetalert2/dist/sweetalert2.css"
import emptyLogo from "../../assets/images/emptyFolder.png"
import { toggleUserState } from "../../rtk-store/slices/userSlice"
import { getCompaniesList } from "components/core/services/getCompanies"

export default function DraftListings() {
  document.title = "Users | CarboneSpace"
  const dispatch = useDispatch()
  const data = useSelector(state => state.users.users)
  const loading = useSelector(state => state.users.loading)
  const error = useSelector(state => state.users.error)
  const [selectedUser, setSelectedUser] = useState()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [visibleData, setVisibleData] = React.useState(data)
  const companyList = useSelector(state => state.companies.companies)
  const mappedCompanyData = companyList
    ?.filter(item => {
      return item.isActive
    })
    .map(item => ({
      id: item.id,
      label: item.companyName,
    }))
  useEffect(() => {
    if (search) {
      const filteredData = data?.filter(item => {
        return (
          item.fullName?.toLowerCase().includes(search.toLowerCase()) ||
          item.organizationName?.toLowerCase().includes(search.toLowerCase()) ||
          item.email?.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber?.toLowerCase().includes(search.toLowerCase())
        )
      })
      setVisibleData(filteredData)
    } else {
      setVisibleData(data) // Reset to show all data when search is empty
    }
  }, [data, search])

  useEffect(() => {
    dispatch(getUsersList())
    dispatch(getCompaniesList())
  }, [])

  const handleUserStatus = async (id, status) => {
    try {
      const newUserStatus = status === 1 ? 0 : 1
      const payload = {
        id: id,
        isActive: newUserStatus,
      }
      dispatch(toggleUserState(payload))
      const response = await dispatch(postUserActivation(payload))
      if (response.payload !== "Deactivated successfully") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User activated successfully.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
        })
      } else if (response.payload !== "Activated successfully") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User deactivated successfully.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        throw new Error(response.payload ?? "Error!")
      }
      dispatch(getUsersList())
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  const deleteSelectedUser = async id => {
    try {
      Swal.fire({
        title: "Delete?",
        text: "Are you sure you want to delete this user from this company?",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#7AA5BF",
        cancelButtonColor: "#F46A6A",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteUser({ id: id }))
          if (response.payload.message === "Deleted Successfully.") {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "User deleted successfully.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch(getUsersList())
          } else {
            throw new Error()
          }
        }
      })
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  const token = localStorage.getItem("authToken")
  const decodedToken = jwtDecode(token)

  const role = decodedToken?.role_id;
  const disableAction = email => {
    if (decodedToken.email === email) {
      return true
    }
    return false
  }
  const userCompany = mappedCompanyData?.filter(obj => {
    return obj.id === decodedToken.companyId
  }) || []; // Default to an empty array if userCompany is undefined

  let companyLabel = userCompany[0]?.label || "Company"; // Default to "Company" if companyLabel is undefined

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="fs-5 fw-bold mb-3">
                {role === 1 ? 'LIST OF USERS' : `LIST OF USERS FOR ${companyLabel.toUpperCase()}`}
              </div>
            </Col>
          </Row>
          <Row className="mb-4 mt-2">
            <Col className="d-none d-md-block" />
            <Col className="text-end">
              <div
                className="gap-3 d-flex align-item-center justify-content-end"
                style={{ height: "38px" }}
              >
                <div className="input-group" style={{
                  width: "247px",
                }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Search..."}
                    style={{
                      backgroundColor: "#ffffff",
                      borderTop: "1px solid #CED4DA",
                      borderBottom: "1px solid #CED4DA",
                      borderLeft: "1px solid #CED4DA",
                      borderRadius: 20,
                    }}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <i
                    className="bx bx-search-alt"
                    style={{
                      position: "absolute",
                      zIndex: 200,
                      right: 16,
                      top: 14,
                    }}
                  />
                </div>
                <Button
                  className="btn"
                  color="dark"
                  style={{
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    backgroundColor: "#7AA5BF",
                    border: "2px solid #7AA5BF",
                    borderRadius: 4,
                    minWidth: 150,
                  }}
                  onClick={() => {
                    setSelectedUser(null)
                    setIsEditModalOpen(true)
                  }}
                >
                  Add User
                </Button>
              </div>
            </Col>
          </Row>
          {error ? (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error}`}
                </div>
              </Col>
            </Row>
          ) : null}
          <Modal isOpen={loading} centered>
            <ModalBody className="text-center">
              <RotatingLines />
              <p className="mt-2">Loading...</p>
            </ModalBody>
          </Modal>
          {!loading && (
            <div style={{ height: "60vh", overflow: "auto" }}>
              {visibleData?.length ? (
                <Table className="table table-striped mb-0">
                  <thead className="sticky-th tbl-publish">
                    <tr>
                      <th>Active</th>
                      <th>User ID</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Organization Name</th>
                      <th>Role</th>
                      <th>Onboarding Type</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibleData.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div className="square-switch">
                              <input
                                style={{
                                  cursor: disableAction(item.email)
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                disabled={disableAction(item.email)}
                                type="checkbox"
                                id={`square-switch${item.id}`}
                                className="switch switch-bool"
                                checked={item.isActive}
                                onClick={() => {
                                  handleUserStatus(
                                    item.id,
                                    item.isActive
                                  ).catch()
                                }}
                              />
                              <label
                                style={{
                                  cursor: disableAction(item.email)
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                htmlFor={`square-switch${item.id}`}
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </td>
                          <td>{'U' + String(item.id).padStart(6, '0')}</td>
                          <td>{item?.fullName ? item.fullName : "-"}</td>
                          <td>{item.email}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.phoneNumber ? `${item.phoneNumber}` : "-"}
                          </td>

                          <td>
                            {item?.organizationName
                              ? item.organizationName
                              : "-"}
                          </td>

                          <td>{item.role}</td>
                          <td>{item.onBoardingType}</td>
                          <td>
                          <Link
                              disabled={!item.isActive}
                              style={{ 
                                color: "#83C7D4" ,
                                cursor: (!item.isActive)
                              ? "not-allowed"
                              : "pointer",
                              }}
                              onClick={() => {
                                if (item.isActive) {
                                setSelectedUser(item.id)
                                setIsEditModalOpen(true)
                                }
                              }}
                            >

                              <i className="bx bx-edit fs-3" id="edit-btn"></i>
                            </Link>
                          </td>
                          <td>
                            <Link
                              disabled={disableAction(item.email)}
                              style={{
                                color: "#F46A6A",
                                cursor: disableAction(item.email)
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (!disableAction(item.email)) {
                                  deleteSelectedUser(item.id)
                                }
                              }}
                            >
                              <i className="bx bx-trash fs-3" id="edit-btn"></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={{minHeight:'60vh'}}>
                <div className="text-center">
                  <img
                    className="pt-4"
                    src={emptyLogo}
                    width="120px"
                    alt="Logo"
                  />
                  <p
                    className="text-center"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#C4C4C4",
                    }}
                  >
                    {!search
                      ? "It seems like there are currently no records to display."
                      : "We couldn't find any results that match your search"}
                  </p>
                </div>
              </div>
              )}
            </div>
          )}
        </Container>

        <Modal
          size="lg"
          isOpen={isEditModalOpen}
          toggle={() => {
            setIsEditModalOpen(false)
            setSelectedUser(undefined)
          }}
        >
          <ModalHeader
            toggle={() => {
              setIsEditModalOpen(false)
              setSelectedUser(undefined)
            }}
            style={{ backgroundColor: "#F9F9F9", color: "#000" }}
          >
            {selectedUser ? "EDIT USER" : "ADD NEW USER"}
          </ModalHeader>
          <ModalBody style={{}}>
            <UserModal
              userId={selectedUser}
              onSave={async data => {
                setIsEditModalOpen(false)
                setSelectedUser(undefined)
                try {
                  let response
                  if (data["id"]) {
                    response = await dispatch(updateUser(data))
                  } else {
                    response = await dispatch(postCreateUser(data))
                  }
                  if (!response.payload) {
                    throw new Error("User already exists!")
                  }
                  // let message = data["id"]
                  dispatch(getUsersList())
                  Swal.fire({
                    title: "Success",
                    text: response.payload.message,
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                    showCancelButton: false,
                  })
                } catch (error) {
                  Swal.fire({
                    title: "Oops!",
                    text: "Unable to create user as the user already exists.",
                    icon: "error",
                    confirmButtonColor: "#7AA5BF",
                  })
                }
              }}
              closeModal={() => {
                setIsEditModalOpen(false)
                setSelectedUser(undefined)
              }}
            />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}
