/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const publishProject = createAsyncThunk('/publish/publishProject', async (wizardData) => {
    try {
        const res = await api.post('/publish/publishProject/1', { id: wizardData.id }).then((res) => res.data)
        if (res['error']) {
            throw new Error(res['payload'])
        }
        return res['payload']
    } catch (error) {
        throw error
    }
})

export const postProjectActivation = createAsyncThunk(
    '/publish/disablePublishProject',
    async ({payload}) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await api.post(
           `/publish/disablePublishProject`,
           payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-type': 'application/json; charset=UTF-8'
            }
          }
        );
        const data = response.data;
        if (data['error']) {
          throw new Error(data['payload']);
        }
        return data['payload'];
      } catch (error) {
        throw error;
      }
    }
  );