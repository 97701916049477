import ValidationIndicator, { validate } from "components/Common/ValidationIndicator";
import ValidationLabel from "components/Common/ValidationLabel";
import api from "components/core/services/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { updateWizardData } from "rtk-store/slices/wizardSlice";

const DESCRIPTION_WORDS = 1000

export default function ListingDetails() {
  const dispatch = useDispatch()
  const sectoralScopes = useSelector((state) => state.wizard.sectoralScopeData);
  const registryStatus = useSelector(state => state.wizard.registryStatus)
  const data = useSelector((state) => state.wizard.wizardData)
  const [projectTypes, setProjectTypes] = useState([])
  const currentDate = moment().format("YYYY-MM-DD")
  let selectedSectoralScopeIDS = data.projectSectoralScopes_ids
  let selectedSectoralScopes = sectoralScopes.filter(item => selectedSectoralScopeIDS?.indexOf(item.id) > -1)

  const countWords = (text) => {
    const words = text?.trim().split(/\s+/);
    return words?.length || 0;
  };

  useEffect(() => {
    if (selectedSectoralScopeIDS && selectedSectoralScopeIDS.length) {
      let ids = ""
      selectedSectoralScopeIDS.split(",").forEach(id => {
        ids += "sectoralScopesId[]=" + id + "&"
      })
      api.get('/dropdown/getProjectTypesBySectoralScopesId?' + ids).then((res) => res.data).then(data => {
        if (data.payload && data.payload.length) {
          let types = data.payload
          setProjectTypes(types)
        }
        else {
          setProjectTypes([])
        }
      })
    }
  }, [selectedSectoralScopeIDS])
  const selectedProjectTypeOptions = []
  if (data.project_type_ids) {
    let ids = data.project_type_ids.split(",").map(item => parseInt(item))
    if (projectTypes.length) {
      projectTypes.forEach(type => {
        if (ids.indexOf(type.id) > -1) {
          selectedProjectTypeOptions.push(type)
        }
      })
    }
  }
  const showValidation = useSelector((state) => state.wizard.showValidation)
  return (
    <React.Fragment>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label>Project Title*</Label>
            <Input
              type="text"
              className="form-input"
              invalid={showValidation && validate(data, 'project_title')}
              placeholder="Enter Name of Project"
              onChange={e => {
                dispatch(updateWizardData({ project_title: e.target.value }))
              }}
              value={data.project_title ?? ''}
            />
            <ValidationIndicator dataKey='project_title' />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Registry Status*</Label>
            <Select
              className="select-input"
              classNamePrefix="select"
              isSearchable
              isClearable
              name="statuses"
              options={registryStatus}
              placeholder="Select the Status"
              value={registryStatus.find(item => item.id == data.registry_status_id)}
              onChange={e => {
                dispatch(updateWizardData({ registry_status_id: e?.id }))
              }}
            />
            <ValidationIndicator dataKey='registry_status_id' />
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <Label>Project Description*</Label>
            <div style={{ position: "relative" }}>
              <Input
                rows={8}
                className="form-control"
                placeholder="Enter Project Description"
                value={data.project_description ?? ''}
                onChange={e => {
                  const newValue = e.target.value;
                  if (countWords(newValue) <= DESCRIPTION_WORDS) {
                    dispatch(updateWizardData({ project_description: newValue }));
                  }
                }}
                type="textarea"
                invalid={showValidation && validate(data, 'project_description')}
              />
              <p className="des-char" style={{ color: countWords(data.project_description) === DESCRIPTION_WORDS ? "red" : 'gray' }}>
                {countWords(data.project_description)} / {DESCRIPTION_WORDS} words
              </p>
            </div>
            <ValidationIndicator dataKey='project_description' />
          </FormGroup>
          <Col md="12">
            <FormGroup >
              <Label>Project Main Activity*</Label>
              <Input rows={2} className="form-control" placeholder="Describe what the Project specifically addresses" onChange={e => {
                dispatch(updateWizardData({ project_main_activity: e.target.value }))
              }} value={data.project_main_activity} type="textarea" invalid={showValidation && validate(data, 'project_main_activity')} />
              <ValidationIndicator dataKey='project_main_activity' />
            </FormGroup>
          </Col>
        </Col>
        <Col md="4">
          <Row>
            <Col md="12">
              <FormGroup>
                <Label>Project Sectoral Scope*</Label>
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  isSearchable={true}
                  isMulti={true}
                  options={sectoralScopes.map(item => ({ 'value': item.id, 'label': item.label }))}
                  placeholder="Select the category"
                  onChange={e => {
                    dispatch(updateWizardData({ projectSectoralScopes_ids: e.length ? e.map(item => item.value).join(",") : null }))
                  }}
                  value={selectedSectoralScopes.map(item => ({ 'value': item.id, 'label': item.label }))}
                />
                <ValidationIndicator dataKey='projectSectoralScopes_ids' />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label>Project Type*</Label>
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  isSearchable
                  isMulti
                  name="projectTypes"
                  options={projectTypes.map(item => ({ 'value': item.id, 'label': item.label }))}
                  placeholder="Select the type of Project"
                  onChange={e => {
                    let selected = e.map(item => item.value)
                    dispatch(updateWizardData({ project_type_ids: selected.length ? selected.join(",") : null }))
                  }}
                  value={
                    selectedProjectTypeOptions.map(item => ({ 'value': item.id, 'label': item.label }))
                  }
                />
                {showValidation && !selectedProjectTypeOptions.length && <ValidationLabel />}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label>Crediting Period Start Date</Label>
                <Input
                  type="date"
                  className="form-input"
                  placeholder="select date"
                  value={moment(data.crediting_project_startdate).format('YYYY-MM-DD')}
                  max={currentDate}
                  onChange={e => {
                    dispatch(updateWizardData({ crediting_project_startdate: e.target.value }))
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label>Crediting Period End Date</Label>
                <Input
                  type="date"
                  className="form-input"
                  placeholder="select date"
                  value={moment(data.crediting_project_enddate).format('YYYY-MM-DD')}
                  onChange={e => {
                    dispatch(updateWizardData({ crediting_project_enddate: e.target.value }))
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
