import { validate } from "components/Common/ValidationIndicator";
import ValidationLabel from "components/Common/ValidationLabel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { updateWizardData } from "rtk-store/slices/wizardSlice";

const emptyItem = {
  "country": null,
  "state_province": "",
  "region_location": null,
  "latitude": "",
  "longitude": "",
}

export default function CoordinatesTable() {
  const dropdownValues = useSelector(state => state.wizard.dropdownData)
  const wizardData = useSelector(state => state.wizard.wizardData)
  const [data, setData] = useState([emptyItem])
  const handleAddContact = () => {
    setData([...data, emptyItem])
  }

  const handleDelete = (index) => {
    let newData = [...data]
    newData.splice(index, 1)
    setData(newData)
  }

  const handleClearData = () => {
    setData([emptyItem])
  }

  const handleItemDetailChange = (index, key, value) => {
    let item = { ...data[index] }
    item[key] = value
    const newData = [...data]
    newData[index] = item
    setData(newData)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateWizardData({ 'coordinates': data }))
  }, [data])

  console.log(data);

  useEffect(() => {
    setData(wizardData.coordinates?.length ? wizardData.coordinates : [{
      "country": null,
      "state_province": "",
      "region_location": null,
      "latitude": "",
      "longitude": "",
    }])
  }, [wizardData.coordinates])

  useEffect(() => {
    if (!data.length) {
      dispatch(updateWizardData({
        'coordinates': [{
          "country": null,
          "state_province": "",
          "region_location": null,
          "latitude": "",
          "longitude": "",
        }]
      }))
    }
  }, [])
  const showValidation = useSelector(state => state.wizard.showValidation)
  return (
    <React.Fragment>
      <Table style={{ borderCollapse: "collapse" }} className="table-sm">
        <thead>
          <tr style={{ borderBottom: "1px solid #ccc" }}>
            <th className="fw-normal text-center">#</th>
            <th className="fw-normal">Country*</th>
            <th className="fw-normal">State or Province</th>
            <th className="fw-normal">Region/Location*</th>
            <th className="fw-normal">Latitude (° N/S) *</th>
            <th className="fw-normal">Longitude (° W/E) *</th>
            <th className="fw-normal text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => {
            console.log(data.length);
            return <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {index + 1}
              </td>
              <td style={{ verticalAlign: 'top' }} width='20%'>
                <Select
                  isSearchable
                  isClearable
                  name={`country`}
                  value={dropdownValues['host_coun'].find(item => item.id == dataItem.country) ?? null}
                  options={dropdownValues['host_coun']}
                  placeholder="Select the Country"
                  onChange={e => {
                    handleItemDetailChange(index, 'country', e?.id)
                  }}
                />
                {showValidation && validate(dataItem, 'country') && <ValidationLabel />}
              </td>
              <td style={{ verticalAlign: 'top' }} width='25%'>
                <Input
                  type="text"
                  placeholder="Enter the State/Province"
                  value={dataItem.state_province ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'state_province', e.target.value)
                  }}
                />
              </td>
              <td style={{ verticalAlign: 'top' }} width='20%'>
                <Select
                  isSearchable={true}
                  options={dropdownValues['regi']}
                  placeholder="Select Region"
                  value={dropdownValues['regi'].find(item => item.id == dataItem.region_location) ?? null}
                  onChange={e => {
                    handleItemDetailChange(index, 'region_location', e.id)
                  }}
                />
                {showValidation && validate(dataItem, 'region_location') && <ValidationLabel />}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  invalid={showValidation && validate(dataItem, 'latitude')}
                  type="number"
                  step="0.0001"
                  placeholder="Enter Value"
                  value={dataItem.latitude ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'latitude', e.target.value)
                  }}
                />
                {showValidation && validate(dataItem, 'latitude') && <ValidationLabel />}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  invalid={showValidation && validate(dataItem, 'longitude')}
                  type="number"
                  step="0.0001"
                  placeholder="Enter Value"
                  value={dataItem.longitude ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'longitude', e.target.value)
                  }}
                />
                {showValidation && validate(dataItem, 'longitude') && <ValidationLabel />}
              </td>
              <td style={{ verticalAlign: 'top', textAlign: 'center' }} width='15%'>
                {data.length === 1 ? (
                  <Button onClick={handleClearData} color="white" size="sm">
                    <i className="dripicons-clockwise text-danger fs-4"></i>
                  </Button>
                ) : (
                  <Button onClick={() => {
                    handleDelete(index)
                  }} color="white" size="sm">
                    <i className="bx bx-trash text-danger fs-4"></i>
                  </Button>
                )}
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      <Row>
        <Col className="text-end">
          <Button color="primary" style={{ backgroundColor: "#8796C7", borderColor: "#8796C7" }} onClick={handleAddContact}>
            Add New
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}
