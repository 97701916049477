/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api.js';

export const saveDraft = createAsyncThunk(
  '/draft/saveDraft',
  async (payload) => {
    try {
      const response = await api.post(
        '/drafts/saveProject',
        payload,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      const data = response.data
      if (data['error']) {
        throw new Error(data['payload'])
      }
      return data['payload']
    } catch (error) {
      throw error
    }
  }
);