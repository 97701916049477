/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const deleteDraftProjectByID = createAsyncThunk('/drafts/deleteDraftProjectById/', async ({ id }) => {
    try {
        let baseURL = "/drafts/deleteDraftProjectById/"
        let url = baseURL + id
        const res = await api.delete(url)
        let data = res.data
        if (data['error']) {
            throw new Error(data['payload'])
        }
        data = data['payload']
        if (data && data.length) {
            data = data[0]
        }
        return { ...data, id: parseInt(id) }
    }
    catch (error) {
        throw error
    }
})

export const deletePublishedProjectByID = createAsyncThunk('/publish/deletePublishProjectById/', async ({ id }) => {
    try {
        let baseURL = "/publish/deletePublishProjectById/"
        let url = baseURL + id
        const res = await api.post(url)
        let data = res.data
        if (data['error']) {
            throw new Error(data['payload'])
        }
        data = data['payload']
        if (data && data.length) {
            data = data[0]
        }
        return { ...data, id: parseInt(id) }
    }
    catch (error) {
        throw error
    }
})