import { useSelector } from "react-redux"
import React from "react"
import ValidationLabel from "./ValidationLabel"
export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export function isValidPhoneNumber(phoneNumber) {
  const phoneRegex = /^\+?\d{10,}$/
  if (phoneNumber === null) {
    return true
  }
  if (phoneNumber === "" || phoneNumber === undefined) {
    return true
  }
  return phoneRegex.test(phoneNumber)
}

export function validate(data, dataKey, criteria = "text") {
  var invalid = false
  if (data[dataKey] === null) {
    invalid = true
  } else if (data[dataKey] === "") {
    invalid = true
  } else if (!data[dataKey]) {
    invalid = true
  }
  if (criteria === "email") {
    invalid = !isValidEmail(data[dataKey])
  }
  return invalid
}

export default function ValidationIndicator({ dataKey, criteria = "text" }) {
  const data = useSelector(state => state.wizard.wizardData)
  const show = useSelector(state => state.wizard.showValidation)
  var invalid = validate(data, dataKey, criteria)
  if (invalid && show) {
    return <ValidationLabel />
  }
}
