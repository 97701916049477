/* eslint-disable no-undef */
import api from "./api";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const getPublishedProjectByID = createAsyncThunk('/publish/getProjectByID', async ({ id, step }) => {
    try {
        let baseURL = "/publish/getRegistryDetails/"
        if (step == 1) {
            baseURL = "/publish/getProponentDetails/"
        }
        else if (step == 2) {
            baseURL = "/publish/getListingDetails/"
        }
        else if (step == 3) {
            baseURL = "/publish/getProjectDetails/"
        }
        else if (step == 4) {
            baseURL = "/publish/getCoordinateDetails/"
        }
        else if (step == 5) {
            baseURL = "/publish/getApprovalCertificationDetails/"
        }
        else if (step == 6) {
            baseURL = "/publish/getCreditingDetails/"
        }
        else if (step == 7) {
            baseURL = "/publish/getResourceDetails/"
        }
        let url = baseURL + id
        const res = await api.get(url)
        let data = res.data
        if (data['error']) {
            throw new Error(data['payload'])
        }
        data = data['payload']
        if (data && data.length) {
            data = data[0]
        }
        return { ...data, id: parseInt(id) }
    }
    catch (error) {
        console.log(error);
        throw error
    }
})

export const getDraftProjectByID = createAsyncThunk('/drafts/getProjectByID', async ({ id, step }) => {
    try {
        let baseURL = "/drafts/getRegistryDetails/"
        if (step == 1) {
            baseURL = "/drafts/getProponentDetails/"
        }
        else if (step == 2) {
            baseURL = "/drafts/getListingDetails/"
        }
        else if (step == 3) {
            baseURL = "/drafts/getProjectDetails/"
        }
        else if (step == 4) {
            baseURL = "/drafts/getCoordinateDetails/"
        }
        else if (step == 5) {
            baseURL = "/drafts/getApprovalCertificationDetails/"
        }
        else if (step == 6) {
            baseURL = "/drafts/getCreditingDetails/"
        }
        else if (step == 7) {
            baseURL = "/drafts/getResourcesDetails/"
        }
        let url = baseURL + id
        const res = await api.get(url)
        let data = res.data
        if (data['error']) {
            throw new Error(data['payload'])
        }
        data = data['payload']
        if (data && data.length) {
            data = data[0]
        }
        return { ...data, id: parseInt(id) }
    }
    catch (error) {
        console.log(error)
        throw error
    }
})