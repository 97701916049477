import React from "react"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import ReloadIcon from "../../../src/assets/images/oi_reload.svg"
import TwoLineItem from "../DetailsView/TwoLineItem"
import Apaexlinecolumn from "./apaexlinecolumn"
function CreditInformation() {
  const creditingInformation = useSelector(
    state => state.projectDetails.details.creditingPddDetails
  )
  const statusColors = {
    "REGISTERED": "#6FF491",
    "LISTED": "#B5EAD7",
    "UNDER DEVELOPMENT": "#FCE87E",
    "ON HOLD": "#FFC3A0",
    "REJECTED": "#FFADAD",
    "INACTIVE": "#DEDEDE",
  }

  const Status =
    creditingInformation && creditingInformation.status
      ? creditingInformation.status.toUpperCase()
      : ""
  return (
    <React.Fragment>
      <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 18, paddingBottom: 18 }}>
        <Row
          className="d-flex align-items-center"
          style={{ backgroundColor: statusColors[Status], marginBottom: '45px', borderRadius: '30px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '30px', paddingRight: '30px' }}
        >
          <Col md={1} className="text-center text-md-start">
            <i className="bx bx-info-circle me-2" style={{ cursor: 'pointer', color: '#495057', fontSize: '29px' }} title='Stage of the project in the registration and crediting process' />
          </Col>
          <Col md={11} className="fw-bold text-center" style={{ fontSize: '16px' }}>
            STATUS: {Status}
          </Col>
        </Row>

        <Row style={{ marginBottom: '45px' }} className="d-flex align-items-top g-3">
          <Col md={12} xl={5} className="text-center">
            <div className="d-flex justify-content-lg-start justify-content-center align-items-lg-start align-items-center h-100">
              <div style={{ aspectRatio: 1, maxWidth: '265px', maxHeight: '265px', borderRadius: '16px', color: 'white', background: '#8DD98E' }} className="text-center d-flex align-content-center flex-column justify-content-center h-100">
                <div>
                  <img src={ReloadIcon} alt="Reload Icon" />
                </div>
                <div className="fw-bold text-white" style={{ fontSize: '15px' }}>
                  Total Credits Issued
                </div>
                <div className="fw-bold mb-4" style={{ color: "#006080", fontWeight: 700, fontSize: '30px' }}>
                  {creditingInformation &&
                    creditingInformation.totalCreditGenerated
                    ? new Intl.NumberFormat("en-US").format(
                      creditingInformation.totalCreditGenerated
                    )
                    : "--"}
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} xl={7}>
            <Apaexlinecolumn dataColors='["#8DD98E","#015F81"]' />
          </Col>
        </Row>
        <Row className="g-3">
          <Col>
            <div className="d-flex flex-column h-100" style={{ borderRadius: '15px', boxShadow: '0px 3px 19px -5px rgba(0, 0, 0, 0.24)', background: '#fff', padding: '16px' }}>
              <TwoLineItem title="Credits Retired"
                marginBottom="0rem"
                tooltip="Credits which have been purchased and removed from circulation to reduce the carbon footprint of an end user"
                body={<div className="fw-bold" style={{
                  fontSize: '48px', color: '#495057'
                }}>{creditingInformation &&
                  creditingInformation.totalCreditRetired
                  ? new Intl.NumberFormat("en-US").format(
                    creditingInformation.totalCreditRetired
                  )
                  : "--"
                  }</div>} />
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column h-100" style={{ borderRadius: '15px', boxShadow: '0px 3px 19px -5px rgba(0, 0, 0, 0.24)', background: '#fff', padding: '16px' }}>
              <TwoLineItem
                title="Credits Available"
                body={
                  <div style={{ fontWeight: 'bold', color: '#495057', fontSize: '16px' }}>
                    {creditingInformation?.totalCreditsAvailable
                      ? new Intl.NumberFormat("en-US").format(
                        creditingInformation.totalCreditsAvailable
                      )
                      : "--"}
                  </div>
                }
                marginBottom="0.25rem"
                tooltip="Credits issued by the project but not yet retired."
              />
              <TwoLineItem
                marginBottom="0rem"
                title="Buffer Credits"
                body={
                  <div style={{ fontWeight: 'bold', color: '#495057', fontSize: '16px' }}>
                    {creditingInformation?.totalBufferCredits
                      ? new Intl.NumberFormat("en-US").format(
                        creditingInformation.totalBufferCredits
                      )
                      : "--"}
                  </div>
                }
                tooltip="Credits generated by the project that have been set aside in a buffer pool. These credits can be cancelled in the event of a reversal."
              />
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column" style={{ borderRadius: '15px', boxShadow: '0px 3px 19px -5px rgba(0, 0, 0, 0.24)', background: '#fff', padding: '16px' }}>
              <TwoLineItem
                title="Emission Reductions"
                body={
                  <div>
                    <Row className="d-flex align-items-center" style={{ marginBottom: '0.25rem' }}>
                      <Col>
                        <span className="fw-bold" style={{ fontSize: '14px', color: '#959595' }}>Total</span>
                      </Col>
                      <Col className="fw-bold" style={{ fontSize: '22px', color: '#495057' }}>
                      {creditingInformation &&
                          creditingInformation.total_emission_reduction_pdd_cumalative
                          ? new Intl.NumberFormat("en-US").format(
                            creditingInformation.total_emission_reduction_pdd_cumalative
                          )
                          : "--"}
                       
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col>
                        <span className="fw-bold" style={{ fontSize: '14px', color: '#959595' }}>Annual</span>
                      </Col>
                      <Col className="fw-bold" style={{ fontSize: '22px', color: '#495057' }}>
                        {creditingInformation &&
                          creditingInformation.est_annu_emission_red
                          ? new Intl.NumberFormat("en-US").format(
                            creditingInformation.est_annu_emission_red
                          )
                          : "--"}
                      </Col>
                    </Row>
                  </div>
                }
                tooltip="The measurable reduction of greenhouse gas emissions generated by project activities."
              />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}

export default CreditInformation
