import React from "react"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import CardSectionTitle from "../DetailsView/CardSectionTitle"
import TwoLineItem from '../DetailsView/TwoLineItem'


function ProjectLinks() {
  const projectLink = useSelector(state => state.projectDetails.details.projectLink);

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle title="Project Links" icon={<img src="/icons/project-links.svg" />} />
        <Row>
          <Col>
            <TwoLineItem title="Website" body=
              {projectLink && projectLink.project_source_link ? (
                <>
                  <a
                    href={projectLink.project_source_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#006080' }}
                  >
                    <img className="me-2" src="/icons/link.svg" />
                    {projectLink.project_source_link}
                  </a>
                </>
              ) : (
                "--"
              )} />
          </Col>
          <Col>
            <TwoLineItem title="Source Link" body=
              {projectLink && projectLink.project_developer_website ? (
                <>
                  <a
                    href={projectLink.project_developer_website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#006080' }}
                  >
                    <img className="me-2" src="/icons/link.svg" />
                    {projectLink.project_developer_website}
                  </a>
                </>
              ) : (
                "--"
              )} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ProjectLinks;
