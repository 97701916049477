/* eslint-disable */

import Breadcrumbs from "components/Common/Breadcrumb"
import { getDropdownDataApi } from "components/core/services/getDropdownDataApi"
import { getRetirementByProjectType } from "components/core/services/getRetirementByProjectTypeApi"
import _ from "lodash"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap"
import { DateRangePicker, SelectPicker, Stack, Whisper } from "rsuite"
import { setProjectFilters } from "rtk-store/slices/retirementByProjectTypeSlice"
import ClusteredChart from "./clusteredChart"
import LineChart from "./lineChart"
import StackedChart from "./stackedChart"
import YearRangePopover from "./yearRangePicker"

const BY_RETIREMENT_DATE = 1,
  BY_VINTAGE_YEAR = 2
const STACKED_BAR_CHART = 3,
  GROUPED_BAR_CHART = 4,
  LINE_CHART = 5
const chartOptions = [
  {
    label: "Stacked Chart",
    value: STACKED_BAR_CHART,
  },
  {
    label: "Clustered Chart",
    value: GROUPED_BAR_CHART,
  },
  {
    label: "Line Chart",
    value: LINE_CHART,
  },
]
const dateFilters = [
  {
    label: "By Retirement Date",
    value: 1,
  },
  {
    label: "By Vintage Year",
    value: 2,
  },
]

const YearIndicator = ({ start, end, onClick, onClear, disabled = false }) => {
  let textColor = "#1675e0"
  if (start == null || start == "") {
    start = "yyyy"
    textColor = "#8e8e93"
  }
  if (end == null || end == "") {
    end = "yyyy"
    textColor = "#8e8e93"
  }
  return (
    <div
      style={{
        backgroundColor: disabled ? "#fafafa" : "#ffffff",
        border: "1px solid #e5e5ea",
        opacity: disabled ? 0.5 : 1,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: 8,
        overflow: "hidden",
        borderRadius: 6,
        maxHeight: 36,
        cursor: "pointer",
      }}
      className="hoverable-form-field"
    >
      <Stack spacing={8}>
        <Stack.Item grow={1} style={{ overflow: "hidden" }}>
          <div
            style={{
              marginRight: "1rem",
            }}
            onClick={e => {
              onClick()
              e.preventDefault()
            }}
          >
            <Stack spacing={8}>
              <span style={{ fontWeight: 500, fontSize: 13 }}>From:</span>
              <Stack.Item grow={1}>
                <div
                  className="text-center"
                  style={{ color: textColor, fontWeight: 500 }}
                >
                  {start}
                </div>
              </Stack.Item>
              <span style={{ fontWeight: 500, fontSize: 13 }}>To:</span>
              <Stack.Item grow={1}>
                <div
                  className="text-center"
                  style={{ color: textColor, fontWeight: 500 }}
                >
                  {end}
                </div>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
        <Stack.Item>
          <div className="text-end bg-white px-2">
            <span
              role="button"
              tabIndex={-1}
              className="rs-picker-toggle-clean p-0 text-end m-0"
              title="Clear"
              aria-label="Clear"
              onClick={e => {
                e.stopPropagation()
                onClear()
              }}
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                aria-hidden="true"
                style={{ marginRight: -5 }}
                focusable="false"
                className="rs-icon"
                aria-label="close"
                data-category="application"
              >
                <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z" />
              </svg>
            </span>
          </div>
        </Stack.Item>
      </Stack>
    </div>
  )
}

export default function RetirementByProjectType(props) {
  document.title = "Analytics | CarboneSpace"
  const dispatch = useDispatch()
  const [selectedChart, setSelectedChart] = useState(STACKED_BAR_CHART)
  const [selectedDateFilter, setSelectedDateFilter] =
    useState(BY_RETIREMENT_DATE)
  const [selectedRegistry, setSelectedRegistry] = useState(null)
  const currentDate = new Date()
  const twoYearsAgo = new Date()
  twoYearsAgo.setFullYear(currentDate.getFullYear() - 2)
  const formatDate = date => date.toISOString().split("T")[0]
  const [years, setYears] = useState({
    start: formatDate(twoYearsAgo),
    end: formatDate(currentDate),
  })
  const dropdownData = useSelector(state => state.wizard.dropdownData)
  const chartData = useSelector(state => state.retirements.chartData)
  const loading = useSelector(state => state.retirements.loading)
  const error = useSelector(state => state.retirements.error)
  const initialLoadDone = useRef(false)
  let formattedStartDate = ""
  let formattedEndDate = ""

  useEffect(() => {
    if (_.isEmpty(dropdownData)) {
      dispatch(getDropdownDataApi())
    }
  }, [dropdownData])

  useEffect(() => {
    const currentDate = new Date()
    const twoYearsAgo = new Date()
    twoYearsAgo.setFullYear(currentDate.getFullYear() - 2)
    const formatDate = date => date.toISOString().split("T")[0]

    if (selectedDateFilter === 2) {
      formattedStartDate = formatDate(twoYearsAgo).split("-")[0]
      formattedEndDate = formatDate(currentDate).split("-")[0]
    } else {
      formattedStartDate = formatDate(twoYearsAgo)
      formattedEndDate = formatDate(currentDate)
    }
    setYears({ start: formattedStartDate, end: formattedEndDate })
  }, [selectedDateFilter])

  useEffect(() => {
    if (!initialLoadDone.current) {
      initialLoadDone.current = true // Prevent further calls on initial load
      return // Exit the effect early on the first render
    }
    const fetchData = async () => {
      dispatch(setProjectFilters())
      dispatch(
        getRetirementByProjectType({
          datetype: selectedDateFilter,
          startDate: years?.start == null ? "" : years?.start,
          endDate: years?.end == null ? "" : years?.end,
          registryType: selectedRegistry,
        })
      )
    }

    fetchData()
  }, [years, selectedRegistry])

  // Extract registry data
  const registryDropdownData = _.get(dropdownData, "regi_str", []).map(item => {
    return {
      label: item.label,
      value: item.id,
    }
  })
  // Year range picker ref
  const yearRangePicker = useRef()

  const handleReset = () => {
    setYears({ start: null, end: null })
  }

  const handleDateRange = value => {
    if (value != null && value.length) {
      const startDate = value[0]
      const endDate = value[1]
      if (startDate != null && endDate != null) {
        formattedStartDate = `${startDate.getFullYear()}-${String(
          startDate.getMonth() + 1
        ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`
        formattedEndDate = `${endDate.getFullYear()}-${String(
          endDate.getMonth() + 1
        ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`
        setYears({
          start: formattedStartDate,
          end: formattedEndDate,
        })
      }
    }
  }

  const [isLog, setIsLog] = useState(false)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Breadcrumb */}
          <Row>
            <Col>
              <Breadcrumbs
                title={
                  <span
                    style={{
                      color: "#495057",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontStyle: "normal",
                      ".breadcrumb-item.active": {
                        color: "#6c757d",
                        fontWeight: "600",
                        fontSize: "16px",
                        fontStyle: "normal",
                      },
                    }}
                  >
                    Analytics
                  </span>
                }
                breadcrumbItems={[
                  {
                    link: "#",
                    title: "Analytics",
                  },
                  {
                    link: "/retirementByProjectType",
                    title: "Retirement by Project Type",
                  },
                ]}
              />
            </Col>
          </Row>
          {/* Chart container */}
          <div className="bg-white graph-box">
            <Row className="mb-4">
              <Col md={12} sm={12} lg={4}>
                <div className="fs-3 fw-bold">Retirement by Project Type</div>
              </Col>
              <Col md={12} sm={12} lg={8}>
                <Row>
                  <Col md={4}>
                    <SelectPicker
                      disabled={loading}
                      placeholder="Select"
                      label="Registry"
                      data={registryDropdownData}
                      value={selectedRegistry}
                      block
                      onChange={value => {
                        setSelectedRegistry(value)
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <SelectPicker
                      label="Date filter"
                      disabled={loading}
                      placeholder="Select"
                      cleanable={false}
                      data={dateFilters}
                      value={selectedDateFilter}
                      block
                      style={{ zIndex: 1000 }}
                      onChange={value => {
                        setSelectedDateFilter(value)
                        if (value === null) {
                          setYears({ start: null, end: null })
                        }
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    {selectedDateFilter === BY_VINTAGE_YEAR && (
                      <Whisper
                        ref={yearRangePicker}
                        trigger="click"
                        placement="bottomEnd"
                        speaker={
                          <YearRangePopover
                            onSubmit={({ start, end }) => {
                              setYears({ start, end })
                              yearRangePicker.current?.close()
                            }}
                            defaultValue={years}
                          />
                        }
                      >
                        <div>
                          <YearIndicator
                            disabled={loading}
                            start={years.start}
                            end={years.end}
                            onClear={() => {
                              handleReset()
                            }}
                            onClick={() => {}}
                          />
                        </div>
                      </Whisper>
                    )}
                    {selectedDateFilter === BY_RETIREMENT_DATE && (
                      <DateRangePicker
                        style={{
                          width: "100%",
                        }}
                        value={
                          years.start != null && years.end != null
                            ? [new Date(years.start), new Date(years.end)]
                            : [undefined]
                        }
                        disabled={loading}
                        placement="auto"
                        onOk={value => {
                          handleDateRange(value)
                        }}
                        onClean={() => setYears({ start: null, end: null })}
                        format="MM-dd-yyyy"
                        onChange={value => {
                          handleDateRange(value)
                        }}
                        renderValue={value => {
                          return (
                            <span>
                              {`${moment(value[0]).format("MM-DD-YYYY")} `}
                              <span style={{ color: "#495057" }}>
                                &nbsp; to&nbsp;
                              </span>
                              {` ${moment(value[1]).format("MM-DD-YYYY")}`}
                            </span>
                          )
                        }}
                        placeholder="Select Date Range"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Chart type selector */}
            <div className="analytics-nev">
              <Row>
                <Col>
                  <Nav pills>
                    {chartOptions.map(option => {
                      let cls =
                        option.value === selectedChart ? "chart_bold" : ""
                      return (
                        <NavItem
                          key={option.value}
                          onClick={() => {
                            setSelectedChart(option.value)
                          }}
                          style={{
                            borderRadius: 5,
                            background:
                              option.value === selectedChart
                                ? "#F3F3F8"
                                : "white",
                          }}
                        >
                          <NavLink className={cls}>{option.label}</NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </Col>

                {/* uncomment to check log vlaues */}
                {/* <Col className="text-end">
                  <span className="me-2">Log</span>
                  <Toggle
                    checked={isLog}
                    onChange={value => {
                      setIsLog(value)
                    }}
                  />
                </Col> */}
              </Row>
            </div>

            {/* Render selector */}
            <Row className="graph-container">
              <Col>
                {selectedChart === STACKED_BAR_CHART && (
                  <StackedChart
                    chartData={chartData}
                    loading={loading}
                    error={error}
                    dateFilter={selectedDateFilter}
                    startDate={years?.start}
                    endDate={years?.end}
                    isLog={isLog}
                  />
                )}
                {selectedChart === GROUPED_BAR_CHART && (
                  <ClusteredChart
                    chartData={chartData}
                    loading={loading}
                    error={error}
                    dateFilter={selectedDateFilter}
                    startDate={years?.start}
                    endDate={years?.end}
                    isLog={isLog}
                  />
                )}
                {selectedChart === LINE_CHART && (
                  <LineChart
                    chartData={chartData}
                    loading={loading}
                    error={error}
                    dateFilter={selectedDateFilter}
                    startDate={years?.start}
                    endDate={years?.end}
                    isLog={isLog}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
