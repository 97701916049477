import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"
import moment from "moment"
import ValidationIndicator from "components/Common/ValidationIndicator"
import jwtDecode from "jwt-decode"; 


export default function RegistryProfileDetails() {
  const dispatch = useDispatch()

  const dropdownValues = useSelector(state => state.wizard.dropdownData)
  const data = useSelector(state => state.wizard.wizardData)
  const currentDate = moment().format("YYYY-MM-DD")
  
  const token = localStorage.getItem("authToken");
  let userName = "";
  
  if (token) {
    const decodedToken = jwtDecode(token);
    userName = decodedToken.username; 
    const userId = decodedToken.user_id; // Replace 'user_id' with the actual field name in your JWT
    if (!data["prj_creator_name"]) {
      dispatch(updateWizardData({ prj_creator_name: userName , prj_creator_id:userId }));
    }    
  }

  

  return (
    data && (
      <React.Fragment>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>Registry*</Label>
              <Select
                val
                className="select-input"
                classNamePrefix="select"
                isSearchable={true}
                options={dropdownValues["regi_str"]}
                onChange={e => {
                  dispatch(updateWizardData({ registry: e.id }))
                }}
                placeholder="Select the Registry"
                value={dropdownValues["regi_str"]?.find(
                  item => item.id == data.registry
                )}
              />
              <ValidationIndicator dataKey="registry" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Registry ID</Label>
              <Input
                className="form-input"
                type="text"
                placeholder="Enter Unique Project ID"
                onChange={e => {
                  dispatch(updateWizardData({ registry_id: e.target.value }))
                }}
                value={data.registry_id ?? ""}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Registered Date</Label>
              <Input
                className="form-input"
                type="date"
                placeholder="Select Date"
                onChange={e => {
                  const selectedDate = e.target.value
                  if (selectedDate <= currentDate) {
                    dispatch(updateWizardData({ registry_date: selectedDate }))
                  } else {
                    console.log("Invalid date selected.")
                  }
                }}
                value={
                  data.registry_date
                    ? moment(data.registry_date).format("YYYY-MM-DD")
                    : null
                }
                max={currentDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "150px" }}>
          <Col md="8">
            <FormGroup>
              <Label>Source Link</Label>
              <Input
                type="url"
                className="form-input"
                placeholder="Add Project Registry Link"
                onChange={e => {
                  dispatch(
                    updateWizardData({ project_source_link: e.target.value })
                  )
                }}
                value={data["project_source_link"] ?? ""}
                defaultValue=""
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Creator Name*</Label>
              <Input
                className="form-input"
                type="text"
                placeholder="Enter your name"
                readOnly
                // onChange={e => {
                //   dispatch(
                //     updateWizardData({ prj_creator_name: e.target.value })
                //   )
                // }}
                value={data["prj_creator_name"] ?? userName}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    )
  )
}
