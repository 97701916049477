/* eslint-disable */
import moment from "moment"
import { forwardRef, useEffect, useState } from "react"
import { Label } from "reactstrap"
import { Button, Input, Popover, Stack } from "rsuite"

export const YearRangePicker = ({ onSubmit, defaultValue }) => {
  const [start, setStart] = useState(undefined)
  const [end, setEnd] = useState(undefined)
  const handleSubmit = () => {
    // onSubmit({ start, end })
    // console.log(start,end);
    if (start > end) {
      onSubmit({ start: end, end: start })
    } else {
      onSubmit({ start, end })
    }
  }
  useEffect(() => {
    if (defaultValue) {
      setStart(defaultValue.start)
      setEnd(defaultValue.end)
    }
  }, [defaultValue])
  return (
    <div className="p-2 rounded bg-white">
      <Stack spacing={16} className="mb-2">
        <Stack.Item>
          <Label>Start year</Label>
          <Input
            value={start}
            onChange={value => {
              setStart(value)
            }}
            type="number"
          />
        </Stack.Item>
        <Stack.Item>
          <Label>End year</Label>
          <Input
            value={end}
            onChange={value => {
              setEnd(value)
            }}
            type="number"
          />
        </Stack.Item>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button appearance="primary" color="blue" onClick={handleSubmit}>
          OK
        </Button>
      </div>
    </div>
  )
}

const YearRangePopover = forwardRef((props, ref) => {
  return (
    <Popover ref={ref} {...props} title="Select year range">
      <YearRangePicker
        onSubmit={props.onSubmit}
        defaultValue={props.defaultValue}
      />
    </Popover>
  )
})

export default YearRangePopover
