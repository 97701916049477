/* eslint-disable no-unused-vars */
import { deleteDraftProjectByID } from "components/core/services/deleteDraftProjectByIDApi"
import { getDraftProjects } from "components/core/services/getDraftProjectsApi"
import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table
} from "reactstrap"
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import { useTranslation } from 'react-i18next';


export default function RejectedListings() {
  document.title = "Draft Projects | CarboneSpace"
  const dispatch = useDispatch()
  const data = useSelector(state => state.drafts.projects) ?? []
  const loading = useSelector(state => state.drafts.loading)
  const error = useSelector(state => state.drafts.error)
  const [activePage, setActivePage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { t } = useTranslation();



  // Get the current items to display based on the active page
  const currentItems = data && data.draftProjects ? data.draftProjects : [];
  const numOfItem = data.totalPages * 10;
  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  // Add these state variables at the top of your component
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(currentItems);

  useEffect(() => {
    dispatch(getDraftProjects({ page: activePage, pageSize: pageSize }));
  }, [activePage, pageSize, dispatch]);

  // delete the item with the given carbonSpaceId and Update the 'data' state by removing the item
  const deleteItem = async (itemId) => {
    try {
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#83C7D4',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      });

      if (confirmed.isConfirmed) {
        await dispatch(deleteDraftProjectByID({ id: itemId, step: 0 }));
        // Dispatch the action to fetch the data again
        dispatch(getDraftProjects({ page: activePage, pageSize: pageSize }));
        Swal.fire({ title: 'Deleted!', text: 'The item has been deleted.', icon: 'success', toast: true, timer: 1500, showCloseButton: false, position: 'top-right' });
      }
    } catch (error) {
      console.log(error); // Handle the error accordingly
    }
  };


  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);

    // Filter the currentItems based on the search term
    const filteredItems = currentItems.filter(item => {
      return (
        (item.draft_id && item.draft_id.toLowerCase().includes(searchText)) ||
        (item.registry && item.registry.toLowerCase().includes(searchText)) ||
        (item.project_title && item.project_title.toLowerCase().includes(searchText)) ||
        ((item.sectoral_scopes && Array.isArray(item.sectoral_scopes)) && item.sectoral_scopes.filter(scope => scope !== null && scope.toLowerCase().includes(searchText)).length > 0) ||
        (item.draft_locations !== null && item.draft_locations.toString().toLowerCase().includes(searchText)) ||
        (item.prj_creator_name && item.prj_creator_name.toLowerCase().includes(searchText))
      );
    });

    setFilteredItems(filteredItems);
  };


  useEffect(() => {
    // Filter currentItems based on the search term and set filteredItems state
    const filteredItems = currentItems.filter(item => {
      return (
        (item.draft_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.registry?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.project_title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.sectoral_scopes?.join(', ')?.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.draft_locations !== null && item.draft_locations?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
          item.prj_creator_name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    });

    setFilteredItems(filteredItems);
  }, [searchTerm, currentItems]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="fs-5 fw-bold mb-3">REJECTED LISTINGS</div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col md="3">
              <form className="app-search d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Search") + "..."}
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Col>
          </Row>
          {error ? (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error}`}
                </div>
              </Col>
            </Row>
          ) : null}
          <Modal isOpen={loading} centered>
            <ModalBody className="text-center">
              <RotatingLines />
              <p className="mt-2">Loading...</p>
            </ModalBody>
          </Modal>
          {!loading && (
            <div style={{ height: '60vh', overflow: "auto" }}>
              {currentItems.length ?
                <Table className="table table-striped mb-0">
                  <thead className="sticky-th">
                    <tr>
                      <th>Draft ID</th>
                      <th>CH ID</th>
                      <th>Registry</th>
                      <th>Project Title</th>
                      <th>Rejected Reason</th>
                      <th>Edit</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.draft_id}</td>
                          <td style={{ color: item.csp_id == null ? "#CACFC9" : "inherit" }}>
                            {item.csp_id || "Unpublish"}
                          </td>

                          <td>{item.registry}</td>
                          <td>{item.project_title ?? "N/A"}</td>
                          <td>{"Project Coordinates are Incorrect"}</td>
                          <td>
                            <Link
                              style={{ color: "#83C7D4" }}
                              to={"/edit/" + item.id}
                            >
                              <i className="bx bx-edit fs-3" id="edit-btn"></i>
                            </Link>
                          </td>
                          {/* <td>
                            <Link style={{ color: "#F46A6A" }} onClick={() => deleteItem(item.id)}>
                              <i
                                className="bx bx-trash fs-3"
                                id="prjdelete-btn"
                              ></i>
                            </Link>
                          </td> */}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                : <div className="alert text-muted text-center" style={{ background: '#fafafa' }}>No draft listings. Start by creating a listing…</div>}
            </div>
          )}
          {/* Pagination */}
          {currentItems.length && <div className="d-flex justify-content-end mb-5 mt-5">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageSize}
              totalItemsCount={numOfItem}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>}
        </Container>
      </div>
    </React.Fragment>
  )
}
