import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Input, Row, Table } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"

export default function ProjectPDD() {
  const wizardData = useSelector(state => state.wizard.wizardData)
  const [data, setData] = useState([
    {
      credit_year: null,
      pdd: null,
    },
  ])

  const handleAddContact = () => {
    setData([
      ...data,
      {
        credit_year: null,
        pdd: null,
      },
    ])
  }

  const handleDelete = index => {
    let newData = [...data]
    newData.splice(index, 1)
    setData(newData)
  }

  const handleClearData = () => {
    setData([
      {
        credit_year: null,
        pdd: null,
      },
    ])
  }

  const handleItemDetailChange = (index, key, value) => {
    let item = { ...data[index] }
    item[key] = value
    const newData = [...data]
    newData[index] = item
    setData(newData)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateWizardData({ projectPDDInformation: data }))
  }, [data])

  useEffect(() => {
    setData(
      wizardData.projectPDDInformation
    )
  }, [wizardData.projectPDDInformation])

  return (
    <Row>
      <Col>
        <Table style={{ borderCollapse: "collapse" }} className="table-sm">
          <thead>
            <tr style={{ borderBottom: "1px solid #ccc", fontSize: "0.rem" }}>
              <th className="fw-normal">#</th>
              <th className="fw-normal">Year</th>
              <th className="fw-normal">
                Estimated GHG Emission Reductions or Removals
              </th>
              <th className="fw-normal">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((dataItem, index) => {
              return (
                <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
                  <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Input
                      type="month"
                      placeholder="Month/Year"
                      value={
                        dataItem.credit_year
                          ? dataItem.credit_year.substring(0, 7)
                          : ""
                      }
                      onChange={e => {
                        handleItemDetailChange(
                          index,
                          "credit_year",
                          e.target.value
                        )
                      }}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Input
                      type="number"
                      placeholder="Enter the value"
                      value={dataItem.pdd ?? ""}
                      onChange={e => {
                        handleItemDetailChange(index, "pdd", e.target.value)
                      }}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {data.length > 1 ? (
                      <a
                        onClick={() => handleDelete(index)}
                        className="btn btn-link fs-3 text-danger"
                      >
                        <i className="bx bx-trash"></i>
                      </a>
                    ) : (
                      <a
                        onClick={handleClearData}
                        className="btn btn-link text-danger fs-3"
                        title="Clear your data"
                      >
                        <i className="dripicons-clockwise"></i>
                      </a>
                    )}{" "}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Row>
          <Col className="text-end">
            <Button
              color="primary"
              style={{ backgroundColor: "#8796C7", borderColor: "#8796C7" }}
              onClick={handleAddContact}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
