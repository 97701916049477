import { createSlice } from '@reduxjs/toolkit';
import { editPublishedProject } from 'components/core/services/editPublishedProjectApi';
import { getPublishedProjects } from 'components/core/services/getPublishedProjectsApi';

// Slice with the necessary actions and reducer
const publishedSlice = createSlice({
    name: 'published',
    initialState: {
        projects: [],
        loading: false,
        error: null,
        currentPage: 1,
    },
    reducers: {
    },
    extraReducers: {
        [getPublishedProjects.fulfilled]: (state, action) => {
            state.projects = action.payload
            state.loading = false
        },
        [getPublishedProjects.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getPublishedProjects.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get published projects (' + action.error.message + ')'
        },
        [editPublishedProject.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to edit the published project (' + action.error.message + ')'
        },
        [editPublishedProject.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [editPublishedProject.fulfilled]: (state) => {
            state.loading = false
        }
    },
});

export default publishedSlice.reducer;
