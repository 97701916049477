import CompanyModal from "components/CompanyManagement/CompanyModal"
import { postCreateUpdateCompany } from "components/core/services/postCompany"
import jwtDecode from "jwt-decode"
import React, { useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Swal from "sweetalert2"
import emptyLogo from "../../assets/images/emptyFolder.png"
import {
  companyDelete,
  getCompaniesList,
  toggleCompanyStatus,
} from "../../components/core/services/getCompanies"
import { toggleCompanyState } from "../../rtk-store/slices/companiesSlice"
function Companies() {
  document.title = "Companies | CarbonHub"
  //
  const data = useSelector(state => state.companies.companies)
  const error = useSelector(state => state.companies.error)
  const loading = useSelector(state => state.companies.loading)
  const [visibleData, setVisibleData] = React.useState(data)
  //
  const dispatch = useDispatch()
  //
  useEffect(() => {
    dispatch(getCompaniesList())
  }, [])
  const [companyModal, setCompanyModal] = React.useState(false)
  const [companyId, setCompanyId] = React.useState(null)
  const [search, setSearch] = React.useState("")
  const handleCompanyForm = async payload => {
    try {
      setCompanyModal(false)
      setCompanyId(undefined)
      const response = await dispatch(postCreateUpdateCompany(payload))
      if (response.payload?.error === false) {
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: companyId
            ? "Company updated successfully!"
            : "Company added successfully!",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        })
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${response.payload.payload.message}`,
        })
      }
      dispatch(getCompaniesList())
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
      })
    }
  }
  const handleToggleCompanyStatus = async (id, status) => {
    try {
      const newCompanyStatus = status === 1 ? 0 : 1
      const payload = {
        id: id,
        isActive: newCompanyStatus,
      }
      dispatch(toggleCompanyState(payload))
      const response = await dispatch(toggleCompanyStatus(id))
      await dispatch(getCompaniesList())
      if (response.payload.error === false) {
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: response.payload.payload.message,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
        })
      } else {
        throw new Error(response.payload ?? "Error!")
      }
      dispatch(getCompaniesList())
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
      })
    }
  }
  const deleteCompany = async id => {
    try {
      const response = await dispatch(companyDelete(id))
      if (response.payload.error === false) {
        Swal.fire({
          icon: "success",
          title: "Deleted",
          text: response.payload.payload.message,
          timer: 1500,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
        })
      } else {
        throw new Error(response.payload ?? "Error!")
      }
      dispatch(getCompaniesList())
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
      })
    }
  }
  useEffect(() => {
    if (search) {
      const filteredData = data.filter(item => {
        return (
          item.companyName.toLowerCase().includes(search.toLowerCase()) ||
          item.contactPerson.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(search.toLowerCase())
        )
      })
      setVisibleData(filteredData)
    } else {
      setVisibleData(data) // Reset to show all data when search is empty
    }
  }, [data, search])
  const token = localStorage.getItem("authToken")
  const decodedToken = jwtDecode(token)

  const disableAction = email => {
    if (decodedToken.email === email) {
      return true
    }
    return false
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <div className="fs-5 fw-bold mb-3">LIST OF COMPANIES</div>
            </Col>
          </Row>
          {error ? (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error}`}
                </div>
              </Col>
            </Row>
          ) : null}
          <Modal isOpen={loading} centered>
            <ModalBody className="text-center">
              <RotatingLines />
              <p className="mt-2">Loading...</p>
            </ModalBody>
          </Modal>
          <Row className="mb-4 mt-2">
            <Col className="d-none d-md-block" />
            <Col className="text-end">
            <div
                className="gap-3 d-flex align-item-center justify-content-end"
                style={{ height: "38px" }}
              >
                <div className="input-group" style={{
                  width: "247px",
                }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Search..."}
                    style={{
                      backgroundColor: "#ffffff",
                      borderTop: "1px solid #CED4DA",
                      borderBottom: "1px solid #CED4DA",
                      borderLeft: "1px solid #CED4DA",
                      borderRadius: 20,
                    }}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <i
                    className="bx bx-search-alt"
                    style={{
                      position: "absolute",
                      zIndex: 200,
                      right: 16,
                      top: 14,
                    }}
                  />
                </div>
                <Button
                  className="btn"
                  color="dark"
                  style={{
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    backgroundColor: "#7AA5BF",
                    border: "2px solid #7AA5BF",
                    borderRadius: 4,
                    minWidth: 150,
                  }}
                  onClick={() => {
                    setCompanyId(null)
                    setCompanyModal(true)
                  }}
                >
                  Add Company
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {!loading && (
                <div style={{ height: "60vh", overflow: "auto" }}>
                  {visibleData?.length ? (
                    <Table className="table table-striped mb-0">
                      <thead
                        className="sticky-th tbl-publish"
                        style={{ background: "white" }}
                      >
                        <tr>
                          <th>Active</th>
                          <th>Company Name</th>
                          <th>Contact Person</th>
                          <th>Company Contact Email</th>
                          <th>Phone Number</th>
                          <th>Added Date</th>
                          <th>Updated Date</th>
                          <th style={{ width: "48px" }}>Edit</th>
                          <th style={{ width: "48px" }}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visibleData
                          ?.filter(item => item["id"] !== 1)
                          .map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <div className="square-switch">
                                    <input
                                      style={{
                                        cursor: disableAction(item.email)
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      disabled={disableAction(item.email)}
                                      type="checkbox"
                                      id={`square-switch${item.id}`}
                                      className="switch switch-bool"
                                      defaultChecked={item.isActive}
                                      onClick={() => {
                                        handleToggleCompanyStatus(
                                          item.id,
                                          item.isActive
                                        ).catch()
                                      }}
                                    />
                                    <label
                                      style={{
                                        marginBottom: 0,
                                        cursor: disableAction(item.email)
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      htmlFor={`square-switch${item.id}`}
                                      data-on-label="Yes"
                                      data-off-label="No"
                                    />
                                  </div>
                                </td>
                                <td>{item.companyName ?? "-"}</td>
                                <td>{item.contactPerson ?? "-"}</td>
                                <td>{item.email ?? "-"}</td>
                                <td className="text-center">
                                  {item.phoneNumber ? item.phoneNumber : "-"}
                                </td>
                                <td>{item.added ?? "-"}</td>
                                <td>{item.updated ?? "-"}</td>
                                <td align="center">
                                  <Link
                                    disabled={!item.isActive}
                                    to="#"
                                    style={{ color: "#83C7D4",
                                    cursor: (!item.isActive)
                                    ? "not-allowed"
                                    : "pointer",
                                     }}
                                    onClick={() => {
                                      if (item.isActive) {
                                      setCompanyModal(true)
                                      setCompanyId(item.id)
                                      }
                                    }}
                                  >
                                    <i
                                      className="bx bx-edit fs-3"
                                      id="edit-btn"
                                    ></i>
                                  </Link>
                                </td>
                                <td align="center">
                                  <Link
                                    to="#"
                                    disabled={disableAction(item.email)}
                                    style={{
                                      color: "#F46A6A",
                                      cursor: disableAction(item.email)
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    onClick={() => {
                                      if (!disableAction(item.email)) {
                                        Swal.fire({
                                          title: "Delete?",
                                          text: "Deleting a company will permanently remove all users assigned to it. Are you sure you want to proceed?",
                                          icon: "error",
                                          showConfirmButton: true,
                                          confirmButtonText: "Yes",
                                          cancelButtonText: "No",
                                          confirmButtonColor: "#7AA5BF",
                                          cancelButtonColor: "#F46A6A",
                                          showCancelButton: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteCompany(item.id).catch()
                                          }
                                        })
                                      }
                                    }}
                                  >
                                    <i
                                      className="bx bx-trash fs-3"
                                      id="edit-btn"
                                    ></i>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{minHeight:'60vh'}}>
                      <div className="text-center">
                        <img
                          className="pt-4"
                          src={emptyLogo}
                          width="120px"
                          alt="Logo"
                        />
                        <p
                          className="text-center"
                          style={{
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#C4C4C4",
                          }}
                        >
                          {!search
                            ? "It seems like there are currently no records to display."
                            : "We couldn't find any results that match your search"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={companyModal}
            className="modal-history-popscreen"
          >
            <ModalHeader
              toggle={() => {
                setCompanyModal(false)
              }}
              style={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              {companyId ? "Edit Company" : "Add new Company"}
            </ModalHeader>
            <div className="modal-body page-content-rev">
              <CompanyModal
                companyId={companyId}
                closeModal={() => {
                  setCompanyModal(false)
                  setCompanyId(undefined)
                }}
                handleCompanyForm={payload => handleCompanyForm(payload)}
              />
            </div>
          </Modal>
        </Container>
      </div>
    </>
  )
}

export default Companies
