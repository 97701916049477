import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody, Card, Row, Col } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { loginUser } from "store/actions";

const FogotPassword = (props) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    reEnterPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const dispatch = useDispatch();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleFormChange = (event, value) => {
    if (event.target.name === "reEnterPassword") {
      if (value !== formData.password) {
        setPasswordsMatch(false);
      } else {
        setPasswordsMatch(true);
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = () => {
    // Handle form submission logic based on step here
    if (step === 1) {
      handleNextStep();
    } else if (step === 2) {
      dispatch(loginUser(formData, props.history));
    }
  };

  return (
    <React.Fragment>
      <MetaTags className="pt-5">
        <title>FogotPassword | CarboneSpace</title>
      </MetaTags>
      <div style={{ backgroundColor: "#F3F3F9", height: "100vh" }}>
        <Row style={{ position: "relative", height: "100%" }}>
          <Col lg={4} />
          <Col lg={4}>
            <Card className="overflow-hidden" style={{ marginTop: "22%", borderRadius: "10px" }}>
              <CardBody className="pt-0">
                <div className="p-2">
                  {step === 1 && (
                    <StepOne
                      formData={formData}
                      onChange={handleFormChange}
                      onNextStep={handleNextStep}
                    />
                  )}
                  {step === 2 && (
                    <StepTwo
                      formData={formData}
                      onChange={handleFormChange}
                      passwordsMatch={passwordsMatch}
                      onPrevStep={handlePrevStep}
                      onSubmit={handleSubmit}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
            <div className="mt-3 text-center">
              <p style={{ fontSize: "10px", marginBottom: "-100px" }}>
                Powered by Searchspace geo © {new Date().getFullYear()} |
                Privacy Policy | Cookie Policy
              </p>
            </div>
          </Col>
          <Col lg={4} />
        </Row>
      </div>
    </React.Fragment>
  );
};

FogotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FogotPassword);
