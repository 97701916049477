import React from "react"

function CheckIcon({ width, height, color = "#85C6AC" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7411 22.928C10.4579 22.9283 10.1774 22.8712 9.91573 22.7599C9.65408 22.6486 9.41644 22.4853 9.21646 22.2793L0.935732 13.7784C0.733623 13.5733 0.573005 13.3292 0.463116 13.0601C0.353228 12.791 0.296237 12.5023 0.295419 12.2106C0.294602 11.9188 0.349974 11.6297 0.458353 11.36C0.566732 11.0903 0.725978 10.8452 0.926935 10.6389C1.12789 10.4326 1.36659 10.2691 1.62932 10.1579C1.89204 10.0466 2.1736 9.98972 2.4578 9.99054C2.742 9.99137 3.02324 10.0499 3.28534 10.1627C3.54745 10.2755 3.78525 10.4403 3.98506 10.6478L10.7411 17.5835L26.8141 1.0831C27.2188 0.669391 27.7668 0.437394 28.3379 0.43802C28.909 0.438647 29.4566 0.671846 29.8604 1.08644C30.2642 1.50104 30.4913 2.06317 30.4919 2.64948C30.4925 3.23578 30.2664 3.79837 29.8634 4.21379L12.2658 22.2795C12.0658 22.4854 11.8282 22.6487 11.5665 22.76C11.3049 22.8713 11.0244 22.9284 10.7411 22.928Z"
        fill={color}
      />
    </svg>
  )
}
function WarningIcon({ width, height, color = "#DE6262" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.015 3.966a3 3 0 1 1 5.97 0l-1.164 19.05a1.83 1.83 0 0 1-3.642 0L.015 3.966ZM0 30.666a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
        fill={color}
      />
    </svg>
  )
}

function Step({ radius, status = "success", title = "Unnamed", active = false }) {
  let bgColor = "#FFFFFF"
  let icon = null, borderColor = "transparent"
  if (status === "error") {
    icon = <WarningIcon width={4} />
    borderColor = "#DE6262"
  }
  if (status === "success") {
    icon = <CheckIcon width={24} color={active ? 'white' : '#85C6AC'} />
  }
  if (active) {
    bgColor = "#85C6AC"
  }
  return <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', width: '96px', cursor: 'pointer' }}>
    <div style={{
      borderRadius: '50%',
      height: radius, width: radius,
      backgroundColor: bgColor,
      borderColor: borderColor,
      borderWidth: 4,
      zIndex: 1,
    }} className="shadow shadow-sm d-flex align-items-center text-center justify-content-center">
      {icon}
    </div >
    <div className="text-center mt-2" style={{ color: '#74788D', fontSize: 12, width: '96px' }}>{title}</div>
  </div>
}

export default function Stepper({ stepRadius = 48, steps = [], activeStep = 0, onClick }) {
  return <div className="d-flex justify-content-between align-items-start flex-row stepper">
    {[0, 1, 2, 3, 4, 5, 6, 7].map(index => {
      let item = steps[index];
      return (
        <React.Fragment key={index}>
          <div className="d-flex justify-content-center align-items-center" style={{ cursor: onClick ? 'pointer' : 'crosshair' }} onClick={() => {
            if (onClick) {
              onClick(index)
            }
          }}>
            <Step radius={stepRadius} status={item?.status} title={item?.title} active={activeStep === index} />
          </div>
          {index !== 7 && (
            <div className="col stroke" style={{ backgroundColor: "#CFCFCF" }}></div>
          )}
        </React.Fragment>
      );
    })}
  </div>
}

// export default function Stepper({ stepRadius = 48, steps = [], activeStep = 0, onClick }) {
//   return <div className="row g-0 stepper">
//       {[0, 1, 2, 3, 4, 5, 6, 7].map(index => {
//           let item = steps[index];
//           return (
//               <React.Fragment key={index}>
//                   <div className="col">
//                       <Step radius={stepRadius} status={item?.status} title={item?.title} active={activeStep === index} />
//                   </div>
//                   {index !== 7 && (
//                       <div className="col stroke" style={{ backgroundColor: "#CFCFCF" }}></div>
//                   )}
//               </React.Fragment>
//           );
//       })}
//   </div>
// }
