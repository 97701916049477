import { createSlice } from '@reduxjs/toolkit';
import { getProjectDetailsByID } from 'components/core/services/getProjectDetailsApi';

// Slice with the necessary actions and reducer
const projectDetailsSlice = createSlice({
    name: 'details',
    initialState: {
        details: [
        ],
        loading: false,
        error: null,
        currentPage: 1,
    },
    reducers: {
        // resetData: (state) => {
        //     state = {
        //         details: [],
        //         loading: false,
        //         error: null,
        //         currentPage: 1,
        //     }
        // }
    },
    extraReducers: {
        [getProjectDetailsByID.fulfilled]: (state, action) => {
            state.details = action.payload
            state.loading = false

        },
        [getProjectDetailsByID.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getProjectDetailsByID.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get project details (' + action.error.message + ')'
        },
    },
});

export const { resetData } = projectDetailsSlice.actions

export default projectDetailsSlice.reducer;
