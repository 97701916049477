import AWS from "aws-sdk"
import SectionTitle from "components/Common/SectionTitle"
import { validate } from "components/Common/ValidationIndicator"
import moment from "moment"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"
import Swal from "sweetalert2"
import config from "../../../../src/config"

export default function ResourceDocumentSection({ title, document_types_id }) {
  const ID = config.s3.ID
  const SECRET = config.s3.SECRET
  const BUCKET_NAME = config.s3.BUCKET_NAME

  const [busy, setBusy] = useState(false)

  const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
    region: "us-east-2",
  })

  const uploadToS3 = async (file, uuid) => {
    const params = {
      ACL: "public-read",
      Bucket: BUCKET_NAME,
      Key: `carbonSpace/registration/resources/${uuid}_${file.name}`, // File name you want to save as in S3
      Body: file,
    }
    const data = await s3.upload(params).promise()
    return data["Location"]
  }

  const deleteFromS3 = async file => {
    const params = {
      Bucket: BUCKET_NAME,
      Key: file, // File name you want to delete from S3
    }
    // Deleting file
    await s3.deleteObject(params).promise()
  }

  const dispatch = useDispatch()
  let resources = useSelector(state => state.wizard.wizardData.resource)
  if (!resources) {
    resources = []
  }

  const handleAddDocument = () => {
    const newDocument = {
      timestamp: moment().valueOf(), // Assign a unique ID for each document row
      document_name: "",
      event_date: "",
      document_types_id,
      file_input: null,
    }
    dispatch(
      updateWizardData({
        resource: [...resources, newDocument],
      })
    )
  }

  const handleRemoveDocument = async (id, timestamp) => {
    setBusy(true)
    let index
    if (id) {
      index = resources.findIndex(item => item.id == id)
    } else {
      index = resources.findIndex(item => item.timestamp == timestamp)
    }
    let document = resources[index]
    // Check if the document has a file_input (S3 file link)
    if (document && document.file_input) {
      try {
        await deleteFromS3(document.file_input)
        // If deletion from S3 is successful, remove the document from Redux state
      } catch (error) {
        // console.log(error.message);
      }
    }
    const newResources = [...resources]
    newResources.splice(index, 1)
    dispatch(updateWizardData({ resource: newResources }))
    setBusy(false)
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Document deleted successfully!",
    })
  }

  const handleChange = (event, timestamp, field) => {
    const index = resources.findIndex(item => item.timestamp === timestamp)
    if (index !== -1) {
      const newResources = [...resources]
      const doc = { ...newResources[index] }
      doc[field] = event.target.value
      newResources[index] = doc
      dispatch(updateWizardData({ resource: newResources }))
    }
  }

  const handleFileChange = async (file, timestamp) => {
    setBusy(true);
    try {
      const index = resources.findIndex(item => item.timestamp === timestamp);
      if (index !== -1) {
        // Get the file name without the extension
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join(' ');

        // Update the document name with the file name if it's empty
        const newDocumentName = resources[index].document_name || fileNameWithoutExtension;

        // Get the current date in "YYYY-MM-DD" format
        const currentDate = moment().format('YYYY-MM-DD');

        // Update the event_date with the current date if it's empty
        const newEventDate = resources[index].event_date || currentDate;

        const link = await uploadToS3(file, timestamp);
        const newResources = [...resources];
        const doc = { ...newResources[index] };
        doc["file_input"] = link;
        doc["document_name"] = newDocumentName;
        doc["event_date"] = newEventDate;
        newResources[index] = doc;
        dispatch(updateWizardData({ resource: newResources }));
        Swal.fire({
          icon: "success",
          title: "Resource uploaded successfully!",
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top-right",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong! (" + error.message + ")",
        toast: true,
        timer: 1500,
        showConfirmButton: false,
        position: "top-right",
      });
    } finally {
      setBusy(false);
    }
  };


  return (
    <React.Fragment>
      <Modal isOpen={busy} centered>
        <ModalBody className="text-center">
          <RotatingLines />
          <p className="mt-2">Loading...</p>
        </ModalBody>
      </Modal>
      <div className="mb-5">
        <Row className="d-flex align-items-top">
          <Col>
            <SectionTitle title={title} />
          </Col>
        </Row>

        {[
          ...(resources &&
            resources
              .filter(item => item.document_types_id === document_types_id)
              .map((document, index) => (
                <Row
                  key={document.Rid} // Use "Rid" instead of "id"
                  className="d-flex justify-content-center align-items-top mb-3 align-content-center"
                >
                  <Col xs={1}>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <div
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          background: "white",
                          border: "1px solid #CED4DA",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                      >
                        {index + 1}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} >
                    <FormGroup>
                      <Label>Document Name</Label>
                      <Input
                        invalid={validate(document, 'document_name')}
                        type="text"
                        value={document.document_name}
                        onChange={
                          event =>
                            handleChange(
                              event,
                              document.timestamp,
                              "document_name"
                            ) // Use "Rid" instead of "id"
                        }
                        placeholder="Enter Document Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={2} >
                    <FormGroup>
                      <Label>Upload Date</Label>
                      <Input
                        type="date"
                        invalid={validate(document, 'event_date')}
                        value={document.event_date}
                        onChange={
                          event =>
                            handleChange(
                              event,
                              document.timestamp,
                              "event_date"
                            ) // Use "Rid" instead of "id"
                        }
                        placeholder="Select Date"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={2} >
                    <Row className="d-flex align-items-center">
                      <Col>
                        <FormGroup>
                          <Label>Upload Document</Label>
                          <Row className="d-flex flex-row align-items-center justify-content-between">
                            <Col>
                              <Dropzone
                                maxFiles={1}
                                multiple={false}
                                validator={file => {
                                  const extension = file.path?.split(".").pop()
                                  if (extension === "pdf" || extension === "kml") {
                                    return null
                                  } else {
                                    alert(
                                      "Please select a PDF or KML file to continue."
                                    )
                                    return {
                                      error:
                                        "Please select a PDF or KML file to continue.",
                                    }
                                  }
                                }}
                                onDrop={
                                  acceptedFiles =>
                                    handleFileChange(
                                      acceptedFiles[0],
                                      document.timestamp
                                    ) // Use "Rid" instead of "id"
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <div
                                      style={{
                                        border: "1px solid #7BC7A9",
                                        borderRadius: "4px",
                                        backgroundColor: "#E2E2E2",
                                      }}
                                    >
                                      <input {...getInputProps()} />
                                      <div
                                        className="p-1 fs-6 text-center"
                                        style={{ color: "#8F8F8F" }}
                                      >
                                        {document.file_input ? (
                                          <span>
                                            Change File{" "}
                                            <i className="bx bx-upload"></i>
                                          </span>
                                        ) : (
                                          <React.Fragment>
                                            Upload Document{" "}
                                            <i className="bx bx-upload"></i>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={2} >
                    <FormGroup>
                      <Label>Preview</Label>
                      <Col>
                        {document.file_input ? (
                          <a href={document.file_input} download>
                            {(() => {
                              const fileName = decodeURIComponent(document.file_input.split("/").pop());
                              if (fileName.length > 25) {
                                const truncatedName = fileName.substring(0, 21) + '..';
                                const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
                                return `${truncatedName}.${fileExtension}`;
                              } else {
                                return fileName;
                              }
                            })()}
                          </a>
                        ) : (
                          <p>File Not Attached</p>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <Button onClick={() => {
                        handleRemoveDocument(document.id, document.timestamp)
                      }} color="white" size="sm">
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            background: "#F46A6A22",
                          }}
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <i
                            style={{ color: "#F46A6A" }}
                            className="bx bx-trash fs-5"
                            id="prjdelete-btn"
                          />
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              ))),
          <Row
            key={"add_item"}
            style={{ cursor: "pointer" }}
            onClick={handleAddDocument}
          >
            <Col>
              <div
                className="alert bg-light text-center rounded"
                style={{ border: "2px dashed #A6B0CF", color: "#A2A2AE" }}
              >
                <i className="bx bxs-file-plus"></i> Click to add document
              </div>
            </Col>
          </Row>,
        ]}
      </div>
    </React.Fragment >
  )
}
