/* eslint-disable no-unused-vars */
import { deleteDraftProjectByID } from "components/core/services/deleteDraftProjectByIDApi"
import { getDraftProjects } from "components/core/services/getDraftProjectsApi"
import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Container, Modal, ModalBody, Row, Table } from "reactstrap"
import Swal from "sweetalert2"
import "sweetalert2/dist/sweetalert2.css"
import { useTranslation } from "react-i18next"

export default function DraftListings() {
  document.title = "Draft Projects | CarboneSpace"
  const dispatch = useDispatch()
  const data = useSelector(state => state.drafts.projects) ?? []
  const loading = useSelector(state => state.drafts.loading)
  const error = useSelector(state => state.drafts.error)
  const [activePage, setActivePage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { t } = useTranslation()
  const currentItems = data && data.draftProjects ? data.draftProjects : []
  const numOfItem = data.totalProjects;
  const handlePageChange = pageNumber => {
    setActivePage(pageNumber)
  }
  // Add these state variables at the top of your component
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    dispatch(getDraftProjects({ page: activePage, pageSize: pageSize, search: searchTerm }))
  }, [activePage, pageSize])

  // delete the item with the given carbonSpaceId and Update the 'data' state by removing the item
  const deleteItem = async itemId => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#83C7D4",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })

      if (confirmed.isConfirmed) {
        await dispatch(deleteDraftProjectByID({ id: itemId, step: 0 }))
        // Dispatch the action to fetch the data again
        dispatch(getDraftProjects({ page: activePage, pageSize: pageSize, search: searchTerm }))
        Swal.fire({
          title: "Deleted!",
          text: "The item has been deleted.",
          icon: "success",
          toast: true,
          timer: 1500,
          showCloseButton: false,
          position: "top-right",
        })
      }
    } catch (error) {
      console.log(error) // Handle the error accordingly
    }
  }

    const handleSearch = event => {
      dispatch(getDraftProjects({ page: 1, pageSize: pageSize, search: searchTerm }))
  }

  const handleClear = async () => {
    setSearchTerm(""); 
    dispatch(getDraftProjects({ page: activePage, pageSize: pageSize, search: "" }));
  }
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex align-items-center mb-5">
            <Col>
              <div className="fs-5 fw-bold">DRAFT LISTINGS</div>
            </Col>
            <Col lg={4}>
              <div className="app-search">
                <div className="position-relative">
                  <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Search..."}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ backgroundColor: "#ffffff", borderTop: "1px solid #CED4DA", borderBottom: "1px solid #CED4DA", borderLeft: "1px solid #CED4DA" }}
                  />
                  {searchTerm && (
                    <button
                      className="btn"
                      style={{ backgroundColor: "#ffffff", borderTop: "1px solid #CED4DA", borderBottom: "1px solid #CED4DA" }}
                      type="button"
                      onClick={() => handleClear()}
                    >
                      <i className="bx bx-x-circle" />
                    </button>
                  )}
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#376B57",
                      color: "#ffffff",
                      borderBottomRightRadius: "30px",
                      borderTopRightRadius: "30px",
                    }}
                    type="button"
                    onClick={() => handleSearch()}
                  >
                    <i className="bx bx-search-alt" /> Search
                  </button>

                  </div>
                </div>
              </div>
            </Col>

          </Row>
          {error ? (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error}`}
                </div>
              </Col>
            </Row>
          ) : null}
          <Modal isOpen={loading} centered>
            <ModalBody className="text-center">
              <RotatingLines />
              <p className="mt-2">Loading...</p>
            </ModalBody>
          </Modal>
          {!loading && (
            <div style={{ height: "60vh", overflow: "auto" }}>
              {currentItems.length ? (
                <Table className="table table-striped mb-0">
                  <thead className="sticky-th">
                    <tr>
                      <th>Draft ID</th>
                      <th>CH ID</th>
                      <th>Registry</th>
                      <th>Project Title</th>
                      <th>Project Sectoral Scope</th>
                      <th>Host Country</th>
                      <th>Project Creator</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.draft_id}</td>
                          <td
                            style={{
                              color:
                                item.csp_id == null ? "#CACFC9" : "inherit",
                            }}
                          >
                            {item.csp_id || "Unpublish"}
                          </td>

                          <td>{item.registry}</td>
                          <td>{item.project_title ?? "N/A"}</td>
                          <td>
                            {item.sectoral_scopes.length
                              ? item.sectoral_scopes
                                .filter(item => item != null && item.length)
                                .join(", ")
                              : "-"}
                          </td>
                          <td>
                            {item.draft_locations ? item.draft_locations : "-"}
                          </td>
                          <td>{item.prj_creator_name}</td>
                          <td>
                            <Link
                              style={{ color: "#83C7D4" }}
                              to={"/edit/" + item.id}
                            >
                              <i className="bx bx-edit fs-3" id="edit-btn"></i>
                            </Link>
                          </td>
                          <td>
                            <Link
                              style={{ color: "#F46A6A" }}
                              onClick={() => deleteItem(item.id)}
                            >
                              <i
                                className="bx bx-trash fs-3"
                                id="prjdelete-btn"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              ) : (
                <div
                  className="alert text-muted text-center"
                  style={{ background: "#fafafa" }}
                >
                  <Table className="table table-striped mb-0">
                  <thead className="sticky-th">
                    <tr>
                      <th>Draft ID</th>
                      <th>CH ID</th>
                      <th>Registry</th>
                      <th>Project Title</th>
                      <th>Project Sectoral Scope</th>
                      <th>Host Country</th>
                      <th>Project Creator</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                </Table>
                <div style={{ color: "#376B57", paddingTop: "30px", fontSize: "20px" }}>
                  <i className="bx bx-error-alt" /><br/>
                  <span style={{ fontSize: "14px" }}>
                    There are currently no projects matching this criteria. Substitute another term and see if that helps.
                  </span>
                </div>
                </div>
              )}
            </div>
          )}
          {/* Pagination */}
          {currentItems.length ? (
            <div className="d-flex justify-content-end mb-5 mt-5">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={numOfItem}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemClassNext="pagination-button"
                itemClassPrev="pagination-button"
                itemClassLast="pagination-button"
                itemClassFirst="pagination-button"
                itemClass="pagination-item"
                activeClass="pagination-active-item"
                activeLinkClass="pagination-active-item"
              />
            </div>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}
