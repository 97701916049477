import axios from 'axios'
// import config from 'config/urls'

const api = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_API_URL, headers: {
        'Content-Type': 'application/json; charset=UTF-8'
    }
})

export default api