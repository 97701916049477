/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api.js';

export const getDropdownDataApi = createAsyncThunk(
  '/dropdown/getDropdownData',
  async () => {
    try {
      let payload = {
        'dropdownData': [],
        'sectoralScopes': [],
      }
      // Regular dropdown data
      let response = await api.get(
        '/dropdown/getAllDropDowns'
      );
      let data = response.data
      if (data['error']) {
        throw new Error(data['payload'])
      }
      payload['dropdownData'] = data['payload']
      // Sectoral scope data
      response = await api.get(
        '/dropdown/getAllSectoralScopes'
      );
      data = response.data
      if (data['error']) {
        throw new Error(data['payload'])
      }
      payload['sectoralScopes'] = data['payload']
      response = await api.get(
        '/dropdown/getAllRegisterStatus'
      );
      data = response.data
      if (data['error']) {
        throw new Error(data['payload'])
      }
      payload['registryStatus'] = data['payload']
      return payload
    } catch (error) {
      throw error
    }
  }
);