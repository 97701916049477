/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api"

const { createAsyncThunk } = require("@reduxjs/toolkit")

export const postLogin = createAsyncThunk("/auth/login", async payload => {
  try {
    const response = await api.post("/auth/login", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
    const data = response.data
    console.log("🚀 ~ postLogin ~ data:", data)
  
    return data
  } catch (error) {
    console.log("🚀 ~ postLogin ~ error:", error)
    return error
  }
})

export const postCreateUser = createAsyncThunk(
  "user/createUser",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(
        "/user/createUser",
        payload, // Move payload here
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      console.log(error);
      throw error
    }
  }
)

export const postPasswordChnage = createAsyncThunk(
  "/user/changePassword",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post("/user/changePassword", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const postUserActivation = createAsyncThunk(
  "/user/disableUser",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(`/user/disableUser`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      const data = response.data
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const updateUser = createAsyncThunk("/user/editUser", async payload => {
  try {
    const token = localStorage.getItem("authToken")
    const response = await api.post(
      "/user/editUser",
      payload, // Move payload here
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const data = response.data
    if (data["error"]) {
      throw new Error(data["payload"])
    }
    return data["payload"]
  } catch (error) {
    throw error
  }
})
export const userForgetPassword = createAsyncThunk(
  "/auth/initResetPasswordLink",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(
        "/auth/initResetPasswordLink",
        payload, // Move payload here
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)
export const deleteUser = createAsyncThunk(
  "/user/deleteUser",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(
        "/user/deleteUser",
        payload, // Move payload here
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = response.data

      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)
