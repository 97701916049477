/* eslint-disable */
import _ from "lodash"
import { useEffect, useState } from "react"
import { InfinitySpin } from "react-loader-spinner"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { PieChartTooltip, getChartValue, getCompactValue } from "./stackedChart"
import html2canvas from 'html2canvas';

function ClusteredBarChart(props) {
  const data = props.chartData
  const loading = props.loading
  const error = props.error
  const dateFilter = props.dateFilter
  const startDate = props.startDate
  const isLog = props.isLog
  const [chartHeight, setChartHeight] = useState(450)
  const [chartBottomMargin, setChartBottomMargin] = useState(60)
  const [legendItems, setLegendItems] = useState({
    nature_based: true,
    energy: true,
    household: true,
    industrial: true,
    waste_management: true,
    transport: true,
    other: true,
  })

  const toggleLegendItem = dataKey => {
    setLegendItems(prevItems => ({
      ...prevItems,
      [dataKey]: !prevItems[dataKey],
    }))
  }

  // // Function to format Y-axis values as currency
  // const formatYAxisValue = value => {
  //   return Intl.NumberFormat("en-US", { notation: "compact" }).format(value)
  // }

  useEffect(() => {
    const updateChartHeight = () => {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const newChartHeight = Math.round(screenHeight * 0.6);
      const newChartBottomMargin = Math.round(screenHeight * 0.1);   
      setChartHeight(newChartHeight);
      setChartBottomMargin(newChartBottomMargin);
    };  
    updateChartHeight();  
    window.addEventListener("resize", updateChartHeight);
      return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, []); 
  
  let xAxisTitle = ""
  if (dateFilter === 1) {
    xAxisTitle = "Retirement Date"
  }
  if (dateFilter === 2 ) {
    xAxisTitle = "Vintage Year"
  }

  const styles = {
    loadingContainer: {
      paddingTop: "10%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }

  const chartData = []
  // let isLog = true
  _.get(data, "categories", []).forEach((item, index) => {
    chartData.push({
      name: item,
      nature_based: getChartValue(data["series"][0][index], isLog),
      energy: getChartValue(data["series"][1][index], isLog),
      household: getChartValue(data["series"][2][index], isLog),
      industrial: getChartValue(data["series"][3][index], isLog),
      waste_management: getChartValue(data["series"][4][index], isLog),
      transport: getChartValue(data["series"][5][index], isLog),
      other: getChartValue(data["series"][6][index], isLog),
    })
  })

  const handleDownload = (startValue, endValue) => {
    const chartContainer = document.getElementById('chart');
  
    html2canvas(chartContainer, { scale: 2 }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `clustered_chart_${startValue}to${endValue}.png`;
      link.click();
    });
  };

const totalTicks = 8;
  const interval = Math.max(1, Math.floor((chartData.length - 1) / (totalTicks - 1)));
  const xTicks = Array.from({ length: totalTicks }, (_, index) => Math.min(index * interval, chartData.length - 1));

  return (
    <div>
      <div id="chart" style={{ minHeight: "30vh", overflow: "visible" }}>
        {loading ? (
          <div style={styles.loadingContainer}>
            <InfinitySpin width="200" height={chartHeight} color="#36d7b7" />
            <p className="pt-3">Loading data...</p>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : data && data.categories && data.categories.length > 0 ? (
          <ResponsiveContainer height={chartHeight} width={"100%"}>
            <button
              onClick={() => handleDownload(chartData[0].name, chartData[chartData.length - 1].name)}
              title="Download chart as PNG"
              style={{
                marginTop: '-40px',
                paddingRight: '20px',
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#645057',
                background: 'none',
                border: 'none',
                cursor: 'pointer',                
              }}
            >
              Download &nbsp;<i className="fas fa-download"></i>
            </button>
            <BarChart
              data={chartData}
              margin={{
                top: 50,
                right: 0,
                left: 1,
                bottom: chartBottomMargin,
              }}
            >
              <CartesianGrid
                stroke="#F9F9F9"
                horizontal
                strokeWidth={1}
                vertical={false}
              />
              <XAxis
                axisLine={{ stroke: "#F1F7F7", strokeWidth: 3 }}
                label={{
                  value: xAxisTitle,
                  position: "insideBottom",
                  offset: -50,
                  style: {
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                  },
                }}
                angle={-45}  // Set the angle to 45 degrees
                textAnchor="end"
                dataKey="name"
                interval={0}
                tickFormatter={(value, index) => {
                  if (chartData.length < 20 || xTicks.includes(index)) {
                    if (/^\d{4}-\d{2}$/.test(value)) {
                      const [year, month] = value.split('-');
                      const monthName = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' });
                      return `${year} ${monthName}`;
                    } else {
                      return value;
                    }
                  } else {
                    return '';
                  }
                }}
              />
              <YAxis
                axisLine={{ stroke: "#F1F7F7", strokeWidth: 3 }}
                label={{
                  value: "Credits Retired",
                  angle: -90,
                  position: "insideLeft",
                  style: {
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                    paddingRight: "10px",
                  },
                }}
                tickFormatter={getCompactValue}
              />
              <Legend
                onClick={e => toggleLegendItem(e.dataKey)}
                verticalAlign="top"
                align="right"
                layout="vertical"
                wrapperStyle={{
                  paddingLeft: "50px",
                  paddingRight: "20px",
                  paddingTop: "0px",
                  lineHeight: "35px",
                  color: "#000000",
                }}
                iconType="square"
                formatter={(value, entry, index) => (
                  <span
                    style={{
                      marginLeft: "18px",
                      color: "#575757",
                      borderRadius: "2px", // Apply radius of 2px for legend icon
                    }}
                  >
                    {value}
                  </span>
                )}
              />

              <Bar
                dataKey="nature_based"
                fill="#1D9D60"
                name="Nature Based"
                barSize={20}               
                hide={!legendItems.nature_based || chartData.every(item => item.nature_based === 0)}
                stroke="#1D9D60"
                strokeWidth={1}
                />
              <Bar
                dataKey="energy"
                fill="#F8BA1D"
                name="Energy"
                barSize={20}     
                hide={!legendItems.energy || chartData.every(item => item.energy === 0)}
                stroke="#F8BA1D"
                strokeWidth={1}
              />
              <Bar
                dataKey="household"
                fill="#19A1B4"
                name="Household"
                barSize={20} 
                hide={!legendItems.household || chartData.every(item => item.household === 0)}
                stroke="#19A1B4"
                strokeWidth={1}
              />
              <Bar
                dataKey="industrial"
                fill="#015F81"
                name="Industrial"
                barSize={20} 
                hide={!legendItems.industrial || chartData.every(item => item.industrial === 0)}
                stroke="#015F81"
                strokeWidth={1}
              />
              <Bar
                dataKey="waste_management"
                fill="#A6B0CF"
                name="Waste Management"
                barSize={20} 
                hide={!legendItems.waste_management || chartData.every(item => item.waste_management === 0)}
                stroke="#A6B0CF"
                strokeWidth={1}
              />
              <Bar
                dataKey="transport"
                fill="#8DD98E"
                name="Transport"
                barSize={20} 
                hide={!legendItems.transport || chartData.every(item => item.transport === 0)}
                stroke="#8DD98E"
                strokeWidth={1}
              />
              <Bar
                dataKey="other"
                fill="#7AA5BF"
                name="Other"
                barSize={20} 
                hide={!legendItems.other || chartData.every(item => item.other === 0)}
                stroke="#7AA5BF"
                strokeWidth={1}
              />
              {/* <Tooltip
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                formatter={(value, name, props) => [value, name]}
              /> */}
              <Tooltip cursor={false} content={<PieChartTooltip />} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p
            style={{ textAlign: "center", marginTop: "15vh", fontSize: "15px" }}
          >
            No data available!
          </p>
        )}
      </div>
    </div>
  )
}

export default ClusteredBarChart
