import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import "./i18n"
import * as serviceWorker from "./serviceWorker"
import store from "store"
import "rsuite/dist/rsuite-no-reset.min.css"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

serviceWorker.unregister()
