import React from "react"
import { Col, Container, Row } from "reactstrap"
import { useSelector } from "react-redux"
import TwoLineItem from '../DetailsView/TwoLineItem'
import CardSectionTitle from "components/DetailsView/CardSectionTitle"

// eslint-disable-next-line no-unused-vars
function GeneralInformation(props) {
  const generalInformation = useSelector(
    state => state.projectDetails.details.generalInformation
  )

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle title="General Information" icon={<img src="/icons/general.svg" />} />
        <Row>
          <Col>
            <TwoLineItem title="CS ID" body={generalInformation?.csp_id ?? "--"} tooltip="Project's unique CarboneSpace identifier number." />
          </Col>

          <Col>
            <TwoLineItem title="Registry" body={generalInformation?.registry ?? "--"} tooltip="Name of carbon Registry where the project is listed/registered." />
          </Col>

          <Col>
            <TwoLineItem title="Registry ID" body={generalInformation?.registry_id ?? "--"} tooltip="Project's unique identifier number in the carbon registry." />
          </Col>

          <Col>
            <TwoLineItem title="Registered Date" body={generalInformation?.registry_date?.split("T")[0] ?? "--"} tooltip="Date of the project's registration in the Registry" />
          </Col>

          <Col>
            <TwoLineItem title="Registry Status" body={generalInformation?.registry_status_id ?? "--"} tooltip="Stage of the project in the registration and crediting process" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default GeneralInformation
