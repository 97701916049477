/* eslint-disable */
import _ from "lodash"
import { useEffect, useState } from "react"
import { InfinitySpin } from "react-loader-spinner"
import {
  CartesianGrid,
  Cell,
  Dot,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { getChartValue, getCompactValue,PieChartTooltip} from "./stackedChart"
import html2canvas from 'html2canvas';

function LineChartComponent(props) {
  const data = props.chartData
  const loading = props.loading
  const error = props.error
  const dateFilter = props.dateFilter
  const startDate = props.startDate
  const isLog = props.isLog
  const [chartHeight, setChartHeight] = useState(450)
  const [chartBottomMargin, setChartBottomMargin] = useState(60)
  const [legendItems, setLegendItems] = useState({
    nature_based: true,
    energy: true,
    household: true,
    industrial: true,
    waste_management: true,
    transport: true,
    other: true,
  })

  const toggleLegendItem = dataKey => {
    setLegendItems(prevItems => ({
      ...prevItems,
      [dataKey]: !prevItems[dataKey],
    }))
  }

  useEffect(() => {
    const updateChartHeight = () => {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const newChartHeight = Math.round(screenHeight * 0.6);
      const newChartBottomMargin = Math.round(screenHeight * 0.1); // Adjust the percentage as needed
  
      setChartHeight(newChartHeight);
      setChartBottomMargin(newChartBottomMargin);
    };
  
    // Initial setup
    updateChartHeight();
  
    // Add event listener for window resize
    window.addEventListener("resize", updateChartHeight);
  
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts
  

  // const closeModal = () => {
  //   // Your existing closeModal function logic
  // }

  // const prepareDate = value => {
  //   // Your existing prepareDate function logic
  // }

  let xAxisTitle = ""
  if (dateFilter === 1) {
    xAxisTitle = "Retirement Date"
  }
  if (dateFilter === 2 ) {
    xAxisTitle = "Vintage Year"
  }

  const styles = {
    loadingContainer: {
      paddingTop: "10%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }

  const chartData = []
  _.get(data, "categories", []).forEach((item, index) => {
    chartData.push({
      name: item,
      nature_based: getChartValue(data["series"][0][index], isLog),
      energy: getChartValue(data["series"][1][index], isLog),
      household: getChartValue(data["series"][2][index], isLog),
      industrial: getChartValue(data["series"][3][index], isLog),
      waste_management: getChartValue(data["series"][4][index], isLog),
      transport: getChartValue(data["series"][5][index], isLog),
      other: getChartValue(data["series"][6][index], isLog),
    })
  })

  const handleDownload = (startValue, endValue) => {
    const chartContainer = document.getElementById('chart');
  
    html2canvas(chartContainer, { scale: 2 }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `line_chart_${startValue}to${endValue}.png`;
      link.click();
    });
  };

  const totalTicks = 8;
  const interval = Math.max(1, Math.floor((chartData.length - 1) / (totalTicks - 1)));
  const xTicks = Array.from({ length: totalTicks }, (_, index) => Math.min(index * interval, chartData.length - 1));

  const renderDot = props => {
    if (chartData.length <= 20) {
      return <Dot {...props} />
    }
    return null
  }

  return (
    <div>
      <div id="chart" style={{ minHeight: "30vh", overflow: "visible" }}>
        {loading ? (
          <div style={styles.loadingContainer}>
            <InfinitySpin width="200" height={chartHeight} color="#36d7b7" />
            <p className="pt-3">Loading data...</p>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : data && data.categories && data.categories.length > 0 ? (
          <ResponsiveContainer height={chartHeight} width={"100%"}>
            <button
              onClick={() => handleDownload(chartData[0].name, chartData[chartData.length - 1].name)}
              title="Download chart as PNG"
              style={{
                marginTop: '-40px',
                paddingRight: '20px',
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#645057',
                background: 'none',
                border: 'none',
                cursor: 'pointer', 
                
              }}
            >
              Download &nbsp;<i className="fas fa-download"></i>
            </button>
            <LineChart
              data={chartData}
              margin={{
                top: 50,
                right: 0,
                left: 1,
                bottom: chartBottomMargin,
              }}
            >
              <CartesianGrid
                stroke="#F9F9F9"
                horizontal
                strokeWidth={1}
                vertical={false}
              />
              <XAxis
                axisLine={{ stroke: "#F1F7F7", strokeWidth: 3 }}
                label={{
                  value: xAxisTitle,
                  position: "insideBottom",
                  offset: -50,
                  style: {
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                  },
                }}
                angle={-45}  // Set the angle to 45 degrees
                textAnchor="end"
                dataKey="name"
                interval={0}
                tickFormatter={(value, index) => {
                  if ( chartData.length < 20 || xTicks.includes(index)) {
                    // Check if the value is in the "YYYY-MM" format
                    if (/^\d{4}-\d{2}$/.test(value)) {
                      // Convert "YYYY-MM" to "YYYY MMM"
                      const [year, month] = value.split('-');
                      const monthName = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' });
                      return `${year} ${monthName}`;
                    } else {
                      return value;
                    }
                  } else {
                    return '';
                  }
                }}
              />
              <YAxis
                axisLine={{ stroke: "#F1F7F7", strokeWidth: 3 }}
                label={{
                  value: "Credits Retired",
                  angle: -90,
                  position: "insideLeft",
                  style: {
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                    paddingRight: "10px",
                  },
                }}
                tickFormatter={getCompactValue}
              />
              <Legend
                onClick={e => toggleLegendItem(e.dataKey)}
                verticalAlign="top"
                align="right"
                layout="vertical"
                wrapperStyle={{
                  paddingLeft: "50px",
                  paddingRight: "20px",
                  paddingTop: "0px",
                  lineHeight: "35px",
                  color: "#000000",
                }}
                iconType="square"
                formatter={(value, entry, index) => (
                  <span
                    style={{
                      marginLeft: "18px",
                      color: "#575757",
                      borderRadius: "2px", // Apply radius of 2px for legend icon
                    }}
                  >
                    {value}
                  </span>
                )}
              />

              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="nature_based"
                stroke="#1D9D60"
                name="Nature Based"
                hide={!legendItems.nature_based}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="energy"
                stroke="#F8BA1D"
                name="Energy"
                hide={!legendItems.energy}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="household"
                stroke="#19A1B4"
                name="Household"
                hide={!legendItems.household}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="industrial"
                stroke="#015F81"
                name="Industrial"
                hide={!legendItems.industrial}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="waste_management"
                stroke="#A6B0CF"
                name="Waste Management"
                hide={!legendItems.waste_management}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="transport"
                stroke="#8DD98E"
                name="Transport"
                hide={!legendItems.transport}
                dot={renderDot}
              />
              <Line
                type="monotone"
                strokeWidth={3}
                dataKey="other"
                stroke="#7AA5BF"
                name="Other"
                hide={!legendItems.other}
                dot={renderDot}
              />
              {/* <Tooltip
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                formatter={(value, name, props) => [value, name]}
              /> */}
              <Tooltip
                cursor={false}
                content={(props)=>{
                  let tempFixPayload  = props.payload.map((item)=>{
                    return {
                      ...item,
                      fill: item.stroke
                    }
                  });
                  return PieChartTooltip({
                    ...props,
                    payload: tempFixPayload
                  });
                }}
                position="top"
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p
            style={{ textAlign: "center", marginTop: "15vh", fontSize: "15px" }}
          >
            No data available!
          </p>
        )}
      </div>
    </div>
  )
}

export default LineChartComponent
