/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card } from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { logout } from '../../rtk-store/slices/authSlice';
import { Link } from "react-router-dom"


// import CarouselPage from "../Authentication/CarouselPage"
import withRouter from "components/Common/withRouter"
import Logo_cs from "../../assets/images/CarbonhubAiFinalLogo.svg"
import Sucess from "../../assets/images/Success Icon.svg"


const ChanageSuccess = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login'); // Redirect to login page
  };


  return (
    <React.Fragment>
      <MetaTags className="pt-5">
        <title>Login | CarboneSpace</title>
      </MetaTags>
      <div style={{ backgroundColor: "#F3F3F9", height: "100vh" }}>

        <Row style={{ position: 'relative', height: "100%" }}>
          <Col lg={4} />
          <Col lg={4}>
            <Card className="overflow-hidden" style={{ marginTop: "25%" , borderRadius: "10px"}}>
              <CardBody className="pt-0">

                <div className="p-2">
                  <div
                    className="form-horizontal"
                    
                  >
                      <div className="text-center mb-4">
                        <img className="pt-5 pb-4" src={Logo_cs} style={{ borderRadius: "10px"}} width="150px" alt="Logo" />
                        <h3 className="pt-2 pb-2" style={{ marginBottom: "20px" }}>Create Your New Password</h3>
                      </div>

                  <div className="mb-2 mt-2 text-center"> <img src={Sucess}/> </div>
                  <div className="mt-4 text-center">
                    <label htmlFor="password">Your password has been changed successfully.</label>
                  </div>



                    <br />

                    

                    <div className="mt-1 pb-3 d-grid">
                      <br />

                      <Link
                        className="btn btn-block"
                        style={{ backgroundColor: "#12526D", color: "white" }}
                        onClick={handleLogout}
                      >
                        Return to Login
                      </Link>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="mt-3 text-center">
              <p style={{ fontSize: "10px", marginBottom: "-100px" }}>
                Powered by Searchspace geo © {new Date().getFullYear()} |
                Privacy Policy | Cookie Policy
              </p>
            </div>
          </Col>
          <Col lg={4} />
        </Row>

      </div>
    </React.Fragment>
  )
}

export default withRouter(ChanageSuccess)

ChanageSuccess.propTypes = {
  history: PropTypes.object,
}
