import { Workbook } from "exceljs";
import { FormLabel } from "react-bootstrap";
import { FormGroup } from "reactstrap";
import { React } from 'react'

export default function CSVUploader({ onUploadComplete, onError }) {
    const handleOnChange = (e) => {
        try {
            const files = e.target.files
            if (files.length) {
                const file = e.target.files[0]
                const fileReader = new FileReader()
                fileReader.onload = async (e) => {
                    let result = e.target.result
                    const workbook = new Workbook()
                    await workbook.xlsx.load(result)
                    const sheet = workbook.worksheets[0]
                    const data = []
                    sheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
                        if (rowIndex != 1) {
                            const dataItem = []
                            // eslint-disable-next-line no-unused-vars
                            row.eachCell({ includeEmpty: true }, (cell, _) => { 
                                const value = cell.value
                                dataItem.push(value)
                            })
                            data.push(dataItem)
                        }
                    })
                    console.log(data);
                    onUploadComplete(data)
                }
                fileReader.readAsArrayBuffer(file)
            }
            else {
                throw new Error('No file selected')
            }
        } catch (error) {
            onError(error.message)
        }
    }
    return <FormGroup>
        <FormLabel>Upload *.xlsx file</FormLabel>
        <input className="form-control" type="file" onClick={e => {
            e.target.value = null
        }} onChange={handleOnChange} accept=".xlsx" />
    </FormGroup>
}