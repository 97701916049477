import React from 'react'
export default function CardSectionTitle({ title, icon, subTitle }) {
    return <div style={{ marginBottom: '32px' }}>
    <div className="fw-bold d-flex align-items-center" >
        {icon} <span style={{ fontSize: '24px', color: '#495057' }} className="ms-2">{title}</span><br/>
        
    </div>
    <div>
    <span style={{ fontSize: '14px', color: '#495057' }} className="ms-2">{subTitle}</span>
    </div>
    </div>
}