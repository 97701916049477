/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "./api.js"

export const getPublishedProjects = createAsyncThunk(
  'published/getAllPublishProjects',
  async ({ page , pageSize, search }) => {
    try {
      const response = await api.get("/publish/getAllPublishProjects", {
        params: {
          page,
          pageSize,
          search,
        },
      })
      const data = response.data
      if (data.error) {
        throw new Error(data.payload)
      }
      return data.payload
    } catch (error) {
      throw error
    }
  }
)
