/* eslint-disable no-unused-vars */
import { deletePublishedProjectByID } from "components/core/services/deleteDraftProjectByIDApi"
import { editPublishedProject } from "components/core/services/editPublishedProjectApi"
import { getPublishedProjects } from "components/core/services/getPublishedProjectsApi"
import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import { RotatingLines } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Col, Container, Modal, ModalBody, Row, Table } from "reactstrap"
import Swal from "sweetalert2"
import "sweetalert2/dist/sweetalert2.css"
import { useTranslation } from 'react-i18next';

const PendingApprovals = () => {
  document.title = "Draft Projects | CarboneSpace"
  const dispatch = useDispatch()
  const data = useSelector(state => state.published.projects) ?? []
  const loading = useSelector(state => state.published.loading)
  const error = useSelector(state => state.published.error)
  const [activePage, setActivePage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { t } = useTranslation();



  // Get the current items to display based on the active page
  const currentItems = data && data.publishProjects ? data.publishProjects : []
  const numOfItem = data.totalPages * 10
  const handlePageChange = pageNumber => {
    setActivePage(pageNumber)
  }

  // Add these state variables at the top of your component
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(currentItems);

  useEffect(() => {
    dispatch(getPublishedProjects({ page: activePage, pageSize: pageSize }))
  }, [activePage, pageSize, dispatch])

  // delete the item with the given carbonSpaceId and Update the 'data' state by removing the item
  const deleteItem = async itemId => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#83C7D4",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })

      if (confirmed.isConfirmed) {
        await dispatch(deletePublishedProjectByID({ id: itemId, step: 0 }))
        // Dispatch the action to fetch the data again
        dispatch(getPublishedProjects({ page: activePage, pageSize: pageSize }))
        Swal.fire({
          title: "Deleted!",
          text: "The item has been deleted.",
          icon: "success",
          toast: true,
          timer: 1500,
          showCloseButton: false,
          position: "top-right",
        })
      }
    } catch (error) {
      console.log(error) // Handle the error accordingly
    }
  }

  const navigate = useNavigate()

  const handleEditProject = (id) => {
    dispatch(editPublishedProject(id)).unwrap().then((draftId) => {
      if (draftId && draftId != null) {
        navigate('/edit/' + draftId)
      }
      else {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong while trying to create the new draft project. Please try again.',
          icon: 'error'
        })
      }
      // console.log(draftId);
    })
  }

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchTerm(searchText);

    // Filter the currentItems based on the search term
    const filteredItems = currentItems.filter(item => {
      return (
        item.csp_id.toLowerCase().includes(searchText.toLowerCase()) ||
        item.registry.toLowerCase().includes(searchText.toLowerCase()) ||
        item.project_title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.sectoral_scopes.join(', ').toLowerCase().includes(searchText.toLowerCase()) ||
        item.locations.toLowerCase().includes(searchText.toLowerCase()) ||
        item.prj_creator_name.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    setFilteredItems(filteredItems);
  };


  useEffect(() => {
    // Filter currentItems based on the search term and set filteredItems state
    const filteredItems = currentItems.filter(item => {
      return (
        item.csp_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.registry.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.project_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.sectoral_scopes.join(', ').toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.locations.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.prj_creator_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredItems(filteredItems);
  }, [searchTerm, currentItems]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="fs-5 fw-bold mb-3">PENDING APPROVALS</div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col md="3">
              <form className="app-search d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Search") + "..."}
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Col>
          </Row>
          {error && (
            <Row>
              <Col>
                <div className="alert bg-danger text-white text-center">
                  {`Error: ${error.message}`}
                </div>
              </Col>
            </Row>
          )}
          <Modal isOpen={loading} centered>
            <ModalBody className="text-center">
              <RotatingLines />
              <p className="mt-2">Loading...</p>
            </ModalBody>
          </Modal>
          {!loading && (
            <div
              style={{ height: "490px", minHeight: "490px", overflow: "auto" }}
            >
              {currentItems.length ? (
                <Table className="table table-striped mb-0">
                  <thead className="sticky-th">
                    <tr>
                      <th>CH ID</th>
                      <th>Registry</th>
                      <th>Project Title</th>
                      <th>Project Sectoral Scope</th>
                      <th>Host Country</th>
                      <th>Project Creator</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.csp_id}</td>
                          <td>{item.registry}</td>
                          <td>{item.project_title ?? "N/A"}</td>
                          <td>
                            {item.sectoral_scopes.length
                              ? item.sectoral_scopes
                                .filter(item => item != null && item.length)
                                .join(", ")
                              : "-"}
                          </td>
                          <td>
                            {item.locations
                              ? item.locations
                              : "-"}
                          </td>
                          <td>{item.prj_creator_name}</td>
                          <td>
                            <Link to={"/view/" + item.id} style={{ color: "#7BC7A9", fontSize: "20px" }}>
                              <i className="dripicons-preview"></i>
                            </Link>
                          </td>

                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              ) : (
                <div
                  className="alert text-muted text-center"
                  style={{ background: "#fafafa" }}
                >
                  No draft listings. Start by creating a listing…
                </div>
              )}
            </div>
          )}
          {/* Pagination */}
          {currentItems.length && (
            <div className="d-flex justify-content-end mb-5 mt-5">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={numOfItem}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingApprovals
