import React from "react";
import PropTypes from "prop-types";
import Logo_cs from "../../assets/images/CarbonhubAiFinalLogo.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import withRouter from "components/Common/withRouter";


const StepOne = (props) => {



  return (
    <React.Fragment>
      <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    
                  >
                      <div className="text-center mb-4">
                        <img className="pt-4 pb-4" src={Logo_cs} width="150px" alt="Logo" />
                        <h3 className="pt-3 pb-2" style={{ marginBottom: "20px" }}>Forgot your password</h3>
                      </div>

                    <div className="mb-4">
                      <label htmlFor="email">Username</label>
                      <AvField
                        name="email"
                        value=""
                        className="form-control"
                        placeholder="Enter Email Address"
                        type="email"
                        required
                      />
                    </div>


                    <br />

                    

                    <div className="mt-1 pb-5 d-grid">
                      <br />

                      <button
                        className="btn btn-block"
                        style={{ backgroundColor: "#12526D", color: "white" }}
                        onClick={props.onNextStep} // Call the onNextStep function here
                      >
                        Next
                      </button>
                    </div>
                  </AvForm>
                </div>
    </React.Fragment>
  );
};

StepOne.propTypes = {
  history: PropTypes.object,
};

export default withRouter(StepOne);
