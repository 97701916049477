import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import emailsuccess from "../../assets/images/email-success.svg"

const ContactSuccessing = () => {
  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <div className="cont-success">
            <h4>We successfully received your message.</h4>
            <h6>We'll get back soon.</h6>
            <img src={emailsuccess} className="email-suc"></img>
          </div>
          <br />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContactSuccessing
