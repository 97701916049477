import CardSectionTitle from "components/DetailsView/CardSectionTitle"
import React from "react"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import TwoLineItem from "../DetailsView/TwoLineItem"

function ApprovalCertification() {
  const ApprovalCertification = useSelector(
    state => state.projectDetails.details.approvalsCertification
  )

  function capitalizeText(str) {
    if (!str) {
      return str
    }
    if (str === "n/a") {
      return str.toUpperCase()
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const sectoralScopeIconsId = [
    {
      id: 768,
      label: 1,
      title: "1.No Poverty",

    },
    {
      id: 769,
      label: 2,
      title: "2.Zero Hunger",

    },
    {
      id: 770,
      label: 3,
      title: "3.Good Health and well being",
    },
    {
      id: 771,
      label: 4,
      title: "4.Quality education",
    },
    {
      id: 772,
      label: 5,
      title: "5.Gender equality"
    },
    {
      id: 773,
      label: 6,
      title: "6.Clean water and sanitation"
    },
    {
      id: 774,
      label: 7,
      title: "7.Affordable and clean energy"
    },
    {
      id: 775,
      label: 8,
      title: "8.Decent work and economic growth"
    },
    {
      id: 776,
      label: 9,
      title: "9.Industry, innovation and infrastructure",
    },
    {
      id: 777,
      label: 10,
      title: "10.Reduced inequalities",

    },
    {
      id: 778,
      label: 11,
      title: "11.Sustainable cities and communities",

    },
    {
      id: 779,
      label: 12,
      title: "12.Responsible consumption and production",

    },
    {
      id: 780,
      label: 13,
      title: "13.Climate action",

    },
    {
      id: 781,
      label: 14,
      title: "14.Life below water",

    },
    {
      id: 782,
      label: 15,
      title: "15.life on land",

    },
    {
      id: 783,
      label: 16,
      title: "16.Peace, justice and strong institution",

    },
    {
      id: 784,
      label: 17,
      title: "17.Partnership for the goals",

    },
  ]

  let sustainableGoalsIcons = []

  if (ApprovalCertification?.sustainable_development_goals) {
    sustainableGoalsIcons = ApprovalCertification?.sustainable_development_goals
      .split(",")
      .map(id => {
        const numericId = parseInt(id)
        const matchingItem = sectoralScopeIconsId.find(
          item => item.id === numericId
        )
        return matchingItem ? matchingItem.label : null
      })
  }

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle
          title="Approvals and Certifications"
          icon={<img src="/icons/approvals.svg" />}
        />
        <Row>
          <Col>
            <TwoLineItem
              title="CORSIA Eligible"
              body={capitalizeText(
                ApprovalCertification?.corsia_qualified ?? "--"
              )}
              tooltip="Binary selection indicating whether the project's credits are eligible as offsets under the CORSIA program. (Y/N)."
            />
          </Col>

          <Col>
            <TwoLineItem
              title="CCB"
              body={capitalizeText(ApprovalCertification?.ccb_eligible ?? "--")}
              tooltip="Binary selection indicating whether the project's credits meet the Climate, Community and Biodiversity standards (Y/N)."
            />
          </Col>

          <Col>
            <TwoLineItem
              title="SD Vista"
              body={capitalizeText(
                ApprovalCertification?.sdvista_eligible ?? "--"
              )}
              tooltip="Binary selection indicating whether the project's credits meet the Sustainable Development Verified Impact Standard (Y/N)."
            />
          </Col>

          <Col>
            <TwoLineItem
              title="Project Approvals"
              body={capitalizeText(
                ApprovalCertification?.project_approvals ?? "--"
              )}
              tooltip="The list of approvals from various third party agencies."
            />
          </Col>

          <Col>
            <TwoLineItem
              title="ARB Eligible"
              body={capitalizeText(ApprovalCertification?.arb_eligible ?? "--")}
              tooltip="Binary selection indicating whether the project's credits are eligible as offsets under the California's Air Resources Board Program (Y/N)."
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <TwoLineItem
              title="UNSDGs"
              body={
                <div className="icon-row flex-wrap">
                  {sustainableGoalsIcons.map((icon, index) => {
                    if (icon != "NaN" && icon != null) {
                      const scope = sectoralScopeIconsId.find(
                        item => item.label === icon
                      )
                      return (
                        <div className="icon-container" key={index}>
                          <img
                            className="me-1"
                            src={`/icons/sdg/${icon}.jpg`}
                            width={64}
                            height={64}
                            title={scope && scope.title ? scope.title : ""}
                            alt={`Icon ${icon}`}
                            style={{ borderRadius: "6px" }}
                          />
                        </div>
                      )
                    }
                  })}
                </div>
              }
              tooltip="United Nations' Sustainable Development Goals (SDG) addressed by the project as per its PDD."
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

export default ApprovalCertification
