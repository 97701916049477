/* eslint-disable no-undef */
import api from "./api";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const getProjectDetailsByID = createAsyncThunk('/publish/detailViewPage/', async (id) => {
    try {
        let baseURL = "/publish/detailViewPage/"
        let url = baseURL + id;
      
        const res = await api.get(url)

        let data = res.data
        if (data['error']) {
            throw new Error(data['payload'])
        }
        data = data['payload']

        if (data && data.length) {
            data = data[0]
        }
        return data
    }
    catch (error) {
      console.log(error);
        throw error
    }
})