import { createSlice } from "@reduxjs/toolkit"
import {
  getCompaniesList,
  //  toggleCompanyStatus
} from "../../components/core/services/getCompanies"
import { postCreateUpdateCompany } from "../../components/core/services/postCompany"

// Slice with the necessary actions and reducer
const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    loading: false,
    error: null,
  },
  reducers: {
    toggleCompanyState: (state, action) => {
      state.companies = state.companies.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            isActive: action.payload.isActive,
          }
        }
        return item
      })
    },
  },
  extraReducers: {
    [getCompaniesList.fulfilled]: (state, action) => {
      state.companies = action.payload ?? []
      state.loading = false
      state.error = undefined
    },
    [getCompaniesList.pending]: state => {
      state.loading = true
      state.error = undefined
    },
    [getCompaniesList.rejected]: (state, action) => {
      state.error = action.error.message
      state.loading = false
    },
    [postCreateUpdateCompany.pending]: state => {
      state.error = undefined
      state.loading = true
    },
    [postCreateUpdateCompany.fulfilled]: state => {
      state.error = undefined
      state.loading = false
    },
    [postCreateUpdateCompany.rejected]: (state, action) => {
      state.error = action.error.message
      state.loading = false
    },
    // [toggleCompanyStatus.pending]: (state) => {
    //   state.loading = true
    //   state.error = false
    // },
    // [toggleCompanyStatus.rejected]: (state, action) => {
    //   state.error = action.error.message
    //   state.loading = false
    // },
    // [toggleCompanyStatus.fulfilled]: (state) => {
    //   state.loading = false
    //   state.error = undefined
    // }
  },
})
export const { toggleCompanyState } = companiesSlice.actions
export default companiesSlice.reducer
