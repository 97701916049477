import jwtDecode from "jwt-decode"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
// Import all middleware
// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import VerticalLayout from "./components/VerticalLayout/"

// Import scss
import NonAuthLayout from "components/NonAuthLayout"
import LoadingPage from "pages/Authentication/Loader"
import CreateListing from "pages/CreateListing"
import DetailView from "pages/DetailsView"
import DraftListings from "pages/DraftListings"
import PendingApprovals from "pages/PendingApprovals"
import PublishedListings from "pages/PublishedListings"
import RejectedListings from "pages/RejectedListings"
import RetirementByProjectType from "pages/RetirementByProjectType"
import ChanageSuccess from "pages/UserManagement/ChanageSuccess"
import FogotPassword from "pages/UserManagement/FogotPassword"
import Login from "pages/UserManagement/Login"
import NewPassword from "pages/UserManagement/NewPassword"
import Users from "pages/UserManagement/index"
import ProtectedRoute from "routes/ProtectedRoute"
import "rsuite/dist/rsuite.min.css"
import "./assets/scss/theme.scss"
import AnalyticsLoader from "pages/Authentication/AnalyticsLoader"
import Companies from "pages/UserManagement/Companies"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const publicRoutes = [
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/forgotpassword",
    component: <FogotPassword />
  },
  {
    path: "/loader",
    component: <LoadingPage />
  },
  {
    path: "/analyticsloader",
    component: <AnalyticsLoader />
  },
  {
    path: "/temp",
    element: <div></div>
  }

]

const protectedpublicRoutes = [
  {
    path: "/newpassward",
    component: <NewPassword />
  },
  {
    path: "/chanagesuccess",
    component: <ChanageSuccess />
  }

]

const routes = [
  {
    path: "/",
    component: <Navigate to="/user" />
  },
  {
    path: "/create",
    component: <CreateListing />, requiredRole: [1]
  },
  {
    path: "/edit/:id",
    component: <CreateListing />, requiredRole: [1]
  },
  {
    path: "/drafts",
    component: <DraftListings />,
    requiredRole: [1]
  },
  {
    path: "/published",
    component: <PublishedListings />, requiredRole: [1]
  },
  {
    path: "/pending",
    component: <PendingApprovals />, requiredRole: [1]
  },
  {
    path: "/rejected",
    component: <RejectedListings />,
    requiredRole: [1]
  },
  {
    path: "/view/:id",
    component: <DetailView />,
    requiredRole: [1]
  },
  {
    path: "/retirementByProjectType",
    component: <RetirementByProjectType />,
    requiredRole: [1]
  }
]

const adminRouts = [
  {
    path: "/user",
    component: <Users />,
    requiredRole: [1, 2]
  },
  {
    path: "/companies",
    component: <Companies />,
    requiredRole: [1]
  }
]

const styles = {
  container: {
    textAlign: "center",
    padding: "20%"
  },
  header: {
    fontSize: "2em",
    color: "#f44336"
  },
  text: {
    fontSize: "1.2em",
    marginTop: "20px"
  }
}

const NotFound = () => (
  <div style={styles.container}>
    <h1 style={styles.header}>404 - Page Not Found</h1>
    <p style={styles.text}>
      Sorry, the page you are looking for does not exist.
    </p>
    <p style={styles.text}>
      Go back to <Link to="/">the home page</Link>.
    </p>
  </div>
)

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType
  }))

  const Layout = getLayout(layoutType)

  const token = localStorage.getItem("authToken")

  let userRole = null

  if (token) {
    const decodedToken = jwtDecode(token)
    userRole = decodedToken.role_id
  }

  return (
    <React.Fragment>
      {/* <RouterProvider router={router} /> */}
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <Layout>{route.component}</Layout>
                  </ProtectedRoute>
                }
              />
            )
          })}
          {adminRouts.map((route, index) => {
            if (route.requiredRole && route.requiredRole.includes(userRole)) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute>
                      <Layout>{route.component}</Layout>
                    </ProtectedRoute>
                  }
                />
              )
            }
            return null
          })}
          {publicRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              />
            )
          })}
          {protectedpublicRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <NonAuthLayout>{route.component}</NonAuthLayout>
                  </ProtectedRoute>
                }
              />
            )
          })}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
