/* eslint-disable */
import _ from "lodash"
import { useEffect, useState } from "react"
import { InfinitySpin } from "react-loader-spinner"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { Modal } from "rsuite"
import PieChartPopUp from "../RetirementByProjectType/pieChartPopup"
import html2canvas from 'html2canvas';

export const PieChartTooltip = ({ active, payload, label }) => {
  const margins = {
    top: 30,
    right: 40,
    bottom: 30,
    left: 40,
  }

  const chartData = []
  payload.forEach(item => {
    chartData.push({
      name: item["name"],
      value: item["value"],
    })
  })

  if (active) {
    const total = payload.reduce((acc, entry) => acc + entry.value, 0);

    return (
      <div
        className="bg-white p-3  rounded"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
        }}
      >
        <div className="fw-bold d-flex align-items-center">
          <span style={{ fontSize: "15px", color: "#495057" }} className="ms-2">
            Pie Chart
          </span>
          <br />
        </div>
        <div>
          <span
            style={{ fontSize: "12px", color: "#74788D", top: "-50px" }}
            className="ms-2"
          >
            {/^\d{4}-\d{2}$/.test(label) ? (
              // Convert "YYYY-MM" to "YYYY MMM"
              (() => {
                const [year, month] = label.split('-');
                const monthName = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' });
                return `${year} ${monthName}`;
              })()
            ) : (
              label
            )}
          </span>
        </div>


        <PieChart width={350} height={200} margin={margins}>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            fill="red"
            label={entry => {
              const percentage = (entry.value / total) * 100;
              return percentage > 0 ? `${getCompactValue(entry.value)} (${percentage.toFixed(2)}%)` : '';
            }}
            labelLine={false} // stroke line remove
          >
            {payload.map((entry, index) => {
              return <Cell fill={entry.fill} key={index} />;
            })}
          </Pie>
        </PieChart>
      </div>
    );
  }
  return null;
};



// Function to get the chart/log value
export function getChartValue(value, isLog = false) {
  if (isLog) {
    value = Math.log(value)
    if (_.isFinite(value)) {
      return value
    }
    return 0
  }
  return value
}

export function getCompactValue(value) {
  if (value == 0) {
    return ""
  }
  return Intl.NumberFormat("en-US", { notation: "compact" }).format(value)
}

function StackedChart(props) {
  const data = props.chartData
  const loading = props.loading
  const error = props.error
  const dateFilter = props.dateFilter
  const startDate = props.startDate
  const isLog = props.isLog
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalXValue, setModalXValue] = useState(null)
  const [modalYValues, setModalYValues] = useState([])
  const [chartHeight, setChartHeight] = useState(450)
  const [chartBottomMargin, setChartBottomMargin] = useState(60)
  const [legendItems, setLegendItems] = useState({
    nature_based: true,
    energy: true,
    household: true,
    industrial: true,
    waste_management: true,
    transport: true,
    other: true,
  })


  const toggleLegendItem = dataKey => {
    setLegendItems(prevItems => ({
      ...prevItems,
      [dataKey]: !prevItems[dataKey],
    }))
  }

  useEffect(() => {
    const updateChartHeight = () => {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const newChartHeight = Math.round(screenHeight * 0.6);
      const newChartBottomMargin = Math.round(screenHeight * 0.1);
  
      setChartHeight(newChartHeight);
      setChartBottomMargin(newChartBottomMargin);
    };
    updateChartHeight();  
    window.addEventListener("resize", updateChartHeight);
    return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, []);

  const closeModal = () => {
    setModalXValue(null)
    setModalYValues([])
    setIsModalOpen(false)
  }

  const prepareDate = value => {
    if (dateFilter === 1 || dateFilter === null) {
      const date = new Date(value)
      const month = date.toLocaleString("default", { month: "short" })
      const year = date.getFullYear()
      return `${month} ${year}`
    } else {
      return value
    }
  }

  let xAxisTitle = ""
  if (dateFilter === 1) {
    xAxisTitle = "Retirement Date"
  }
  if (dateFilter === 2 ) {
    xAxisTitle = "Vintage Year"
  }

  const styles = {
    loadingContainer: {
      paddingTop: "10%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }

  const chartData = []
  _.get(data, "categories", []).forEach((item, index) => {
    chartData.push({
      name: item,
      nature_based: getChartValue(data["series"][0][index], isLog),
      energy: getChartValue(data["series"][1][index], isLog),
      household: getChartValue(data["series"][2][index], isLog),
      industrial: getChartValue(data["series"][3][index], isLog),
      waste_management: getChartValue(data["series"][4][index], isLog),
      transport: getChartValue(data["series"][5][index], isLog),
      other: getChartValue(data["series"][6][index], isLog),
    })
  })

  const totalTicks = 8;
  const interval = Math.max(1, Math.floor((chartData.length - 1) / (totalTicks - 1)));
  const xTicks = Array.from({ length: totalTicks }, (_, index) => Math.min(index * interval, chartData.length - 1));

  const handleDownload = (startValue, endValue) => {
    const chartContainer = document.getElementById('chart');
  
    html2canvas(chartContainer, { scale: 2 }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `stacked_chart_${startValue}to${endValue}.png`;
      link.click();
    });
  };
  
  return (
    <div>
      <div id="chart" style={{ minHeight: "30vh", overflow: "visible" }}>
        {loading ? (
          <div style={styles.loadingContainer}>
            <InfinitySpin width="200" height={chartHeight} color="#36d7b7" />
            <p className="pt-3">Loading data...</p>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : data && data.categories && data.categories.length > 0 ? (
          <ResponsiveContainer height={chartHeight} width={"100%"}>
           <button
              onClick={() => handleDownload(chartData[0].name, chartData[chartData.length - 1].name)}
              title="Download chart as PNG"
              style={{
                marginTop: '-40px',
                paddingRight: '20px',
                position: 'absolute',
                top: '0',
                right: '0',
                color: '#645057',
                background: 'none',
                border: 'none',
                cursor: 'pointer', 
                
              }}
            >
              Download &nbsp;<i className="fas fa-download"></i>
            </button>

            <BarChart
              data={chartData}
              margin={{
                top: 50,
                right: 10,
                left: 7,
                bottom: chartBottomMargin,
              }}
            >
              <CartesianGrid
                stroke="#F9F9F9"
                horizontal
                strokeWidth={1}
                vertical={false}
              />
              <XAxis
                axisLine={{ stroke: '#F1F7F7', strokeWidth: 3 }}
                label={{
                  value: xAxisTitle,
                  position: "insideBottom",
                  offset: -50,
                  style: {
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                  },
                }}
                angle={-45}
                textAnchor="end"
                dataKey="name"
                interval={0}
                tickFormatter={(value, index) => {
                  if ( chartData.length < 20 || xTicks.includes(index)) {
                    if (/^\d{4}-\d{2}$/.test(value)) {
                      const [year, month] = value.split('-');
                      const monthName = new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' });
                      return `${year} ${monthName}`;
                    } else {
                      return value;
                    }
                  } else {
                    return '';
                  }
                }}
              />

              <YAxis
                axisLine={{ stroke: "#F1F7F7", strokeWidth: 3 }}
                label={{
                  value: "Credits Retired",
                  angle: -90,
                  offset: -1,
                  position: "insideLeft",
                  style: {
                    color: "#495057",
                    fontSize: "15px",
                    fontWeight: 700,
                    paddingRight: "10px",
                  },
                }} // Adjust paddingRight for right padding
                tickFormatter={getCompactValue}
              />
              <Legend
                onClick={e => toggleLegendItem(e.dataKey)}
                verticalAlign="top"
                align="right"
                layout="vertical"
                wrapperStyle={{
                  paddingLeft: "50px",
                  paddingRight: "20px",
                  paddingTop: "0px",
                  lineHeight: "35px",
                  color: "#000000",
                }}
                iconType="square"
                formatter={(value, entry, index) => (
                  <span
                    style={{
                      marginLeft: "18px",
                      color: "#575757",
                      borderRadius: "10px",
                    }}
                  >
                    {value}
                  </span>
                )}
              />

              <Bar
                dataKey="nature_based"
                fill="#1D9D60"
                name="Nature Based"
                stackId="stack"
                hide={!legendItems.nature_based}
                stroke="#1D9D60"
                strokeWidth={1}
              />
              <Bar
                dataKey="energy"
                fill="#F8BA1D"
                name="Energy"
                stackId="stack"
                hide={!legendItems.energy}
                stroke="#F8BA1D"
                strokeWidth={1}
              />
              <Bar
                dataKey="household"
                fill="#19A1B4"
                name="Household"
                stackId="stack"
                hide={!legendItems.household}
                stroke="#19A1B4"
                strokeWidth={1}
              />
              <Bar
                dataKey="industrial"
                fill="#015F81"
                name="Industrial"
                stackId="stack"
                hide={!legendItems.industrial}
                stroke="#015F81"
                strokeWidth={1}
              />
              <Bar
                dataKey="waste_management"
                fill="#A6B0CF"
                name="Waste Management"
                stackId="stack"
                hide={!legendItems.waste_management}
                stroke="#A6B0CF"
                strokeWidth={1}
              />
              <Bar
                dataKey="transport"
                fill="#8DD98E"
                name="Transport"
                stackId="stack"
                hide={!legendItems.transport}
                stroke="#8DD98E"
                strokeWidth={1}
              />
              <Bar
                dataKey="other"
                fill="#7AA5BF"
                name="Other"
                stackId="stack"
                hide={!legendItems.other}
                stroke="#7AA5BF"
                strokeWidth={1}
              />
              <Tooltip cursor={false} content={<PieChartTooltip />} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p
            style={{ textAlign: "center", marginTop: "15vh", fontSize: "15px" }}
          >
            No data available!
          </p>
        )}
      </div>
      
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        size="sm"
        style={{ paddingTop: "5%" }}
      >
        <Modal.Header>
          <div className="fw-bold d-flex align-items-center">
            <span
              style={{ fontSize: "15px", color: "#495057" }}
              className="ms-2"
            >
              Pie Chart
            </span>
            <br />
          </div>
          <div>
            <span
              style={{ fontSize: "12px", color: "#74788D", top: "-50px" }}
              className="ms-2"
            >
              {prepareDate(modalXValue)}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="piechart-container">
          <PieChartPopUp xValue={modalXValue} yValues={modalYValues} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default StackedChart
