/* eslint-disable no-undef */
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import Logo_cs from "../../assets/images/CHLogoTitleDark.png"
import Logo_cs_sm from "../../assets/images/CHLogoTitleDark.png"

const Sidebar = props => {

  const token = localStorage.getItem("authToken")

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={process.env.REACT_APP_OTHER_SERVICE_URL + "/home?token=" + token} className="logo logo-dark">
            <span className="logo-sm">
              <img src={Logo_cs_sm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={Logo_cs} alt="" height="45" />
            </span>
          </Link>
          <Link to={process.env.REACT_APP_OTHER_SERVICE_URL + "/home?token=" + token}  className="logo logo-light">
            <span className="logo-sm">
              <img src={Logo_cs_sm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={Logo_cs} alt="" height="45" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
