import { validate } from "components/Common/ValidationIndicator";
import ValidationLabel from "components/Common/ValidationLabel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, FormFeedback, Input, Row, Table } from "reactstrap";
import { updateWizardData } from "rtk-store/slices/wizardSlice";
import React from 'react'

const emptyItem = {
  "proponentName": "",
  "contactPersonName": "",
  "email": "",
  "country": null,
  "phone": "",
  "state_province": "",
  "region_location": null
}

export default function ContactDetailsTable() {
  const dropdownValues = useSelector(state => state.wizard.dropdownData)
  const wizardData = useSelector(state => state.wizard.wizardData)
  const [data, setData] = useState([
    emptyItem
  ])
  const handleAddContact = () => {
    setData([...data, emptyItem])
  }

  const handleDelete = (index) => {
    let newData = [...data]
    newData.splice(index, 1)
    setData(newData)
  }

  const handleClearData = () => {
    setData([emptyItem])
  }

  const handleItemDetailChange = (index, key, value) => {
    let item = { ...data[index] }
    item[key] = value
    const newData = [...data]
    newData[index] = item
    setData(newData)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateWizardData({ 'contact_details': data }))
  }, [data])

  useEffect(() => {
    setData(wizardData.contact_details?.length ? wizardData.contact_details : [{
      "proponentName": "",
      "contactPersonName": "",
      "email": "",
      "country": null,
      "phone": "",
      "state_province": "",
      "region_location": null
    }])
  }, [wizardData.contact_details])

  const isValidPhoneNumber = (number) => {
    const regex = /^[+0-9()\-\s]+$/;  // Updated regex to include (, ), -, and space
    let validates = regex.test(number);

    if (validates) {
      const cleanedNumber = number.replace(/[()+\-\s]/g, ''); // Remove special characters and spaces
      if (isNaN(parseInt(cleanedNumber))) {
        console.log('nan');
        validates = false;
      }
    }

    return validates;
  };



  const showValidation = useSelector(state => state.wizard.showValidation)
  return <Row>
    <Col>
      <Table style={{ borderCollapse: "collapse" }} className="table-sm">
        <thead>
          <tr style={{ borderBottom: "1px solid #ccc", fontSize: '0.rem' }}>
            <th className="fw-normal">#</th>
            <th className="fw-normal">Proponent Name*</th>
            <th className="fw-normal">Contact Person's Name*</th>
            <th className="fw-normal">Email</th>
            <th className="fw-normal">Phone Number</th>
            <th className="fw-normal">Country*</th>
            <th className="fw-normal">State or Province</th>
            <th className="fw-normal">Region/Location</th>
            <th className="fw-normal text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => {
            return <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
              <td style={{ verticalAlign: 'middle' }}>
                {index + 1}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  type="text"
                  placeholder="Enter Name of Entity"
                  value={dataItem.proponentName ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'proponentName', e.target.value)
                  }}
                  invalid={showValidation && validate(dataItem, 'proponentName')}
                />
                {showValidation && validate(dataItem, 'proponentName') && <FormFeedback>Required</FormFeedback>}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  value={dataItem.contactPersonName ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'contactPersonName', e.target.value)
                  }}
                  invalid={showValidation && validate(dataItem, 'contactPersonName')}
                />
                {showValidation && validate(dataItem, 'contactPersonName') && <FormFeedback>Required</FormFeedback>}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  type="email"
                  placeholder="Enter Email"
                  value={dataItem.email ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'email', e.target.value)
                  }}
                  // invalid={dataItem?.email?.length && validate(dataItem, 'email', 'email')}
                />
                {/* {validate(dataItem, 'email', 'email') && showValidation ? <FormFeedback>Required/Invalid email</FormFeedback> : null} */}
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Input
                  inputMode="tel"
                  placeholder="Enter Phone Number"
                  value={dataItem.phone ?? ''}
                  onChange={e => {
                    const input = e.target.value.toString()
                    if (isValidPhoneNumber(input) || input == '' || input == '+')
                      handleItemDetailChange(index, 'phone', input)
                  }}
                />
              </td>
              <td style={{ verticalAlign: 'top' }} width='15%'>
                <Select
                  classNamePrefix="select"
                  isSearchable
                  value={dropdownValues['host_coun'].find(item => item.id == dataItem?.country) ?? null}
                  options={dropdownValues['host_coun']}
                  placeholder="Select Country"
                  onChange={e => {
                    handleItemDetailChange(index, 'country', e?.id)
                  }}
                  isClearable
                />
                {showValidation && validate(dataItem, 'country') && <ValidationLabel text={'Required'} />}
              </td>
              <td style={{ verticalAlign: 'top' }} width='10%'>
                <Input
                  type="text"
                  placeholder="Enter the State/Province"
                  value={dataItem.state_province ?? ''}
                  onChange={e => {
                    handleItemDetailChange(index, 'state_province', e.target.value)
                  }}
                />
              </td>
              <td style={{ verticalAlign: 'top' }} width='10%'>
                <Select
                  classNamePrefix="select"
                  isSearchable
                  isClearable
                  options={dropdownValues['regi']}
                  placeholder="Region"
                  value={dropdownValues['regi'].find(item => item.id == dataItem.region_location) ?? null}
                  onChange={e => {
                    handleItemDetailChange(index, 'region_location', e.id)
                  }}
                />
              </td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {data.length && data.length === 1 ? (
                  <Button onClick={handleClearData} color="white" size="sm">
                    <i className="dripicons-clockwise text-danger fs-4"></i>
                  </Button>
                  // <a
                  //   onClick={handleClearData}
                  //   className="btn btn-link btn-sm text-danger fs-5"
                  //   title="Clear your data"
                  // >
                  //   <i className="dripicons-clockwise"></i>
                  // </a>
                ) : (
                  // <a
                  //   onClick={(e) => {
                  //     e.preventDefault()
                  //     handleDelete(index)
                  //   }}
                  //   className="btn btn-link btn-sm fs-5 text-danger"
                  // >
                  //   <i className="bx bx-trash"></i>
                  // </a>
                  <Button onClick={() => {
                    handleDelete(index)
                  }} color="white" size="sm">
                    <i className="bx bx-trash text-danger fs-4"></i>
                  </Button>
                )}
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      <Row>
        <Col className="text-end">
          <Button disabled={wizardData.number_of_proponents == 828} color="primary" style={{ backgroundColor: "#8796C7", borderColor: "#8796C7" }} onClick={handleAddContact}>
            Add New
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
}