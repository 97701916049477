import withRouter from 'components/Common/withRouter'
import React, { useEffect } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Loader } from 'rsuite'
const LoadingPage = (props) => {
    const [params] = useSearchParams()
    const token = params.get('token')
    useEffect(() => {
        if (token) {
            localStorage.setItem("authToken", token);
            props.router.navigate("/");
        }
        else {
            props.router.navigate('/login')
        }
    }, [
        token
    ])
    if (localStorage.getItem("authToken") != null) {
        return <Navigate to="/" />
    }
    return <React.Fragment>
        <div className="container">
            <div className='row'>
                <div className='col'>
                    <div className='d-flex min-vh-100 align-items-center justify-content-center'>
                        <div className='d-flex align-items-center'>
                            <Loader color='green' />
                            <span className='ms-2'>Please wait</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}


export default withRouter(LoadingPage)

LoadingPage.propTypes = {
    history: propTypes.object,
}