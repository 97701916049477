import { createSlice } from '@reduxjs/toolkit';
import { getDraftProjects } from 'components/core/services/getDraftProjectsApi';



// Slice with the necessary actions and reducer
const draftsSlice = createSlice({
    name: 'drafts',
    initialState: {
        projects: [],
        loading: false,
        error: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: {
        [getDraftProjects.fulfilled]: (state, action) => {
            state.projects = action.payload
            state.loading = false
        },
        [getDraftProjects.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getDraftProjects.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get draft projects ('+action.error.message+')'
        },
    },
});

export default draftsSlice.reducer;
