import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
//Dashboard
// import authReducer from 'rtk-store/slices/authSlice';
import draftsReducer from "rtk-store/slices/draftsSlice"
import retirementsReducer from "rtk-store/slices/retirementByProjectTypeSlice"
import detailsReducer from "rtk-store/slices/projectDetailsSlice"
import publishedReducer from "rtk-store/slices/publishedSlice"
import wizardReducer from "rtk-store/slices/wizardSlice"
import userReducer from "rtk-store/slices/userSlice"
import authReducer from "rtk-store/slices/authSlice"
import companiesReducer from "rtk-store/slices/companiesSlice"


const rootReducer = combineReducers({
  Layout,
  auth: authReducer,
  wizard: wizardReducer,
  drafts: draftsReducer,
  retirements: retirementsReducer,
  published: publishedReducer,
  projectDetails: detailsReducer,
  users: userReducer,
  companies: companiesReducer
})

export default rootReducer
