import React, { useEffect, useState } from "react"
import { CircleFlag } from "react-circle-flags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import ApprovalCertification from "components/PublishListing/ApprovalCertification"
import CreditInformation from "components/PublishListing/CreditInformation"
import Documents from "components/PublishListing/Documents"
import GeneralInformation from "components/PublishListing/GeneralInformation"
import IssuanceHistory from "components/PublishListing/IssuanceHistory"
import ProjectInformation from "components/PublishListing/ProjectInformation"
import ProjectLinks from "components/PublishListing/ProjectLinks"
import ProponentInformation from "components/PublishListing/ProponentInformation"
import { getProjectDetailsByID } from "components/core/services/getProjectDetailsApi"
import { useParams } from "react-router-dom"

import RegistryLabel from "components/DetailsView/RegistryLabel"
import { icon } from "leaflet"
import _ from "lodash"
import { Image } from "react-bootstrap"
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from "react-leaflet"

import "leaflet/dist/leaflet.css"

function DetailsView() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const projectDetails = useSelector(state => state.projectDetails.details)
  // Map configuration
  const data = projectDetails?.headerData?.coordinates
  const [mapData, setMapData] = useState([])
  const [firstMapLoc, setFirstMapLoc] = useState()
  useEffect(() => {
    if (
      data &&
      data.length &&
      data[0].latitude !== null &&
      data[0].longitude !== null
    ) {
      setMapData(data)
      setFirstMapLoc({
        latitude: parseFloat(data[0].latitude),
        longitude: parseFloat(data[0].longitude),
      })
    } else {
      setMapData(data)
      setFirstMapLoc({
        latitude: parseFloat("0"),
        longitude: parseFloat("0"),
      })
    }
  }, [data])

  const sectoralScopes = [
    {
      id: 2,
      label: "Nature Based",
      img: (
        <h3>
          <i
            className="fas fa-tree"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Nature Based"
          ></i>
        </h3>
      ),
    },
    {
      id: 3,
      label: "Energy",
      img: (
        <h3>
          <i
            className="fas fa-cloud-sun-rain"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Energy"
          ></i>
        </h3>
      ),
    },
    {
      id: 4,
      label: "Household",
      img: (
        <h3>
          <i
            className="fas fa-home"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Household"
          ></i>
        </h3>
      ),
    },
    {
      id: 5,
      label: "Industrial",
      img: (
        <h3>
          <i
            className="fas fa-industry"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Industrial"
          ></i>
        </h3>
      ),
    },
    {
      id: 6,
      label: "Waste Management",
      img: (
        <h3>
          <i
            className="fas fa-trash-restore"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Waste Management"
          ></i>
        </h3>
      ),
    },
    {
      id: 7,
      label: "Transport",
      img: (
        <h3>
          <i
            className="fas fa-train"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Transport"
          ></i>
        </h3>
      ),
    },
    {
      id: 8,
      label: "Other",
      img: (
        <h3>
          <i
            className="fas fa-poll-h tab-icon-font"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Other"
          ></i>
        </h3>
      ),
    },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetailsByID(id))
    }
  }, [id])

  const [showLocations, setShowLocations] = useState(false)

  let sectoralScopeIcons = []

  if (projectDetails?.headerData?.projectSectoralScopes_ids) {
    sectoralScopeIcons = projectDetails?.headerData?.projectSectoralScopes_ids
      .split(",")
      ?.map(id => parseInt(id))
  }
  const [center, setCenter] = useState([0, 0])
  const ViewHandler = ({ center }) => {
    const map = useMap()
    map.setView(center, 3)
    return null
  }
  useEffect(() => {
    if (mapData?.length) {
      const item = mapData[mapData.length - 1]
      setCenter([item.latitude, item.longitude])
    }
  }, [mapData])
  return (
    <React.Fragment>
      <Container fluid className="page-content">
        <Card className="card-view border-0" style={{ background: "#FCFCFC" }}>
          <CardBody>
            <div className="p-0 p-md-2">
              <Row>
                <Col className="md-6 ms-12">
                  <div
                    className="mb-3 fw-bold"
                    style={{ textAlign: "justify", fontSize: "28px" }}
                  >
                    {projectDetails?.headerData?.project_title ?? "N/A"}
                  </div>
                  <div className="mb-3 fw-bold">
                    <Row className="align-items-center">
                      <Col
                        className="text-start md-4"
                        style={{ minWidth: "80px", paddingTop: "10px" }}
                      >
                        <span>
                          <div className="d-flex align-items-center">
                            {sectoralScopeIcons.slice(0, 3).map(icon => {
                              const scope = sectoralScopes.find(
                                item => item.id === icon
                              )
                              return (
                                <React.Fragment key={icon}>
                                  {scope && (
                                    <div className="d-flex align-items-center">
                                      {sectoralScopeIcons.length === 1 && (
                                        <span
                                          className="me-2 fw-bold d-flex"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {scope.img}&nbsp;
                                          <span
                                            className="ps-1"
                                            style={{
                                              fontSize: 16,
                                              color: "#495057",
                                              fontWeight: "bold",
                                              lineHeight: "1rem",
                                              maxHeight: "1rem",
                                              textAlign: "left",
                                              paddingTop: "5px",
                                            }}
                                          >
                                            {scope.label}
                                          </span>
                                        </span>
                                      )}
                                      {sectoralScopeIcons.length < 3 &&
                                        sectoralScopeIcons.length > 1 && (
                                          <span
                                            className="me-2 fw-bold"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {scope.img}
                                          </span>
                                        )}
                                      {sectoralScopeIcons.length >= 3 && (
                                        <span
                                          className="me-2 fw-bold"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {scope.img}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            })}
                            {sectoralScopeIcons.length > 3 && (
                              <div style={{ marginLeft: "-5px" }}>
                                <span
                                  className="me-4 fw-bold"
                                  style={{ fontSize: "16px" }}
                                >
                                  <sup>+{sectoralScopeIcons.length - 3}</sup>
                                </span>
                              </div>
                            )}
                          </div>
                        </span>
                      </Col>

                      <Col className="text-start text-md-center align-items-center md-4">
                        <div className="d-flex align-items-center">
                          {projectDetails?.headerData?.countryEmojis?.code ? (
                            <CircleFlag
                              className="me-2"
                              countryCode={projectDetails?.headerData?.countryEmojis?.code?.toLowerCase()}
                              height="25"
                            />
                          ) : null}
                          <span
                            className="ps-1"
                            style={{
                              fontSize: 16,
                              color: "#495057",
                              fontWeight: "bold",
                              lineHeight: "1rem",
                              maxHeight: "1rem",
                              textAlign: "left",
                            }}
                          >
                            {projectDetails?.headerData?.country ?? "N/A"}
                          </span>
                        </div>
                      </Col>
                      <Col className="text-start text-md-end md-4">
                        <RegistryLabel
                          registry={projectDetails?.headerData?.registry}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="mt-5 "
                    style={{ textAlign: "justify", fontSize: "16px" }}
                  >
                    {projectDetails?.headerData?.projectDescription}
                  </div>
                </Col>
                <Col className="md-6 ms-12">
                  {/* Leafflet maps */}
                  {mapData && firstMapLoc ? (
                    <MapContainer
                      center={center}
                      style={{ height: 400 }}
                      zoom={10}
                    >
                      <TileLayer
                        attribution="Google Maps Satellite"
                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                      />
                      <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                      {data &&
                        data.length &&
                        data[0].latitude !== null &&
                        data[0].longitude !== null &&
                        _.defaultTo(mapData, []).map(item => {
                          return (
                            <Marker
                              icon={icon({ iconUrl: "/map-icon.svg" })}
                              position={[item?.latitude, item?.longitude]}
                              key={`${item?.latitude}-${item?.longitude}}`}
                            >
                              <Tooltip
                                className="rounded rounded-4 shadow shadow-lg border px-3 bg-white"
                                permanent
                                direction="top"
                              >
                                <div
                                  className="fs-5 fw-bold"
                                  style={{ fontFamily: "Poppins" }}
                                >
                                  {item.countryName}
                                </div>
                                <div
                                  className="text-muted"
                                  style={{ fontFamily: "Poppins" }}
                                >
                                  {item.state_province}
                                </div>
                                <small
                                  className="me-2 text-secondary"
                                  style={{ fontFamily: "Poppins" }}
                                >
                                  {item.latitude}° N
                                </small>
                                <small
                                  className="text-secondary"
                                  style={{ fontFamily: "Poppins" }}
                                >
                                  {item.longitude}° E
                                </small>
                              </Tooltip>
                              <ViewHandler center={center} />
                            </Marker>
                          )
                        })}
                    </MapContainer>
                  ) : (
                    <div className="p-3">Loading maps…</div>
                  )}
                  <Row className="mt-2 d-flex align-items-center">
                    <Col>
                      <div
                        className="d-flex align-items-center"
                        style={{ color: "#A1A1A1" }}
                      >
                        <Image
                          src="/icons/area-icon.png"
                          width={18}
                          height={18}
                          style={{ marginRight: "16px" }}
                        />
                        <span
                          className="fw-bold"
                          style={{ color: "#A1A1A1", fontSize: "16px" }}
                        >
                          {projectDetails?.headerData?.projectArea ?? "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="d-flex align-items-center"
                        style={{ color: "#A1A1A1", fontSize: "16px" }}
                      >
                        <Image
                          src="/icons/locations-icon.svg"
                          width={18}
                          height={18}
                          style={{ marginRight: "16px" }}
                        />
                        <span className="fw-bold">Locations</span>
                        <a
                          className="btn btn-link"
                          style={{ color: "#a1a1a1", fontSize: "16px" }}
                          onClick={() => {
                            setShowLocations(!showLocations)
                          }}
                        >
                          <i
                            className={
                              showLocations
                                ? "mdi mdi-chevron-up"
                                : "mdi mdi-chevron-down"
                            }
                            style={{ fontSize: "22px" }}
                          ></i>
                        </a>
                      </div>
                      <div className="d-relative">
                        {showLocations && (
                          <div
                            className="bg-white rounded shadow shadow-lg p-2 d-flex flex-column"
                            style={{
                              position: "absolute",
                              zIndex: 100,
                              color: "#ADADAD",
                              fontSize: "16px",
                              justifyItems: "center",
                              minWidth: "320px",
                              right: 0,
                            }}
                          >
                            <ul>
                              {projectDetails?.headerData?.coordinates?.map(
                                (item, index) => {
                                  let cls = "mb-2"
                                  if (index === 0) {
                                    cls += " fw-bold"
                                  }
                                  return (
                                    <li className={cls} key={item.id}>
                                      {item.countryName}, {item.state_province},{" "}
                                      {item.latitude}&deg;N, {item.longitude}
                                      &deg;E
                                    </li>
                                  )
                                }
                              )}
                            </ul>

                            <a
                              className="btn btn-text btn-sm mt-2"
                              // eslint-disable-next-line no-unused-vars
                              onClick={e => {
                                setShowLocations(false)
                              }}
                            >
                              Close
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Card
                className="card-view border-0"
                style={{
                  borderRadius: "45px",
                  background: "white",
                  boxShadow: "0px 3px 19px -5px rgba(0, 0, 0, 0.24)",
                }}
              >
                <CardBody>
                  <CreditInformation />
                </CardBody>
              </Card>

              <Card className="card-view">
                <CardBody>
                  <ProjectInformation />
                </CardBody>
              </Card>

              <Card className="card-view">
                <CardBody>
                  <GeneralInformation />
                </CardBody>
              </Card>

              <Card className="card-view">
                <CardBody>
                  <ApprovalCertification />
                </CardBody>
              </Card>

              <Card className="card-view">
                <CardBody>
                  <ProponentInformation />
                </CardBody>
              </Card>

              <Card className="card-view">
                <CardBody>
                  <ProjectLinks />
                </CardBody>
              </Card>

              <Card className="card-view1">
                <CardBody>
                  <IssuanceHistory />
                </CardBody>
              </Card>

              <Card className="card-view1">
                <CardBody>
                  <Documents />
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default DetailsView
