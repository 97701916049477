/* eslint-disable */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import  {CopyToClipboard}  from 'react-copy-to-clipboard';
import { AvField, AvForm } from "availity-reactstrap-validation"
import { getCompaniesList } from "components/core/services/getCompanies"
import { getUserRoles, getUsersByID } from "components/core/services/getUsers"
import { userForgetPassword } from "components/core/services/postUsers"
import jwtDecode from "jwt-decode"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FaCopy } from "react-icons/fa"
import Select from "react-select"
import { Alert, Button, Col, Label, Row } from "reactstrap"
import Swal from "sweetalert2"
import "./modal.css"
import {
  isValidEmail,
  isValidPhoneNumber,
} from "components/Common/ValidationIndicator"

export default function UserModal({ closeModal, userId, onSave }) {
  const token = localStorage.getItem("authToken")
  let currentUserRole = 0
  let currentCompanyId = 0
  if (token != null) {
    const decodedToken = jwtDecode(token)
    currentUserRole = decodedToken.role_id // Assuming the role ID is stored in the token.
    currentCompanyId = decodedToken.companyId
  }
  const dispatch = useDispatch()
  const [fullName, setFullName] = useState()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState()
  const [isActive, setIsActive] = useState()
  const [generatedPassword, setGeneratedPassword] = useState() // eslint-disable-line no-unused-vars
  const [role, setRole] = useState(currentUserRole)
  const [company, setCompany] = useState(currentCompanyId)
  const userRoles = useSelector(state => state.users.rols)
  const data = useSelector(state => state.wizard.wizardData)
  const [loading, setLoading] = useState(false)
  const companyList = useSelector(state => state.companies.companies)
  const mappedCompanyData = companyList
    ?.filter(item => {
      return item.isActive
    })
    .map(item => ({
      id: item.id,
      label: item.companyName,
      value: item.id
    }))

  useEffect(() => {
    dispatch(getUserRoles())
    if (userId) {
      setLoading(true)
      dispatch(getUsersByID(userId))
        .unwrap()
        .then(userData => {
          setFullName(userData?.fullName)
          setEmail(userData?.email)
          setCompany(userData?.companyId)
          setPhoneNumber(userData?.phoneNumber)
          setRole(userData?.role_id)
          setIsActive(userData?.isActive)
        })
        .catch(error => {
          Swal.fire({
            title: "Oops!",
            text: `Unable to load user details! (${error.message})`,
            icon: "error",
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    dispatch(getCompaniesList())
    generateRandomPassword()
  }, [])
  const handleCopyToClipboard = () => {
    if (generatedPassword) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The password has been copied to the clipboard.",
        timer: 1500,
        toast: true,
        showConfirmButton: false,
        position: "top-end",
      })
    }
  }
  const generateRandomPassword = () => {
    const length = 15
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let password = ""
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }
    setGeneratedPassword(password)
  }

  const forgetPassword = async () => {
    try {
      const payload = {
        email: email,
      }
      const response = await dispatch(userForgetPassword(payload))
      if (response.payload.message === "Email sent successfully.") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.payload.message,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        throw new Error(response.payload ?? "Error!")
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  const tempUserRoles = userRoles.filter(item => {
    if (currentUserRole === 1) {
      return true
    } else {
      return item.id !== 1
    }
  })
  const filteredUserRoles = tempUserRoles.map(item => {
    return {
      label: item.label,
      value: item.id,
      id: item.id,
    }
  })
  const handleSubmit = async () => {
    try {
      const payload = {
        fullName: fullName,
        role_id: role,
        companyId: company,
        email: email,
        phoneNumber: phoneNumber,
      }
      if (userId) {
        payload["id"] = userId
      } else {
        payload["password"] = generatedPassword
      }
      if (currentUserRole !== 1) {
        payload["companyId"] = currentCompanyId
      }
      onSave(payload)
    } catch (error) {
      Swal.fire({
        title: "Oops!",
        text: `Something went wrong! (${error.message})`,
        icon: "error",
      })
    }
  }
  const regex = /^\+?\d{10,}$/
  return (
    data && (
      <React.Fragment>
        {loading ? (
          <Row>
            <Col>
              <Alert color="light">Loading…</Alert>
            </Col>
          </Row>
        ) : null}
        <AvForm className="form-horizontal p-4" disabled={loading}>
          <Row>
            <Col md="5" className="m-0 p-0">
              <Label className="mt-2">Full Name</Label>
            </Col>
            <Col md="7">
              <AvField
                className="form-input"
                name="fullName"
                placeholder="Enter your full name"
                onChange={e => setFullName(e.target.value)}
                value={fullName}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md="5" className="m-0 p-0">
              <Label className="mt-2">Email</Label>
            </Col>
            <Col md="7">
              <AvField
                name="email"
                className="form-input"
                placeholder={userId ? "" : "Enter your email"}
                value={email}
                disabled={userId}
                style={
                  userId
                    ? {
                      border: 0,
                      backgroundColor: "#fff",
                      paddingLeft: 0,
                      // margin: 0,
                    }
                    : {}
                }
                onChange={e => setEmail(e.target.value)}
                type="email"
                required
              />
            </Col>
          </Row>
          {currentUserRole === 1 ? (
            <Row>
              <Col md="5" className="m-0 p-0">
                <Label className="mt-2">Organization Name</Label>
              </Col>
              <Col md="7">
              <Select
                classNamePrefix="select"
                isSearchable
                options={mappedCompanyData}
                placeholder="Select organization"
                isClearable
                required
                onChange={selectedOption => setCompany(selectedOption?.id)}
                value={mappedCompanyData.find(obj => obj.id === company)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#7aa5bf' : state.isFocused ? '#yourHoverColor' : '#fff',
                  }),
                }}
              />
              </Col>
            </Row>
          ) : ""}
          {currentUserRole === 2 ? (
            <Row>
              <Col md="5" className="m-0 p-0">
                <Label className="mt-2">Organization Name</Label>
              </Col>
              <Col md="7">
              <Select
                classNamePrefix="select"
                isSearchable
                options={mappedCompanyData}
                placeholder="Select organization"
                isClearable
                required
                // onChange={selectedOption => setCompany(selectedOption?.id)}
                isDisabled
                value={mappedCompanyData.find(obj => obj.id === currentCompanyId)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#7aa5bf' : state.isFocused ? '#yourHoverColor' : '#fff',
                  }),
                }}
              />
              </Col>
            </Row>
          ) : null}
          <Row className="mt-3">
            <Col md="5" className="m-0 p-0">
              <Label className="mt-2">Phone Number</Label>
            </Col>
            <Col md="7" className="mt-0">
              <AvField
                name="phoneNumber"
                type="tel"
                placeholder="Enter phone number"
                onChange={e => {
                  setPhoneNumber(e.target.value)
                }}
                value={phoneNumber}
                className="form-input"
                validate={{ tel: { pattern: regex } }}
              />
            </Col>
          </Row>
          {!userId && (
            <Row>
              <Col md="5" className="m-0 p-0">
                <Label className="pt-2">Password</Label>
              </Col>
              <Col md="7">
                <div className="position-relative">
                  <AvField
                    className="form-input"
                    name="password"
                    placeholder=""
                    value={generatedPassword}
                    required
                    readOnly
                  />
                  <CopyToClipboard
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 8,
                      cursor: "pointer",
                    }}
                    text={generatedPassword}
                    onCopy={handleCopyToClipboard}
                  >
                    <FaCopy size={16} />
                  </CopyToClipboard>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="5" className="m-0 p-0">
              <Label className="mt-2">Role</Label>
            </Col>
            <Col md="7">
              <Select
                classNamePrefix="select"
                isSearchable
                options={filteredUserRoles}
                placeholder="Select role"
                isClearable
                required
                onChange={selectedOption => setRole(selectedOption?.id)}
                // value={userRoles.find(obj => `${obj.id}` === `${role}`)}
                value = {filteredUserRoles.find(obj => obj.id === role)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#7aa5bf' : state.isFocused ? '#yourHoverColor' : '#fff',
                  }),
                }}
              />
            </Col>
          </Row>
          {userId && (
            <Row className="mt-3">
              <Col md="5" className="m-0 p-0">
                <Label className="mt-2">Password Reset</Label>
              </Col>
              <Col md="7">
                <Button
                  disabled={!isActive}
                  onClick={forgetPassword}
                  color="link"
                  className="px-0"
                >
                  Send password reset link
                </Button>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col className="text-end">
              <Button
                onClick={handleSubmit}
                type="submit"
                className="btn mt-2"
                color="dark"
                disabled={
                  !isValidEmail(email) ||
                  (currentUserRole !== 1 && !company) ||
                  !role ||
                  !fullName ||
                  !isValidPhoneNumber(phoneNumber) ||
                  !company
                }
                style={{
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  backgroundColor: "#7AA5BF",
                  border: "2px solid #7AA5BF",
                }}
              >
                {userId ? "Save" : "Add"}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </React.Fragment>
    )
  )
}
