import { createSlice } from "@reduxjs/toolkit"
import { getUserRoles, getUsersList, } from "../../components/core/services/getUsers"
import { postCreateUser } from "../../components/core/services/postUsers"

// Slice with the necessary actions and reducer
const userSlice = createSlice({
  name: "users",
  initialState: {
    rols: [],
    users: [],
    user: null,
    loading: false,
    error: null
  },
  reducers: {
    toggleUserState: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            isActive: action.payload.isActive
          }
        }
        return item
      })
    }
  },
  extraReducers: {
    [getUsersList.fulfilled]: (state, action) => {
      if (action.payload.users) {
        state.users = action.payload.users
        state.loading = false
      } else {
        state.loading = false
        state.users = action.payload
      }
      state.loading = false
    },
    [getUsersList.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getUsersList.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user list (" + action.error.message + ")"
    },
    [postCreateUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [postCreateUser.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
    [postCreateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUserRoles.fulfilled]: (state, action) => {
      state.rols = action.payload
      state.loading = false
    },
    [getUserRoles.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getUserRoles.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user rols (" + action.error.message ?? "Error!" + ")"
    },
    // [postUserActivation.pending]: (state) => {
    //   state.loading = true
    //   state.error = false
    // },
    // [postUserActivation.rejected]: (state, action) => {
    //   state.error = action.error.message
    //   state.loading = false
    // },
    // [postUserActivation.fulfilled]: (state) => {
    //   state.loading = false
    //   state.error = undefined
    // }
  }
})

export const {
  toggleUserState
} = userSlice.actions

export default userSlice.reducer
