import React, { useState } from "react"
import { Col, Container, Row, Table } from "reactstrap"
import { useSelector } from "react-redux"
import CardSectionTitle from "../DetailsView/CardSectionTitle"
import TwoLineItem from '../DetailsView/TwoLineItem'


function ProponentInformation() {
  const [sortColumn, setSortColumn] = useState("")
  const [sortOrders, setSortOrders] = useState({
    proponentname: "asc",
    contactPerson: "asc",
    email: "asc",
    phonenumber: "asc",
    regionlocation: "asc",
    stateprovince: "asc",
    country: "asc"
  });


  const proponentDetails = useSelector(
    state => state.projectDetails.details.proponentDetails
  )

  const data =
    proponentDetails && proponentDetails.contact_details
      ? proponentDetails.contact_details
      : null

  const handleSort = column => {
    if (column === sortColumn) {
      setSortOrders({
        ...sortOrders,
        [column]: sortOrders[column] === "asc" ? "desc" : "asc"
      });
    } else {
      setSortColumn(column);
      setSortOrders({ ...sortOrders, [column]: "asc" });
    }
  };

  const sortedData = data
    ? [...data].sort((a, b) => {
      const sortOrder = sortOrders[sortColumn];
      if (sortColumn === "proponentname") {
        return sortOrder === "asc"
          ? a.proponentName.localeCompare(b.proponentName)
          : b.proponentName.localeCompare(a.proponentName);
      } else if (sortColumn === "contactPerson") {
        return sortOrder === "asc"
          ? a.contactPersonName.localeCompare(b.contactPersonName)
          : b.contactPersonName.localeCompare(a.contactPersonName);
      } else if (sortColumn === "email") {
        return sortOrder === "asc" ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
      } else if (sortColumn === "phonenumber") {
        return sortOrder === "asc" ? a.phone.localeCompare(b.phone) : b.phone.localeCompare(a.phone);
      } else if (sortColumn === "regionlocation") {
        return sortOrder === "asc"
          ? a.region_location.localeCompare(b.region_location)
          : b.region_location.localeCompare(a.region_location);
      } else if (sortColumn === "stateprovince") {
        return sortOrder === "asc"
          ? a.state_province.localeCompare(b.state_province)
          : b.state_province.localeCompare(a.state_province);
      } else if (sortColumn === "country") {
        return sortOrder === "asc" ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country);
      }
      return 0;
    })
    : [];

  return (
    <React.Fragment>
      <Container fluid>
        <CardSectionTitle
          title="Proponent Information"
          icon={<img src="/icons/proponent.svg" />}
        />
        <Table className="data-table" responsive>
          <thead  >
          <tr style={{ borderBottom: "2px solid #DEDCDE", fontSize: '0.rem' }}>
              <th onClick={() => handleSort("proponentname")} className="ash-text">
                Proponent Name{" "}
                {sortColumn === "proponentname" &&
                  sortOrders.proponentname === "asc" ? "▲" : "▼"
                }
              </th>
              <th onClick={() => handleSort("contactPerson")} className="ash-text">
                Contact Person
                {sortColumn === "contactPerson" &&
                  sortOrders.contactPerson === "asc" ? "▲" : "▼"
                }
              </th>
              <th onClick={() => handleSort("email")} className="ash-text">
                E-mail
                {sortColumn === "email" &&
                  sortOrders.email === "asc" ? "▲" : "▼"}

              </th>
              <th onClick={() => handleSort("phonenumber")} className="ash-text">
                Phone Number
                {sortColumn === "phonenumber" &&
                  sortOrders.phonenumber === "asc" ? "▲" : "▼"
                }
              </th>
              <th onClick={() => handleSort("regionlocation")} className="ash-text">
                Region/Location
                {sortColumn === "regionlocation" && sortOrders.regionlocation === "asc" ? "▲" : "▼"
                }
              </th>
              <th onClick={() => handleSort("stateprovince")} className="ash-text">
                State/Province
                {sortColumn === "stateprovince" &&
                  sortOrders.stateprovince === "asc" ? "▲" : "▼"
                }
              </th>
              <th onClick={() => handleSort("country")} className="ash-text">
                Country
                {sortColumn === "country" && sortOrders.country === "asc" ? "▲" : "▼"
                }
              </th>
            </tr>
          </thead>
          <tbody >
            {sortedData ? (
              sortedData.map((row, index) => (
                <tr key={index} >
                  <td className="black-text" >{row.proponentName || "--"}</td>
                  <td className="black-text">{row.contactPersonName || "--"}</td>
                  <td className="black-text">{row.email || "--"}</td>
                  <td className="black-text">{row.phone || "--"}</td>
                  <td className="black-text">{row.region_location || "--"}</td>
                  <td className="black-text">{row.state_province || "--"}</td>
                  <td className="black-text">{row.country || "--"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">--</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Row className="mt-5">
          <Col md={4}>
            <TwoLineItem title="Project Developer" body={proponentDetails && proponentDetails.project_developer
              ? proponentDetails.project_developer
              : "--"} />
          </Col>
          <Col md={4}>
            <TwoLineItem title="Project Verifier/Validator" body={proponentDetails && proponentDetails.project_verifier_validator
              ? proponentDetails.project_verifier_validator
              : "--"} />
          </Col>
          <Col md={4}>
            <TwoLineItem title="Project Operator" body={proponentDetails && proponentDetails.project_operator
              ? proponentDetails.project_operator
              : "--"} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProponentInformation
