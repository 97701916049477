import React, { useEffect, useState } from "react"

const CheckItem = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5582" cy="13.5582" r="13.5582" fill="#85C6AC" />
      <path
        d="M5.77344 14.6705L10.9637 19.8607L21.3442 8.73877"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default function SDGPicker({
  defaultValue = [],
  allItems = [
    {
      id: 768,
      label: "1.No Poverty",
    },
    {
      id: 769,
      label: "2.Zero Hunger",
    },
    {
      id: 770,
      label: "3.Good Health and well being",
    },
    {
      id: 771,
      label: "4.Quality education",
    },
    {
      id: 772,
      label: "5.Gender equality",
    },
    {
      id: 773,
      label: "6.Clean water and sanitation",
    },
    {
      id: 774,
      label: "7.Affordable and clean energy",
    },
    {
      id: 775,
      label: "8.Decent work and economic growth",
    },
    {
      id: 776,
      label: "9.Industry, innovation and infrastructure",
    },
    {
      id: 777,
      label: "10.Reduced inequalities",
    },
    {
      id: 778,
      label: "11.Sustainable cities and communities",
    },
    {
      id: 779,
      label: "12.Responsible consumption and production",
    },
    {
      id: 780,
      label: "13.Climate action",
    },
    {
      id: 781,
      label: "14.Life below water",
    },
    {
      id: 782,
      label: "15.life on land",
    },
    {
      id: 783,
      label: "16.Peace, justice and strong institution",
    },
    {
      id: 784,
      label: "17.Partnership for the goals",
    },
  ],
  onChange,
  itemSize = 128,
}) {
  const [selectedItems, setSelectedItems] = useState(defaultValue);

  useEffect(() => {
    setSelectedItems(defaultValue);
  }, [defaultValue])

  const handleClick = (itemId) => {
    let newItems = [...selectedItems];
    const index = newItems.indexOf(itemId);

    if (index >= 0) {
      newItems.splice(index, 1);
    } else {
      newItems.push(itemId);
    }

    setSelectedItems(newItems);
    onChange(newItems);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-start flex-wrap">
        {allItems.map((item, i) => {
          let index = selectedItems.indexOf(item.id)
          let selected = index >= 0 
          return (
            <div
              key={item.id}
              className="p-2"
              onClick={() => handleClick(item.id)}
            >
              <div
                className="position-relative"
                style={{ width: itemSize, height: itemSize }}
              >
                {selected && (
                  <div
                    className="position-absolute"
                    style={{ right: -12, top: -12 }}
                  >
                    <CheckItem size={24} />
                  </div>
                )}
                <img
                  src={"/icons/sdg/" + (i + 1) + ".jpg"}
                  className="img-fluid"
                  width={itemSize}
                  height={itemSize}
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}
