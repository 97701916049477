/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api"

const { createAsyncThunk } = require("@reduxjs/toolkit")
// const token = localStorage.getItem('authToken');
export const getCompaniesList = createAsyncThunk(
  "/company/getAllCompanies", // eslint-disable-next-line no-unused-vars
  async (_, { getState }) => {
    try {
      console.log("getCompaniesList called-123")
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get("/company/getAllCompanies", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const { data } = response
      return data?.payload?.companies ?? []
    } catch (error) {
      throw error
    }
  }
)
export const getCompanyById = createAsyncThunk(
  "/company/getCompanyById", // eslint-disable-next-line no-unused-vars
  async (id, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get(`/company/getCompanyById/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const { data } = response
      return data?.payload?.companyData ?? []
    } catch (error) {
      throw error
    }
  }
)
export const toggleCompanyStatus = createAsyncThunk(
  "/company/disableCompany", // eslint-disable-next-line no-unused-vars
  async (id, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get(`/company/disableCompany/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const { data } = response
      return data ?? []
    } catch (error) {
      throw error
    }
  }
)
export const companyDelete = createAsyncThunk(
  "/company/deleteCompany", // eslint-disable-next-line no-unused-vars
  async (id, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get(`/company/deleteCompany/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const { data } = response
      return data ?? []
    } catch (error) {
      throw error
    }
  }
)
