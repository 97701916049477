import React, { useState } from "react";
import PropTypes from "prop-types";
import Logo_cs from "../../assets/images/CarbonhubAiFinalLogo.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";
import { loginUser } from "../../store/actions"
import { Link } from "react-router-dom";

const StepTwo = (props) => {

  const dispatch = useDispatch()
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleValidSubmit = async (event, values) => {
    dispatch(loginUser(values, props.history))
  
  }

  const handlePasswordChange = (event, value) => {
    // Compare the values of new password and re-enter password
    if (value !== event.target.form.password.value) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };
  return (
    <React.Fragment>
      <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                      <div className="text-center mb-4">
                        <img className="pt-4 pb-4" src={Logo_cs} width="150px" alt="Logo" />
                        <h3 className="pt-2 pb-2" style={{ marginBottom: "20px" }}>Create Your New Password</h3>
                      </div>
                    {/* {error ? (
                      <Alert color="danger">
                        {"Incorrect Username or Password"}
                      </Alert>
                    ) : null} */}

                  <div className="mb-3">
                    <label htmlFor="password">New Password</label>
                    <AvField
                      id="password"
                      name="password"
                      value=""
                      type="password"
                      required
                      placeholder="Enter New Password"
                      onChange={handlePasswordChange} 
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="reEnterPassword">Re-Enter Password</label>
                    <AvField
                      id="reEnterPassword"
                      name="reEnterPassword"
                      value=""
                      type="password"
                      required
                      placeholder="Re-Enter Your Password"
                      onChange={handlePasswordChange}
                    />
                    {!passwordsMatch && (
                      <div className="text-danger">Password does not match.</div>
                    )}
                  </div>


                    <br />

                    

                    <div className="mt-1 d-grid">
                      <br />
                      <Link
                        to="/login"
                        className="btn btn-block"
                        style={{ backgroundColor: "#12526D", color: "white" }}
                        
                      >
                         Change Password
                      </Link>
                     
                    </div>
                  </AvForm>
                </div>
    </React.Fragment>
  );
};

StepTwo.propTypes = {
  history: PropTypes.object,
};

export default withRouter(StepTwo);
