/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api"
import jwtDecode from "jwt-decode"

const { createAsyncThunk } = require("@reduxjs/toolkit")
// const token = localStorage.getItem('authToken');

export const getUserRoles = createAsyncThunk(
  "/user/userroles",
  // eslint-disable-next-line no-unused-vars
  async (_, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get("/user/userroles", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = response.data
      return data.payload
    } catch (error) {
      throw error
    }
  }
)

export const getUsersList = createAsyncThunk(
  "/user/getAllUsers",
  // eslint-disable-next-line no-unused-vars
  async (_, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const decodedToken = jwtDecode(token)
      const userRole = decodedToken.role_id
      let url = userRole === 1 ? "/user/getAllUsers" : "user/getUsersByCompanyId"
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = response.data
      return data.payload
    } catch (error) {
      throw error
    }
  }
)


export const getUsersByID = createAsyncThunk(
  "/user/getUserById/",
  // eslint-disable-next-line no-unused-vars
  async (id, { getState }) => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.get(`/user/getUserById/${id}`, { // Include the 'id' in the URL
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = response.data
      if (data.error) {
        throw new Error(data.payload)
      }
      return data.payload
    } catch (error) {
      throw error
    }
  }
)
  