/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import axios from "axios"
import "react-phone-number-input/style.css"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import * as Yup from "yup"
import fb from "../../assets/images/brands/Vector.svg"
import linkedin from "../../assets/images/brands/linkdin.svg"
import twitter from "../../assets/images/brands/twitter-alt.svg"

import ContactErroring from "components/ContactUs/ContactError"
import ContactSuccessing from "components/ContactUs/ContactSuccess"
import { Button, Form, FormFeedback, Input, Modal } from "reactstrap"
import { postContactUs } from "components/core/services/contactUs"
import UpdatedDate from "components/Common/UpdatedDate"
import moment from "moment"

const Footer = () => {
  const [modal_contact_us, setmodal_contact_us] = useState(false)

  function tog_contact_us() {
    setmodal_contact_us(!modal_contact_us)
  }

  // Contact us page  Start here

  const dispatch = useDispatch()
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },

    validationSchema: Yup.object({
      fullname: Yup.string().required("This value is required"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      phone: Yup.string().max(255),
      message: Yup.string().required("Message is required"),
      subject: Yup.string().required("This value is required"),
    }),
    onSubmit: values => {
      sendMail(values)
      tog_cont_success()
      if (process.env.NODE_ENV === "development") {
        // console.log("values", data);
      }
    },
  })

  const [modal_cont_success, setmodal_cont_success] = useState(false)
  const [modal_cont_error, setmodal_cont_error] = useState(false)
  const [lastUpdatedDate, setLastUpdatedDate] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 932);

  function tog_cont_success() {
    setmodal_cont_success(!modal_cont_success)
  }

  function tog_cont_error() {
    setmodal_cont_error(!modal_cont_error)
  }

  function sendMail(values) {
    var data = {
      email: values.email,
      name: values.fullname,
      message: values.message,
      subject: values.subject,
      phone: values.phone,
    }
    if (process.env.NODE_ENV === "development") {
      console.log("data", data)
    }
    dispatch(postContactUs(data))
    clear()
  }

  // Contact us page  End here

  function clear() {
    validationType.resetForm({
      values: {
        fullname: "",
        email: "",
      },
    })
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "publish/getTheLatestUpdateDate")
      .then(response => {
        let data = response.data.payload
        let dateString = _.get(data, "latestUpdateDateTimestamp", "")
        let formattedDate = moment(dateString).local().format("DD MMM YYYY")
        setLastUpdatedDate(formattedDate)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 932);
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <footer className="footer-web ">
        <Container fluid={true}>
          <Row className="footer-horizontal-row">
            <Col md={3} className="carbon-copyright">
              {new Date().getFullYear()} © CarbonHub &ensp; v.8.0.1
            </Col>
            {isMobile && (
              <Col
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {lastUpdatedDate ? (
                  <UpdatedDate
                    text="Data Last updated on"
                    date={lastUpdatedDate}
                  />
                ) : (
                  <div
                    style={{
                      width: "198px",
                      padding: "3px",
                    }}
                  />
                )}
              </Col>
            )}
            <Col md={6} className="footer-link">
              <Link
                onClick={event => {
                  event.preventDefault()
                  window.open(
                    process.env.REACT_APP_OTHER_SERVICE_URL + "/about-us",
                    "_blank"
                  )
                }}
              >
                About Us
              </Link>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <Link
                onClick={event => {
                  event.preventDefault()
                  window.open(
                    process.env.REACT_APP_OTHER_SERVICE_URL + "/faq",
                    "_blank"
                  )
                }}
              >
                FAQ
              </Link>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <a
                className="onclick-fun"
                onClick={async () => {
                  setTimeout(() => {
                    tog_contact_us()
                  }, 200)
                }}
              >
                Contact Us
              </a>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              {/* <Link to="/contactus">Contact Us</Link>&nbsp;&nbsp;.&nbsp;&nbsp; */}
              <Link
                onClick={event => {
                  event.preventDefault()
                  window.open(
                    process.env.REACT_APP_OTHER_SERVICE_URL + "/disclaimer",
                    "_blank"
                  )
                }}
              >
                Disclaimer
              </Link>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <Link
                onClick={event => {
                  event.preventDefault()
                  window.open(
                    process.env.REACT_APP_OTHER_SERVICE_URL +
                      "/terms-and-conditions",
                    "_blank"
                  )
                }}
              >
                Terms and Conditions
              </Link>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <Link
                onClick={event => {
                  event.preventDefault()
                  window.open(
                    process.env.REACT_APP_OTHER_SERVICE_URL + "/privacy-policy",
                    "_blank"
                  )
                }}
              >
                Privacy <span className="statement">Statement</span>{" "}
              </Link>
            </Col>

            <Col md={3} className="social-media">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {lastUpdatedDate ? (
                  <UpdatedDate
                    text="Data Last updated on"
                    date={lastUpdatedDate}
                  />
                ) : (
                  <div
                    style={{
                      width: "198px",
                      padding: "3px",
                    }}
                  />
                )}
                <div>
                  <a
                    style={{ color: "#3A6B58", marginLeft: "20px" }}
                    // target="_blank"
                    href="https://www.facebook.com/CarbonHubAI/"
                    title="Facebook"
                  >
                    <img src={fb} />
                  </a>
                  &ensp;&ensp;
                  <a
                    style={{ color: "#3A6B58" }}
                    // target="_blank"
                    href="https://x.com/CarbonHubAI"
                    title="Twitter"
                  >
                    <img src={twitter} />
                  </a>
                  &ensp;&ensp;
                  <a
                    style={{ color: "#3A6B58" }}
                    // target="_blank"
                    href="https://www.linkedin.com/in/carbonhub-ai-44ba75321"
                    title="Linkedin"
                  >
                    <img src={linkedin} />
                  </a>
                  &ensp;&ensp;
                </div>
              </div>
            </Col>
          </Row>
          {!isMobile ? (
            <div className="footer-second">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="copyright-tag">
                  {new Date().getFullYear()} CarbonHub &nbsp;&nbsp;&nbsp;
                </div>

                <div className="media-icon">
                  {/* <i className="fab fa-facebook" href ="https://www.facebook.com" ></i> &nbsp; */}
                  <a
                    style={{ color: "#3A6B58" }}
                    href="https://www.facebook.com"
                    title="Facebook"
                  >
                    <img src={fb} alt="Facebook" />
                  </a>
                  &ensp;&ensp;
                  <a
                    style={{ color: "#3A6B58" }}
                    href="https://www.twitter.com"
                    title="Twitter"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  &ensp;&ensp;
                  <a
                    style={{ color: "#3A6B58" }}
                    href="https://www.linkedin.com"
                    title="Linkedin"
                  >
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                  &ensp;&ensp;
                </div>
              </div>
            </div>
          ) : (
            <div className="footer-second">
              <Row>
                <Col md={6} className="text-center">
                  <div className="copyright-tag">
                    {new Date().getFullYear()} CarbonHub
                  </div>
                </Col>
                <Col md={6} className="text-center">
                  <div className="media-icon">
                    <a
                      style={{ color: "#3A6B58" }}
                      href="https://www.facebook.com"
                      title="Facebook"
                    >
                      <img src={fb} alt="Facebook" />
                    </a>
                    &ensp;&ensp;
                    <a
                      style={{ color: "#3A6B58" }}
                      href="https://www.twitter.com"
                      title="Twitter"
                    >
                      <img src={twitter} alt="Twitter" />
                    </a>
                    &ensp;&ensp;
                    <a
                      style={{ color: "#3A6B58" }}
                      href="https://www.linkedin.com"
                      title="Linkedin"
                    >
                      <img src={linkedin} alt="LinkedIn" />
                    </a>
                    &ensp;&ensp;
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </footer>

      <Col md="4"></Col>

      <Col md="4">
        <Modal
          size="lg"
          isOpen={modal_contact_us}
          toggle={() => {
            tog_contact_us()
          }}
          centered
          className="modal-popscreen"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
              Contact us
            </h5>
            <button
              onClick={() => {
                clear()
                setmodal_contact_us(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>How can we help</h4>
            <h6>Please send your questions our way. We'll get back soon.</h6>

            {/* <Contacting /> */}

            <div className="">
              <Container fluid={true}>
                <div className="contact-container">
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                    <div className="contact-full">
                      <Row>
                        <Col md="12">
                          <Input
                            name="fullname"
                            placeholder="Full name*"
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.fullname || ""}
                            invalid={
                              validationType.touched.fullname &&
                              validationType.errors.fullname
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.fullname &&
                          validationType.errors.fullname ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.fullname}
                            </FormFeedback>
                          ) : null}

                          {/* <div className="invalid-tip" name="validate" id="validate1">&nbsp;Your name is required</div> */}
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <div className="contact-mail">
                      <Row>
                        <Col md="12">
                          <Input
                            name="email"
                            placeholder="Email*"
                            type="email"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.email || ""}
                            invalid={
                              validationType.touched.email &&
                              validationType.errors.email
                                ? true
                                : false
                            }
                          />

                          {validationType.touched.email &&
                          validationType.errors.email ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <div className="contact-phone">
                      <Row>
                        <Col md="12">
                          {/* <PhoneInput
                            country={'us'}
                            className="phone-no"
                            placeholder="Phone"
                            onChange={validationType.handleChange}
                          /> */}
                          <Input
                            name="phone"
                            type="number"
                            className="phone-no"
                            placeholder="Phone"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                          />
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <div className="contact-sub">
                      <Row>
                        <Col md="12">
                          <Input
                            name="subject"
                            type="subject"
                            className="subject"
                            placeholder="Subject*"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.subject || ""}
                            invalid={
                              validationType.touched.subject &&
                              validationType.errors.subject
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.subject &&
                          validationType.errors.subject ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.subject}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <div className="contact-message">
                      <Row>
                        <Col md="12">
                          <Input
                            name="message"
                            type="textarea"
                            className="message"
                            placeholder="Message*"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.message || ""}
                            invalid={
                              validationType.touched.message &&
                              validationType.errors.message
                                ? true
                                : false
                            }
                            rows="5"
                          />
                          {validationType.touched.message &&
                          validationType.errors.message ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.message}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    {/* For success message */}
                    <Button
                      type="submit"
                      className="send-button mt-3"
                      onClick={() => {
                        setTimeout(() => {
                          // console.log(process.env.REACT_APP_APIKEY);
                        }, 200)
                      }}
                    >
                      Send
                    </Button>{" "}
                    {/* For error message */}
                    {/* <Button 
                    className="send-button" 
                    onClick={async () => {
                      setTimeout(() => {
                        tog_cont_error()
                      }, 200);
                      
                      }}>Send
                </Button> */}
                  </Form>
                </div>
              </Container>
            </div>

            <Modal
              size="lg"
              isOpen={modal_cont_success}
              toggle={() => {
                tog_cont_success()
              }}
              centered
              className="modal-popscreen"
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  Contact us
                </h5>
                <button
                  onClick={() => {
                    setmodal_cont_success(false)
                    setmodal_contact_us(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ContactSuccessing />
              </div>
              <div className="modal-footer">
                <Button
                  onClick={() => {
                    setmodal_cont_success(false)
                    setmodal_contact_us(false)
                  }}
                  className="continue-btn"
                  type="submit"
                  data-dismiss="modal"
                >
                  Continue
                </Button>
              </div>
            </Modal>

            <Modal
              size="lg"
              isOpen={modal_cont_error}
              toggle={() => {
                tog_cont_error()
              }}
              centered
              className="modal-popscreen"
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  Contact us
                </h5>
                <button
                  onClick={() => {
                    setmodal_cont_error(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ContactErroring />
              </div>
              <div className="modal-footer">
                <Button
                  className="continue-btn"
                  onClick={() => {
                    setmodal_cont_error(false)
                  }}
                  type="submit"
                >
                  Try again
                </Button>
              </div>
            </Modal>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  )
}

export default Footer
