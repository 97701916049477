/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { getDropdownDataApi } from "components/core/services/getDropdownDataApi";
import { getDraftProjectByID, getPublishedProjectByID } from "components/core/services/getProjectByIDApi";
import { publishProject } from "components/core/services/publishProjectApi";
import { saveDraft } from "components/core/services/saveDraftApi";

export const STEPS = 8

const initialState = {
    lastUpdatedField: null,
    dirtyCount: 0,
    publishButtonEnabled: false,
    canNavigateUsingMouse: false,
    loading: false,
    error: null,
    showValidation: false,
    activeStep: 0,
    dropdownData: {},
    sectoralScopeData: {},
    steps: [
        {
            "title": "Registry Profile",
            "status": 'active',
        },
        {
            "title": "Proponent & Owner Details",
            "status": 'default',
        },
        {
            "title": "Listing Details",
            "status": 'default',
        },
        {
            "title": "Project Details",
            "status": 'default',
        },
        {
            "title": "Coordinates",
            "status": 'default',
        },
        {
            "title": "Approvals & Certifications",
            "status": 'default',
        },
        {
            "title": "Crediting Details",
            "status": 'default',
        },
        {
            "title": "Resources",
            "status": 'default',
        },
    ],
    wizardData: {}
}

export const wizardSlice = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        goToNextStep: (state) => {
            if (state.activeStep < STEPS - 1) {
                state.activeStep += 1
                if (state.activeStep === STEPS - 1) {
                    state.publishButtonEnabled = true
                    state.canNavigateUsingMouse = true
                }
            }
        },
        goToPrevStep: (state) => {
            if (state.activeStep >= 1) {
                state.activeStep -= 1
            }
        },
        updateWizardData: (state, action) => {
            const keys = Object.keys(action.payload)
            if (keys?.length) {
                if (state.lastUpdatedField == null) {
                    state.lastUpdatedField = keys[0]
                    state.dirtyCount += 1
                }
                else if (state.lastUpdatedField != keys[0]) {
                    state.lastUpdatedField = keys[0]
                    state.dirtyCount += 1
                }
            }
            let newData = { ...state.wizardData, ...action.payload }
            state.wizardData = newData
        },
        resetDirtyCount: (state) => {
            state.dirtyCount = 0
        },
        resetWizardData: (state) => {
            state.dirtyCount = 0
            state.publishButtonEnabled = false
            state.canNavigateUsingMouse = false
            state.loading = false
            state.error = null
            state.activeStep = 0
            state.dropdownData = {}
            state.sectoralScopeData = {}
            state.showValidation = false
            state.steps = [
                {
                    "title": "Registry Profile",
                    "status": 'active',
                },
                {
                    "title": "Proponent & Owner Details",
                    "status": 'default',
                },
                {
                    "title": "Listing Details",
                    "status": 'default',
                },
                {
                    "title": "Project Details",
                    "status": 'default',
                },
                {
                    "title": "Coordinates",
                    "status": 'default',
                },
                {
                    "title": "Approvals & Certifications",
                    "status": 'default',
                },
                {
                    "title": "Crediting Details",
                    "status": 'default',
                },
                {
                    "title": "Resources",
                    "status": 'default',
                },
            ]
            state.wizardData = {}
        },
        updateValidationState: (state, action) => {
            state.steps = action.payload
            state.showValidation = true
        },
        goToStep: (state, action) => {
            state.activeStep = action.payload
        }
    },
    extraReducers: {
        //Publish project API
        [publishProject.fulfilled]: (state) => {
            state.loading = true
            state.error = null
        },
        [publishProject.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [publishProject.rejected]: (state, action) => {
            state.error = action.error.message
            state.loading = false
        },
        // All dropdown Data
        
        // eslint-disable-next-line no-unused-vars
        [getDropdownDataApi.pending]: (state, _) => {
            state.loading = true
            state.error = null
        },
        [getDropdownDataApi.fulfilled]: (state, action) => {
            state.dropdownData = action.payload['dropdownData']
            state.sectoralScopeData = action.payload['sectoralScopes']
            state.registryStatus = action.payload['registryStatus']
            state.loading = false
        },
        [getDropdownDataApi.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get dropdown data (' + action.error.message + ')'
        },
        // Save draft project 
        [saveDraft.fulfilled]: (state, action) => {
            state.wizardData = { ...state.wizardData, id: action.payload.id, draft_id: action.payload.draft_id }
            state.loading = false
        },
        [saveDraft.pending]: (state, _) => {
            state.loading = true
            state.error = null
        },
        [saveDraft.rejected]: (state, action) => {
            state.error = action.error.message
            state.loading = false
        },
        // Get wizard steps
        [getDraftProjectByID.fulfilled]: (state, action) => {
            let data = { ...state.wizardData, ...action.payload }
            state.wizardData = data
            state.loading = false
        },
        [getDraftProjectByID.pending]: (state) => {
            state.loading = true
            state.error = false
        },
        [getDraftProjectByID.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get published project details (' + action.error.message + ')'
        },
        [getPublishedProjectByID.fulfilled]: (state, action) => {
            let data = { ...state.wizardData, ...action.payload }
            state.wizardData = data
            state.loading = false
        },
        [getPublishedProjectByID.pending]: (state, action) => {
            state.loading = true
            state.error = false
        },
        [getPublishedProjectByID.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Unable to get published project details (' + action.error.message + ')'
        },
    }
})

export const { goToNextStep, goToPrevStep, updateWizardData, resetWizardData, updateValidationState, goToStep, resetDirtyCount } = wizardSlice.actions
export default wizardSlice.reducer
