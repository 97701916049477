import React from 'react'
import ACR from "../../../src/assets/images/registry/ACR.jpg"
import CAR from "../../../src/assets/images/registry/CAR.jpg"
import CD from "../../../src/assets/images/registry/CD.png"
import GS from "../../../src/assets/images/registry/GS.jpg"
import VERA from "../../../src/assets/images/registry/VERA.png"
export default function RegistryLabel({ registry }) {
    if (registry) {
        return <div title={registry} className='d-flex align-items-center justify-content-start justify-content-md-end'>
            {
                registry === "Clean Development Mechanism" ? (
                    <img src={CD} width={32} height={32} alt="CD" />
                ) : registry === "Verra" ? (

                    <img src={VERA} width={32} height={32} alt="VERA" />

                ) : registry === "Gold Standard" ? (
                    <img src={GS} width={32} height={32} alt="GS" />

                ) : registry === "Climate Action Reserve" ? (
                    <img src={CAR} width={35} height={35} alt="CAR" />

                ) : registry === "American Carbon Registry" ? (
                    <img src={ACR} width={42} height={39} alt="ACR" />

                ) :
                    null
            }
            <span className='ps-1 ' style={{ display: 'block', fontSize: 16, color: '#959595', fontWeight: 'bold', lineHeight: '1rem', maxHeight: '1rem', textAlign: 'left' }}>{registry}</span>
        </div>
    }
    return <div style={{ fontSize: 16, fontWeight: 'bold', color: '#959595' }}>No registry</div>
}