import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { updateWizardData } from "rtk-store/slices/wizardSlice";

export default function CreaditInfo() {
  const wizardData = useSelector(state => state.wizard.wizardData)
  const currentDate = moment().format("YYYY-MM-DD")

  const [data, setData] = useState([
    {
      "vintage_period": null,
      "credit_generated": null,
      "credit_retired": null,
      "buffer_credits": null,
      "retired_cancelled_date": null,
      "retired_credits_reason": null,
    }
  ])
  const handleAddContact = () => {
    setData([...data, {
      "vintage_period": null,
      "credit_generated": null,
      "credit_retired": null,
      "buffer_credits": null,
      "retired_cancelled_date": null,
      "retired_credits_reason": null,
    }])
  }

  const handleDelete = (index) => {
    let newData = [...data]
    newData.splice(index, 1)
    setData(newData)
  }

  const handleClearData = () => {
    setData([{
      "vintage_period": null,
      "credit_generated": null,
      "credit_retired": null,
      "buffer_credits": null,
      "retired_cancelled_date": null,
      "retired_credits_reason": null,
    }])
  }

  const handleItemDetailChange = (index, key, value) => {
    let item = { ...data[index] }    
    if (key ==  "buffer_credits" || key == "credit_retired" || key == "credit_generated"){
      item[key] = parseFloat(value);
    }
    else{
      item[key] = value
    }
    const newData = [...data]
    console.log(key , " -> values in array", value);
    newData[index] = item
    setData(newData)
  }
 


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateWizardData({ 'projectCreditingInformation': data }))
  }, [data])

  useEffect(() => {
    setData(wizardData.projectCreditingInformation)
  }, [wizardData.projectCreditingInformation])


  return (
    <React.Fragment>
      <Table className="table table-sm">
        <thead>
          <tr style={{ borderBottom: "1px solid #ccc" }}>
            <th className="fw-normal">#</th>
            <th className="fw-normal">Issuance Date</th>
            <th className="fw-normal" style={{ width: "80px" }}>Vintage Start & End Date </th>
            <th className="fw-normal">Credit Generated</th>
            <th className="fw-normal">Credit Retired</th>
            <th className="fw-normal">Buffer Credits</th>
            <th className="fw-normal">Retired/Cancelled Date & Reason</th>
            <th className="fw-normal text-end">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => {
            return <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
              <td >
                {index + 1}
              </td>
              <td >
                <Input
                  type="date"
                  placeholder="Month/Year"
                  value={dataItem.issunace_date ?? ""}
                  onChange={e => {
                    handleItemDetailChange(index, 'issunace_date', e.target.value)
                  }}
                />
              </td>
              <td >
                <table>
                  <tr>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "10px" }}>Start</div>
                        <Input
                          type="date"
                          placeholder="Month/Year"
                          value={dataItem.vintage_start_date ?? ""}
                          max={currentDate}
                          onChange={(e) => {
                            handleItemDetailChange(index, "vintage_start_date", e.target.value);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "17px" }}> End </div>
                        <Input
                          type="date"
                          placeholder="Month/Year"
                          value={dataItem.vintage_end_date ?? ""}
                          onChange={(e) => {
                            handleItemDetailChange(index, "vintage_end_date", e.target.value);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td >
                <Input
                  type="number"
                  placeholder="Enter the value"
                  value={dataItem.credit_generated ?? null }
                  onClick={e => e.target.select()}
                  onChange={e => {
                    handleItemDetailChange(index, 'credit_generated', e.target.value)
                  }}
                />
              </td>
              <td >
                <Input
                  type="number"
                  placeholder="Enter the value"
                  onClick={e => e.target.select()}
                  onChange={e => {
                    handleItemDetailChange(index, 'credit_retired', e.target.value)
                  }}
                  value={dataItem.credit_retired ?? null}
                />
              </td>
              <td >
                <Input
                  type="number"
                  placeholder="Enter the value"
                  onClick={e => e.target.select()}
                  onChange={e => {
                    handleItemDetailChange(index, 'buffer_credits', e.target.value)
                  }}
                  value={dataItem.buffer_credits ?? null}
                />
              </td>

              <td >
                <table>
                  <tr>
                    <td>
                      <Input
                        type="date"
                        placeholder="mm/dd/yyyy"
                        value={dataItem.retired_cancelled_date ?? ""}
                        onChange={e => {
                          handleItemDetailChange(index, 'retired_cancelled_date', e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input
                        type="text"
                        placeholder="Enter the Reason"
                        onChange={e => {
                          handleItemDetailChange(index, 'retired_credits_reason', e.target.value)
                        }}
                        value={dataItem.retired_credits_reason ?? ""}
                      />
                    </td>
                  </tr>
                </table>
              </td>
              <td >
                {data.length >= 0 ? (
                  <a
                    onClick={() => handleDelete(index)}
                    className="btn btn-link fs-3 text-danger"
                  >
                    <i className="bx bx-trash"></i>
                  </a>
                ) : (
                  <a
                    onClick={handleClearData}
                    className="btn btn-link text-danger fs-3"
                    title="Clear your data"
                  >
                    <i className="dripicons-clockwise"></i>
                  </a>
                )}
              </td>
            </tr>
          })}
        </tbody>
      </Table >
      <Row>
        <Col className="text-end">
          <Button color="primary" style={{ backgroundColor: "#8796C7", borderColor: "#8796C7" }} onClick={handleAddContact}>
            Add New
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}
