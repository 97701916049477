import React from "react"
import PropTypes from "prop-types"

/*
    This component displays the last updated date.
    It takes a two props, text and date.

    // Example usage:
    <Date text="Last Updated: " date="12/12/2020" />

    required props:
    - text: string (text to display before the date)
    - date: string (date to display)
*/

export default function UpdatedDate({ text, date}) {
  
  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        borderColor: "#5A69F0",
        borderRadius: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        width: "198px",
        fontStyle: "italic",
        padding: "3px",
        textAlign: "center",
      }}
    >
      <p
        className="m-0 p-0"
        style={{
          color: "#5A69F0",
          fontFamily: "poppins",
          fontWeight: "400",
          fontSize: "10px",
          lineHeight: "15px",
        }}
      >
        {text}
        <span
          className="m-0 p-0"
          style={{
            fontWeight: "700",
          }}
        >
          &nbsp;{date}
        </span>
      </p>
    </div>
  )
}

Date.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
}
