import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { updateWizardData } from "rtk-store/slices/wizardSlice";
import React from "react";

function RadioButton({ options, selectedOption = null, onChange }) {
  const [selected, setSelected] = useState(null)
  useEffect(() => {
    setSelected(selectedOption)
  }, [selectedOption])
  useEffect(() => {
    onChange(selected)
  }, [selected])
  return <ButtonGroup>
    {options.map(item => {
      var bgColor = "white"
      var textColor = "black"
      if (selected === item.value) {
        bgColor = item.color
        textColor = 'white'
      }
      // eslint-disable-next-line no-unused-vars
      return <Button key={item.id} onClick={e => { 
        setSelected(item.value)
      }} style={{ fontSize: '12px', color: textColor, background: bgColor, border: 'none' }}>{item.label}</Button>
    })}
  </ButtonGroup>
}

export default function ApprovalCertificationDetails() {

  const dispatch = useDispatch()
  const wizardData = useSelector(state => state.wizard.wizardData)

  const options = [
    {
      label: "Project Approvals",
      key: "project_approvals",
      options: [
        { id: "btnradio1", value: "yes", label: "Yes", color: "#50A5F1" },
        { id: "btnradio2", value: "no", label: "No", color: "#F46A6A" },
        { id: "btnradio3", value: "n/a", label: "N/A", color: "#A6B0CF" },
      ],
    },
    {
      label: "ARB Eligible",
      key: "arb_eligible",
      options: [
        { id: "btnradio4", value: "yes", label: "Yes", color: "#50A5F1" },
        { id: "btnradio5", value: "no", label: "No", color: "#F46A6A" },
        { id: "btnradio6", value: "n/a", label: "N/A", color: "#A6B0CF" },
      ],
    },
    {
      label: "CORSIA Eligible",
      key: "corsia_qualified",
      options: [
        { id: "btnradio7", value: "yes", label: "Yes", color: "#50A5F1" },
        { id: "btnradio8", value: "no", label: "No", color: "#F46A6A" },
        { id: "btnradio9", value: "n/a", label: "N/A", color: "#A6B0CF" },
      ],
    },
    {
      label: "SD Vista",
      key: "sdvista_eligible",
      options: [
        { id: "btnradio10", value: "yes", label: "Yes", color: "#50A5F1" },
        { id: "btnradio11", value: "no", label: "No", color: "#F46A6A" },
        { id: "btnradio12", value: "n/a", label: "N/A", color: "#A6B0CF" },
      ],
    },
    {
      label: "CCB",
      key: "ccb_eligible",
      options: [
        { id: "btnradio13", value: "yes", label: "Yes", color: "#50A5F1" },
        { id: "btnradio14", value: "n/a", label: "No", color: "#F46A6A" },
        {
          id: "btnradio15",
          value: "in-progress",
          label: "In Progress",
          color: "#A6B0CF",
        },
      ],
    },
  ]

  return (
    <div>
      <Row className="row-cols-1 row-cols-md-4 row-cols-lg-3 g-5">
        {options.map(option => {
          return <Col key={option.id}>
            <Row>
              <Col>
                {option.label}
              </Col>
              <Col>
                <RadioButton options={option.options} onChange={value => {
                  dispatch(updateWizardData({ [option.key]: value }))
                }} selectedOption={wizardData[option.key]} />
              </Col>
            </Row>
          </Col>
        })}
      </Row>
    </div>
  );
}