import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import { useSelector } from "react-redux";

const GroupedBarChart = ({ dataColors }) => {
  const groupedBarColors = getChartColorsArray(dataColors);

  const creditingInformation = useSelector(
    (state) => state.projectDetails.details.creditingPddDetails
  )?.chartData;

  const series = creditingInformation?.series || [];
  const totalYears = creditingInformation?.year?.length || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedCategories, setSlicedCategories] = useState([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = Math.min(startIndex + 10, totalYears);

    const newSlicedCategories =
      creditingInformation?.year?.slice(startIndex, endIndex) || [];
    setSlicedCategories(newSlicedCategories);
  }, [currentPage, totalYears, creditingInformation]);

  const slicedSeries = series.map((item) => ({
    data: item.data.slice((currentPage - 1) * 10, currentPage * 10),
  }));

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(totalYears / 10))
    );
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(totalYears / 10);
    const startYear = slicedCategories[0];
    const endYear = slicedCategories[slicedCategories.length - 1];
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="btn btn-outline btn-sm"
          style={{ fontSize: "12px", color: "#CCCCCC", borderColor: "#CCCCCC" }}
        >
          &lt;
        </button>
        {startYear !== "null" && endYear !== "null" ? (
          <span
            className="fw-bold"
            style={{ margin: "0 10px", color: "#495057", fontSize: "11px" }}
          >
            {startYear} - {endYear}
          </span>
        ) : (
          <span
            className="fw-bold"
            style={{ margin: "0 10px", color: "#495057", fontSize: "11px" }}
          >
            Not Available
          </span>
        )}

        <button
          onClick={goToNextPage}
          className="btn btn-outline btn-sm"
          style={{ fontSize: "12px", color: "#CCCCCC", borderColor: "#CCCCCC" }}
          disabled={currentPage >= totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-content-center">
        <div style={{ color: "#495057", fontSize: "16px" }} className="fw-bold">
          Production Profile
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "20px",
          }}
        >
          <div
            style={{ marginRight: "10px" }}
            className="d-flex align-items-center"
          >
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                width: "16px",
                borderRadius: "50%",
                height: "16px",
                backgroundColor: groupedBarColors[0],
              }}
            />
            <div style={{ fontSize: "16px", color: "#495057" }} className="fw-bold">
              PDD
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ marginRight: "32px" }}>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                width: "16px",
                borderRadius: "50%",
                height: "16px",
                backgroundColor: groupedBarColors[1],
              }}
            />
            <div style={{ fontSize: "16px", color: "#495057" }} className="fw-bold">
              Actual
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "20px 0 " }}>
        <ResponsiveContainer height={264}>
          <BarChart
            data={slicedCategories.map((year, index) => ({
              year: year !== "null" ? year : "",
              PDD: slicedSeries[0].data[index],
              Actual: slicedSeries[1].data[index],
            }))}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="year"
              angle={-45}
              tick={{ fontWeight: "bold" }}
              textAnchor="end" 
              interval={0}
              padding={{ left: 30, right: 30 }}
              tickLine={false}
              axisLine={false}
            />
            <YAxis tickLine={false} axisLine={false} />
            <Tooltip />
            <CartesianGrid horizontal={true} vertical={false} />
            <Bar
              dataKey="PDD"
              fill={groupedBarColors[0]}
              barSize={10}
              radius={[5, 5, 0, 0]}
            />
            <Bar
              dataKey="Actual"
              fill={groupedBarColors[1]}
              barSize={10}
              radius={[5, 5, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {renderPaginationButtons()}
    </div>
  );
};

export default GroupedBarChart;
