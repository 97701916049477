/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api"

const { createAsyncThunk } = require("@reduxjs/toolkit")

export const postCreateUpdateCompany = createAsyncThunk(
  "company/createOrUpdateCompany",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(
        "/company/createOrUpdateCompany",
        payload, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
)
