import CSVUploader from "components/Common/CSVUploader"
import SectionTitle from "components/Common/SectionTitle"
import moment from "moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap"
import { updateWizardData } from "rtk-store/slices/wizardSlice"
import Swal from "sweetalert2"
import CreaditInfo from "./SubProjectCrediting"
import ProjectPDD from "./SubProjectPDD"
import { validate } from "components/Common/ValidationIndicator"

export default function CreditingDetails() {
  const dropdownValues = useSelector((state) => state.wizard.dropdownData);
  const data = useSelector((state) => state.wizard.wizardData)
  const dispatch = useDispatch()
  const showValidation = useSelector(state => state.wizard.showValidation)
  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label>Estimated Annual Emission Reductions*</Label>
            <Input
              invalid={showValidation && validate(data, 'est_annu_emission_red')}
              type="number"
              className="form-input"
              id="emission_reduc"
              name="emission_reduc"
              onChange={e => {
                dispatch(updateWizardData({ 'est_annu_emission_red': e.target.value }))
              }}
              value={data.est_annu_emission_red ?? ''}
            />
            {showValidation && validate(data, 'est_annu_emission_red') && <FormFeedback>Required</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Crediting Period</Label>
            <Select
              className="select-input"
              classNamePrefix="select"
              isSearchable={true}
              name="customer"
              options={dropdownValues['proj_credit']}
              placeholder="Select the type of Crediting Period"
              onChange={e => {
                dispatch(updateWizardData({ 'project_vintage_period': e.id }))
              }}
              value={dropdownValues['proj_credit'].find(item => item.id === data.project_vintage_period)}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Project Length*</Label>
            <Input
              invalid={showValidation && validate(data, 'project_length')}
              type="text"
              className="form-input"
              id="prj_length"
              name="prj_length"
              placeholder="Enter the Value "
              onChange={e => {
                dispatch(updateWizardData({ 'project_length': e.target.value }))
              }}
              value={data.project_length ?? ''}
            />
            {showValidation && validate(data, 'project_length') && <FormFeedback>Required</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
      <SectionTitle title="Project Crediting Information">
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <CSVUploader
              onUploadComplete={newData => {
                const existingData = [...data.projectCreditingInformation]; 
                const formatDate = (date) => {
                  const d = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
                  const formattedDate = d.format('yyyy-MM-DD')
                  if (formattedDate === 'Invalid date') {
                    return null
                  }
                  return formattedDate
                }
                newData.forEach(dataItem => {
                  existingData.push({
                    'issunace_date': formatDate(dataItem[0]),
                    'vintage_start_date': formatDate(dataItem[1]),
                    'vintage_end_date': formatDate(dataItem[2]),
                    'credit_generated': dataItem[3],
                    'credit_retired': dataItem[4],
                    'buffer_credits': dataItem[5],
                    'retired_cancelled_date': formatDate(dataItem[6]),
                    'retired_credits_reason': dataItem[7],
                  });
                });
                dispatch(updateWizardData({ 'projectCreditingInformation': existingData }));
              }}
              onError={error => {
                Swal.fire('Warning', 'Error: ' + error, 'error')
              }}
            />
          </div>
          <a className="btn btn-outline-dark btn-sm ms-3" href="/crediting.xlsx">
            <i className="bx bx-download"></i> Template
          </a>
        </div>
      </SectionTitle>

      <CreaditInfo />
        <div className="mb-5" />
          <SectionTitle title="Project PDD Information">
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                <CSVUploader 
                onUploadComplete={newData => {
                  const existingData = [...data.projectPDDInformation]; 
                  // const pddData = []
                  const formatDate = (date) => {
                    const d = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
                    return d.format('yyyy-MM')
                  }
                  newData.forEach(dataItem => {
                    existingData.push({
                      'credit_year': formatDate(dataItem[0]),
                      'pdd': dataItem[1],
                    })
                  })
                  dispatch(updateWizardData({ projectPDDInformation: existingData }))
                  }} onError={error => {
                    Swal.fire('Warning', 'Error: ' + error, 'error')
                  }} />
              </div>
              <a className="btn btn-outline-dark btn-sm ms-3" href="/pdd.xlsx"><i className="bx bx-download"
              ></i> Template</a>
            </div>
          </SectionTitle>
      <ProjectPDD />
    </React.Fragment>
  )
}
