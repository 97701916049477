/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import api from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getRetirementByProjectType = createAsyncThunk(
    'analytic/getRetirementByProjectType',
    async ({datetype, startDate, endDate, registryType }) => {
        try {
          const token = localStorage.getItem("authToken");
          const response = await api.get(`/analytic/getRetirementByProjectType/?dateType=${datetype}&startDate=${startDate}&endDate=${endDate}&registryType=${registryType}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data
          
          if (data.error) {
            throw new Error(data.payload)
          }
          return data
        } 
        catch (error) {
          throw error;
        }
    }
);
