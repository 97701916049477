import React from "react"

export default function DoubleLineItem({ title, body, tooltip, iconSize = '19px', marginBottom = '1rem' }) {
  return (
    <React.Fragment>
      <div className="d-flex flex-row">
        {tooltip && <div><i className="bx bx-info-circle me-2" style={{ cursor: 'pointer', color: '#9C9C9C', fontSize: iconSize }} title={tooltip} /></div>}
        <div style={{ marginBottom: marginBottom }}>
          <h5 style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: 700, color: '#9C9C9C' }}>{title}</h5>
          <div className="fw-bold" style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, color: '#495057', overflowWrap: 'anywhere' }}>{body}</div>
        </div>
      </div>
    </React.Fragment>
  )
}
