import SectionTitle from "components/Common/SectionTitle";
import React from "react";
import {
  Col,
  Row
} from "reactstrap";
import CoordinatesTable from "./CoordinatesTable";

export default function CoordinatesDetails() {
  return (
    <React.Fragment>
      <div>
        <SectionTitle title="Project Coordinates" subtitle="First entry is the default coordinates for the project." />
        <Row>
          <Col md="12" >
            <CoordinatesTable />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
