import { AvField, AvForm } from "availity-reactstrap-validation"
import { getCompanyById } from "components/core/services/getCompanies"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Button, Col, Label, Row } from "reactstrap"
import { isValidEmail, isValidPhoneNumber } from "../Common/ValidationIndicator"

export default function CompanyModal({ handleCompanyForm, companyId }) {
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [contactPerson, setContactPerson] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [loading, setLoading] = useState(false)
  const data = useSelector(state => state.wizard.wizardData)

  useEffect(() => {
    if (companyId) {
      setLoading(true)
      dispatch(getCompanyById(companyId))
        .then(response => {
          if (response.payload) {
            const companyData = response.payload
            setCompanyName(companyData.companyName)
            setCompanyEmail(companyData.email)
            setContactPerson(companyData.contactPerson)
            setPhoneNumber(companyData.phoneNumber)
          }
        })
        .catch(error => {
          console.error("Error fetching user data: ", error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [companyId])

  const handleSubmit = async () => {
    const payload = {
      companyName: companyName,
      email: companyEmail,
      contactPerson: contactPerson,
      phoneNumber: phoneNumber,
    }
    if (companyId) {
      payload["id"] = companyId
    }
    handleCompanyForm(payload)
  }
  const regex = /^\+?\d{10,}$/
  return (
    data && (
      <React.Fragment>
        {loading ? (
          <Row>
            <Col>
              <Alert color="light">Loading…</Alert>
            </Col>
          </Row>
        ) : null}
        <AvForm className="form-horizontal p-4" disabled={loading}>
          <Row>
            <Col md="5">
              <Label className="mt-2">Company Name</Label>
            </Col>
            <Col md="7">
              <AvField
                className="form-input"
                name="companyName"
                placeholder="Enter name"
                onChange={e => setCompanyName(e.target.value)}
                value={companyName}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Label className="mt-2">Company Contact Email</Label>
            </Col>
            <Col md="7">
              <AvField
                name="email"
                className="form-input"
                placeholder="Enter contact email"
                value={companyEmail}
                onChange={e => setCompanyEmail(e.target.value)}
                type="email"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Label className="mt-2">Contact Person</Label>
            </Col>
            <Col md="7">
              <AvField
                className="form-input"
                name="contactPerson"
                placeholder="Enter contact person"
                onChange={e => setContactPerson(e.target.value)}
                value={contactPerson}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Label className="mt-2">Phone Number</Label>
            </Col>
            <Col md="7">
              <AvField
                name="phoneNumber"
                type="tel"
                placeholder="Enter phone number"
                onChange={e => {
                  setPhoneNumber(e.target.value)
                }}
                value={phoneNumber}
                className="form-input"
                validate={{ tel: { pattern: regex } }}
                required
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-end">
              <Button
                onClick={handleSubmit}
                type="submit"
                color="dark"
                disabled={
                  !contactPerson ||
                  !phoneNumber ||
                  !companyEmail ||
                  !isValidEmail(companyEmail) ||
                  !companyName ||
                  !isValidPhoneNumber(phoneNumber)
                }
                style={{
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  backgroundColor: "#7AA5BF",
                  border: "2px solid #7AA5BF",
                }}
              >
                {companyId ? "Save" : "Add"}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </React.Fragment>
    )
  )
}
