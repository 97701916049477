/* eslint-disable no-undef */
module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  s3:{
    ID :'AKIA3MSAOMVYYZQYANOY',
    SECRET : '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',
    BUCKET_NAME : 'carbonespace',
  }
}
