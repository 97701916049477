
/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const editPublishedProject = createAsyncThunk('/publish/createDraftEdit', async (id) => {
    try {
        const res = await api.post('/publish/createDraftEdit/' + id).then((res) => res.data)
        if (res['error']) {
            throw new Error(res['payload'])
        }
        const payload = res['payload']
        if (payload['message']) {
            if (payload['message'] === "Project successfully edited.") {
                const draftId = payload?.newDraftProject?.id
                if (draftId) {
                    return draftId
                }
            }
        }
        return null
    } catch (error) {
        throw error
    }
})