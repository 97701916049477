import { createSlice } from '@reduxjs/toolkit';
import { getRetirementByProjectType } from 'components/core/services/getRetirementByProjectTypeApi';

const retirementByProjectTypeSlice = createSlice({
    name: 'retirements',
    initialState: {
      chartData: [],
      loading: false,
      projectFilter: [],
      error: null,
    },
    reducers: {
      setProjectFilters: (state, action) => {
        state.projectFilter = action.payload
      },
    },
    extraReducers: {
      [getRetirementByProjectType.fulfilled]: (state, action) => {
        state.chartData = action.payload;
        state.loading = false;
      },
      [getRetirementByProjectType.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [getRetirementByProjectType.rejected]: (state, action) => {
        state.loading = false;
        state.error = 'Unable to fetch data (' + action.error.message + ')';
      },
    },
});

export const {
  setProjectFilters,
} = retirementByProjectTypeSlice.actions

export default retirementByProjectTypeSlice.reducer;
